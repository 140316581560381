(function() {

    angular.module("conto")
        .factory('CalculationService', CalculationService);

    function CalculationService(ValidationService) {

        var service;
        service = {
            'calculateSums': calculateSums
        };

        /* class "constructor" and initialisation */
        //service.init();
        return service;

        /*
         * Funkcija za kalkuliranje totala
         * @return vraća OBJEKT s rekapitulacijom izračuna
         */
        function calculateSums(cijena, kolicina, pdvVrijednost) {
            ValidationService.validate(cijena, 'required|numeric', 'cijena');
            ValidationService.validate(kolicina, 'required|numeric', 'kolicina');
            ValidationService.validate(pdvVrijednost, 'required|numeric', 'pdvVrijednost');

            /*var osnovica = math.round(kolicina * cijena, 2);
            var pdvIznos = math.round(osnovica * pdvVrijednost, 2);
            var ukupno = osnovica + pdvIznos;*/

            var osnovica = round2(kolicina * cijena);
            var pdvIznos = round2(osnovica * pdvVrijednost);
            var ukupno = osnovica + pdvIznos;

            return {
                'osnovica': osnovica,
                'pdv_iznos': pdvIznos,
                'ukupno': ukupno

            };
        }
    }

})();
