(function () {

    angular.module("conto")
        .factory('UlazniRacunStavka', UlazniRacunStavka);

    function UlazniRacunStavka($log, RacunStavka) {


        return function () {
            var service = new RacunStavka();
            return service;
        };

    }

})();
