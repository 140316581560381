(function() {

    angular.module("conto")
        .controller('KategorijaClanstvaListCtrl', KategorijaClanstvaListCtrl);

    function KategorijaClanstvaListCtrl($scope, KategorijaClanstvaRest) {
        var vm = this;

        vm.modelRest = KategorijaClanstvaRest;
        vm.response = [];

    }
})();
