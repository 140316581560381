(function () {

    angular.module("conto")
        .factory('RacunStavka', RacunStavka);

    function RacunStavka($log, ValidationService, ClassUtils, Pdv, Jmj, CalculationService, UlaznaKategorija,
                         AppConstants, RacunService, ValidationException) {

        return function () {

            var attributes = {
                ulazna_kategorija: 'required|object',
                kolicina: 'required|numeric',
                cijena: 'required|numeric',
                osnovica: 'required|numeric',
                jmj: 'required|object',
                pdv: 'required|object',
                pdv_iznos: 'required|numeric',
                ukupno: 'required|numeric',
                inventar_broj: 'numeric|min:0',
                opis: 'text',
                storno: 'alpha'
            };

            var service = ClassUtils.defineModel('RacunStavka', attributes)();
            var serviceValidate = service.validate;
            service.validateOsaSiValue = validateOsaSiValue;

            /* Extend validators */
            service.extendValidators('validateOsaSiValue', validateOsaSiValue);
            service.extendValidators('validateTotals', validateTotals);

            /* Extend formatters */
            service.extendFormatters('formatFeildsForOsa', formatFeildsForOsa);

            function validateTotals() {
                var retval = true;
                /*
                TODO: ova provjera nema smisla kod obrnutog računanja cijene iz totala, ramizliti što s ovim kad dođe PDV
                if (service.pdv.vrijednost) {
                    var cijena = service.cijena;
                    var kolicina = service.kolicina;
                    var pdvVrijednost = service.pdv.vrijednost;

                    // TODO: staviti u calculation service
                    var izracun = CalculationService.calculateSums(cijena, kolicina, pdvVrijednost);
                    //$log.debug("izracun", izracun);

                    if (!(round2(izracun.osnovica) === round2(service.osnovica) &&
                        round2(izracun.pdv_iznos) === round2(service.pdv_iznos) &&
                        round2(izracun.ukupno) === round2(service.ukupno))) {
                        $log.error("Izracunata osnovica: " + izracun.osnovica, "Postavljena osnovica: " + service.osnovica);
                        $log.error("Izracunat pdvIznos: " + izracun.pdv_iznos, "Postavljen pdvIznos: " + service.pdv_iznos);
                        $log.error("Izracunat ukupno: " + izracun.ukupno, "Postavljen ukupno: " + service.ukupno);

                        retval = false;
                    }
                }*/

                return retval;
            }

            function getOsaOrSi() {
                if (service.ulazna_kategorija && service.ulazna_kategorija.uid) {
                    return service.ulazna_kategorija.uid;
                }
            }

            /**
             * Ako stavka ima kategoriju OSA ili SI onda je polje 'opis' obavezno
             */
            function formatFeildsForOsa() {
                attributes['opis'] = getOsaOrSi() === AppConstants.OSNOVNO_SREDSTVO || getOsaOrSi() === AppConstants.SITAN_INVENTAR ? 'required|text' : 'text';
                service.setAttributes(attributes);
            }

            /**
             * Funckija koja validira ima li stavka ispravan iznos u odnosu na iznos potreban da bude
             * 'Osnovno sredstvo' ili 'Sitan inventar'
             * @returns {boolean}
             */
            function validateOsaSiValue() {
                var retval = true;
                if (service && service.cijena !== '' && service.ulazna_kategorija && service.ulazna_kategorija.uid && !service.storno) {
                    if (RacunService.isStavkaValidOsaOrSi(service) === AppConstants.OSNOVNO_SREDSTVO &&
                        service.ulazna_kategorija.uid !== AppConstants.OSNOVNO_SREDSTVO) {
                        retval = 'MUST_BE_OSA';
                    }
                    else if (RacunService.isStavkaValidOsaOrSi(service) === AppConstants.SITAN_INVENTAR &&
                        service.ulazna_kategorija.uid !== AppConstants.SITAN_INVENTAR) {
                        retval = 'MUST_BE_SI';
                    }
                }

                return retval;
            }

            return service;
        };

    }

})();
