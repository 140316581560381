(function () {

    angular.module("conto")
        .factory('RacunService', RacunService);

    function RacunService($log, ValidationService, PdvRest, AppConstants, SupNacinPlacanja, $injector) {

        var service;
        service = {
            'calculateSums': calculateSums,
            'calculateStavkaTotal': calculateStavkaTotal,
            'calculateStavkaCijenaFromTotal': calculateStavkaCijenaFromTotal,
            'calculateRekapitulacija': calculateRekapitulacija,
            'grupirajPoreznuRekapitulaciju': grupirajPoreznuRekapitulaciju,
            'addStavka': addStavka,
            'createStavkaDirtyAndPreconfigured': createStavkaDirtyAndPreconfigured,
            'removeStavka': removeStavka,
            'updateStavka': updateStavka,
            'calculateDaniPlacanja': calculateDaniPlacanja,
            'calculateDatumDospijeca': calculateDatumDospijeca,
            'setStavkaPdvByVrijednost': setStavkaPdvByVrijednost,
            'isStavkaValidOsaOrSi': isStavkaValidOsaOrSi,
            'getDozvoljeniNaciniPlacanja': getDozvoljeniNaciniPlacanja,
            'extractPartnerDefaultsForDocument': extractPartnerDefaultsForDocument
        };

        /* class "constructor" and initialisation */
        return service;

        /*
         * Funkcija za kalkuliranje totala
         * @return vraća OBJEKT s rekapitulacijom izračuna
         */
        function calculateSums(cijena, kolicina, pdvVrijednost) {
            ValidationService.validate(cijena, 'required|numeric', 'cijena');
            ValidationService.validate(kolicina, 'required|numeric', 'kolicina');
            ValidationService.validate(pdvVrijednost, 'required|numeric', 'pdvVrijednost');

            var osnovica = round2(kolicina * cijena);
            var pdvIznos = round2(osnovica * pdvVrijednost);
            var ukupno = osnovica + pdvIznos;

            return {
                'osnovica': osnovica,
                'pdv_iznos': pdvIznos,
                'ukupno': ukupno
            };
        }

        function calculateCijena(ukupno, kolicina, pdvVrijednost) {
            ValidationService.validate(ukupno, 'required|numeric', 'cijena');
            ValidationService.validate(kolicina, 'required|numeric|min:0.01', 'kolicina');
            ValidationService.validate(pdvVrijednost, 'required|numeric', 'pdvVrijednost');

            pdvVrijednost = round2(pdvVrijednost);

            /**$log.info("ukupno", ukupno);
             $log.info("kolicina", kolicina);
             $log.info("pdvVrijednost", pdvVrijednost);*/

            var osnovica = round2(round2(round2(ukupno / kolicina) / round2(pdvVrijednost + 1)) * kolicina);
            var pdvIznos = osnovica * pdvVrijednost;
            var cijena = osnovica / kolicina;

            return {
                'osnovica': round2(osnovica),
                'pdv_iznos': round2(pdvIznos),
                'cijena': round2(cijena)
            };
        }

        /* Funkcija za kalkuliranje totala pojedine stavke */
        function calculateStavkaTotal($stavka) {
            ValidationService.validate($stavka, 'required|object', '$stavka');
            ValidationService.validate($stavka.pdv, 'required|object', '$stavka.getPdv()');

            /* Izračunaj totale stavke */
            var sums = calculateSums($stavka.cijena, $stavka.kolicina, $stavka.pdv.vrijednost);

            $stavka.osnovica = sums.osnovica;
            $stavka.pdv_iznos = sums.pdv_iznos;
            $stavka.ukupno = sums.ukupno;
        }

        function calculateStavkaCijenaFromTotal($stavka) {
            ValidationService.validate($stavka, 'required|object', '$stavka');
            ValidationService.validate($stavka.pdv, 'required|object', '$stavka.getPdv()');

            /* Izračunaj totale stavke */
            var sums = calculateCijena($stavka.ukupno, $stavka.kolicina, $stavka.pdv.vrijednost);

            $log.debug("SUMS", sums);

            $stavka.osnovica = sums.osnovica;
            $stavka.pdv_iznos = sums.pdv_iznos;
            $stavka.cijena = sums.cijena;
            //$stavka.ukupno = math.round($stavka.ukupno,2);
        }

        /* Funkcija za kalkuliranje totala ulaznog računa */
        function calculateRekapitulacija($ulazniRacun) {
            ValidationService.validate($ulazniRacun, 'required|object', '$ulazniRacun');

            var sumOsnovica = 0;
            var sumPdv = 0;
            var sumUkupno = 0;

            angular.forEach($ulazniRacun.json_stavke, function (stavka, key) {
                ValidationService.validate(stavka, 'required|object', 'stavka');

                sumOsnovica = sumOsnovica + parseFloat(stavka.osnovica);
                sumPdv = sumPdv + parseFloat(stavka.pdv_iznos);
                sumUkupno = sumUkupno + parseFloat(stavka.ukupno);
            });

            var rekapitulacija = {
                'osnovica': sumOsnovica,
                'pdv_iznos': sumPdv,
                'ukupno': sumUkupno
            };

            return $ulazniRacun.json_rekapitulacija = rekapitulacija;
        }

        /*
         * Funkcija za grupiranje porezne rekapitulacije
         * @return vraća objekata sa svim sumama po grupi poreza
         */
        function grupirajPoreznuRekapitulaciju($ulazniRacun) {
            ValidationService.validate($ulazniRacun, 'required|object', '$ulazniRacun');

            var vatGroup = {};
            var stavke = $ulazniRacun.json_stavke;
            angular.forEach(stavke, function (item, key) {
                ValidationService.validate(item.pdv, 'required|object', 'PDV na stavci');

                var pdvVrijednost = item.pdv.vrijednost;
                if (!vatGroup[pdvVrijednost]) {
                    vatGroup[pdvVrijednost] = {osnovica: 0, cijena: 0, pdv_iznos: 0, ukupno: 0};
                }

                vatGroup[pdvVrijednost].cijena += item.cijena;
                vatGroup[pdvVrijednost].osnovica += item.osnovica;
                vatGroup[pdvVrijednost].pdv_iznos += item.pdv_iznos;
                vatGroup[pdvVrijednost].ukupno += item.ukupno;
            });

            return vatGroup;
        }

        function addStavka($ulazniRacun, $stavka, dirty) {
            ValidationService.validate($ulazniRacun, 'required|object', '$ulazniRacun');
            ValidationService.validate($stavka, 'required|object', '$stavka');

            calculateStavkaTotal($stavka);
            $stavka.executeValidator('validateTotals');

            if (!angular.isArray($ulazniRacun.json_stavke)) {
                $ulazniRacun.json_stavke = [];
            }

            //$log.warn("Stavka prije pusha", $stavka.getData());

            $ulazniRacun.json_stavke.push($stavka);

            //$log.warn("Sve stavke nakon", $ulazniRacun.json_stavke);
            //$log.warn("ULAZNI RACUN", $ulazniRacun);
            //$log.warn("ULAZNI RACUN JSON STAVKE", $ulazniRacun.json_stavke);

            /* Rekalkuliraj totale ulaznog računa nakon dodavanja stavke*/
            calculateRekapitulacija($ulazniRacun);

            var idx = $ulazniRacun.json_stavke.length;
            if (dirty) {
                if (idx) {
                    $ulazniRacun.json_stavke[idx - 1].ukupno = '';
                    delete $ulazniRacun.json_stavke[idx - 1].errors['RacunStavka.ukupno'];
                }
            }
            if (idx) {
                return $ulazniRacun.json_stavke[idx - 1];
            }
        }

        function createStavkaDirtyAndPreconfigured(batchData, $racun, stavkaClassModel) {
            //var pdvDefault = batchData.PdvRest[0];
            var jmjDefault = batchData.JmjRest[0];
            var pdv = batchData.PdvRest;
            //var stavkaModel = $injector.get(classModel);

            var stavka = (new stavkaClassModel).setData({
                kolicina: 1,
                jmj: jmjDefault,
                cijena: 0
            });

            var tenantData = batchData.SessionDataRest.tenant;

            /**
             * Ako tenant nije obveznik PDV-a
             * postavi defaultnu vrijednost PDV-a za stavku na nulu
             */
            if (!tenantData.isPdvObveznik()) {
                setStavkaPdvByVrijednost(stavka, 0, pdv);
            }

            return addStavka($racun, stavka, true);

        }

        function removeStavka($ulazniRacun, $index) {
            ValidationService.validate($ulazniRacun, 'required|object', '$ulazniRacun');
            ValidationService.validate($index, 'required|numeric', '$index');

            var stavke = $ulazniRacun.json_stavke;
            stavke.splice($index, 1);
            $ulazniRacun.json_stavke = stavke;

            /* Rekalkuliraj totale ulaznog računa nakon dodavanja stavke*/
            return calculateRekapitulacija($ulazniRacun);
        }

        function updateStavka($ulazniRacun, $index, $stavka) {
            ValidationService.validate($ulazniRacun, 'required|object', '$ulazniRacun');
            ValidationService.validate($index, 'required|numeric', '$index');
            ValidationService.validate($stavka, 'required|object', '$stavka');

            var stavke = $ulazniRacun.json_stavke;

            /* Još jednom validiraj stavku i izračunaj totale */
            calculateStavkaTotal($stavka);
            $stavka.executeValidator('validateTotals');

            stavke[$index] = $stavka;
            $ulazniRacun.json_stavke = stavke;

            /* Rekalkuliraj totale ulaznog računa nakon promjene stavke*/
            return calculateRekapitulacija($ulazniRacun);
        }

        function calculateDaniPlacanja($ulazniRacun) {
            ValidationService.validate($ulazniRacun, 'required|object', '$ulazniRacun');

            $log.debug("extractPartnerDefaultsForDocument", {
                "Datum Izdavanja": $ulazniRacun.datum_izdavanja,
                "Datum dospijeca": $ulazniRacun.datum_dospijeca,
                "Dani odgode": $ulazniRacun.dani_odgode
            });

            var retval = undefined;
            if ($ulazniRacun.datum_dospijeca && $ulazniRacun.datum_izdavanja) {
                var datumIzdavanja = moment.utc($ulazniRacun.datum_izdavanja, AppConstants.DDMMYYY);
                var datumDospijeca = moment.utc($ulazniRacun.datum_dospijeca, AppConstants.DDMMYYY);
                retval = datumDospijeca.diff(datumIzdavanja, 'days');

                $ulazniRacun.dani_odgode = retval;
            }
        }

        function calculateDatumDospijeca($ulazniRacun) {
            ValidationService.validate($ulazniRacun, 'required|object', '$ulazniRacun');
            var retval = undefined;

            if ($ulazniRacun.datum_izdavanja && $ulazniRacun.dani_odgode) {

                //$log.debug("datum_izdavanja", $ulazniRacun.datum_izdavanja);
                //$log.debug("dani_odgode", $ulazniRacun.dani_odgode);

                var datumIzdavanja = new moment($ulazniRacun.datum_izdavanja, AppConstants.DDMMYYY);
                datumIzdavanja.add($ulazniRacun.dani_odgode, 'days');
                retval = datumIzdavanja;

                if (moment($ulazniRacun.datum_dospijeca).format(AppConstants.DDMMYYY) !== retval.format(AppConstants.DDMMYYY)) {
                    $ulazniRacun.datum_dospijeca = retval;
                }
            }
        }

        function setStavkaPdvByVrijednost($stavka, vrijednost, pdvList) {
            ValidationService.validate($stavka, 'required|object', '$stavka');
            ValidationService.validate(vrijednost, 'required|numeric', 'vrijednost');
            ValidationService.validate(vrijednost, 'required', 'pdvList');

            if (!vrijednost) vrijednost = 0;

            var found = null;
            angular.forEach(pdvList, function (item, i) {
                if (item.vrijednost == vrijednost) {
                    found = item;
                }
            });

            if (found === null) {
                throw Error("Traženi PDV nije pronađen!");
            }

            $stavka.pdv = found;

        }

        function isStavkaValidOsaOrSi(stavka) {
            ValidationService.validate(stavka, 'required|object', 'stavka');

            var retval = null;

            if (stavka.ulazna_kategorija &&
                stavka.ulazna_kategorija.uid) {

                if (stavka.ulazna_kategorija.uid === AppConstants.OSNOVNO_SREDSTVO ||
                    stavka.ulazna_kategorija.uid === AppConstants.SITAN_INVENTAR) {
                    if (stavka.cijena >= AppConstants.OSNOVNO_SREDSTVO_MIN_IZNOS) {
                        retval = AppConstants.OSNOVNO_SREDSTVO;
                    }
                    else {
                        retval = AppConstants.SITAN_INVENTAR;
                    }
                }

            }

            return retval;
        }

        function getDozvoljeniNaciniPlacanja(nacinPlacanjaList, documentSlug) {
            ValidationService.validate(nacinPlacanjaList, 'required|object', 'nacinPlacanjaList');
            ValidationService.validate(documentSlug, 'required', 'documentSlug');

            var tmp = [];

            var listaDozvoljenih;
            if (documentSlug === AppConstants.IRA_SLUG) {
                listaDozvoljenih = AppConstants.DOZVOLJENI_NACINI_PLACANJA_IRA;
            }
            else if (documentSlug === AppConstants.URA_SLUG) {
                listaDozvoljenih = AppConstants.DOZVOLJENI_NACINI_PLACANJA_URA;
            }

            angular.forEach(nacinPlacanjaList, function (placanje) {
                if ($.inArray(placanje.metoda, listaDozvoljenih) > -1) {
                    tmp.push(placanje);
                }
            });
            return tmp;
        }

        function extractPartnerDefaultsForDocument(ulazniRacun) {
            ValidationService.validate(ulazniRacun, 'required|object', 'ulazniRacun');

            var retval = {};

            var partner = ulazniRacun.json_partner;
            if (partner && partner.iban) {
                retval.iban = partner.iban;
            }

            if (partner && partner.ura_dani_odgode) {
                retval.ura_dani_odgode = partner.ura_dani_odgode;
            }

            if (partner && partner.ira_dani_odgode) {
                retval.ira_dani_odgode = partner.ira_dani_odgode;
            }

            $log.debug("extractPartnerDefaultsForDocument", retval);

            return retval;
        }
    }

})();
