(function() {

    angular.module("conto")
        .controller('StavkaPopupCtrl', StavkaPopupCtrl);

    /** @namespace $scope.RacunFormCtrl */
    function StavkaPopupCtrl($scope, $log, close, $element, stavka, stavkaIndex, ulazniRacun) {

        var vm = this;
        vm.stavka = stavka;
        vm.stavkaIndex = stavkaIndex;
        vm.racun = ulazniRacun;

        vm.closeAndSave = function() {
            close({'update': vm.stavka, 'index': stavkaIndex});
        };

        vm.closeAndDelete = function() {
            close({'delete': vm.stavka, 'index': stavkaIndex});
        };

        vm.closeAndCancel = function() {
            close(null);
        }

    }

})();
