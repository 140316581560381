/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('KategorijaClanstva', KategorijaClanstva);

    function KategorijaClanstva($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            naziv: 'required|text'
        };

        return ClassUtils.defineModel('KategorijaClanstva', attributes);
    }

})();
