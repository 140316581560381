(function() {

    angular.module("conto")
        .factory('RestOptions', RestOptions);

    function RestOptions($log, $q, $http) {

        return function() {
            /* Params: apiUrl, model */

            var PROTECTED_PARAMS = ['sort', 'limit', 'q', 'page'];

            /* Defaults */
            var options = {
                params: {
                    sort: 'naziv,asc',
                    limit: '10',
                    q: '',
                    page: 1
                }
            };

            var service;
            service = {
                'get': get,
                'setQuery': setQuery,
                'getQuery': getQuery,
                'setSort': setSort,
                'getSort': getSort,
                'setLimit': setLimit,
                'getLimit': getLimit,
                'setPage': setPage,
                'getPage': getPage,
                'setCustomOption': setCustomOption,
                'getCustomOption': getCustomOption
            };

            /* class return*/
            return service;

            function set(params) {
                if (!params) throw new Error("Params object missing!");
                return options = params;
            }

            function get() {
                return options;
            }

            function setCustomOption(optionName, optionValue) {
                if ($.inArray(optionName, PROTECTED_PARAMS) > -1) throw new Error("customOption param not allowed!");
                var opt = get();
                opt.params[optionName] = optionValue;
                set(opt);

                return this;
            }

            function getCustomOption(optionName) {
                if ($.inArray(optionName, PROTECTED_PARAMS) > -1) throw new Error("customOption param not allowed!");
                return get().params[optionName];
            }

            function setSort(fieldName, direction) {
                if (!fieldName) throw new Error("Sort fieldName missing!");
                if (!direction) direction = 'asc';

                var opt = get();
                opt.params.sort = fieldName + ',' + direction;
                set(opt);

                return this;
            }

            function getSort() {
                var opt = get();
                if (opt.params && opt.params.sort) {
                    var sortOpts = opt.params.sort.split(',');
                    return {
                        fieldName: sortOpts[0],
                        direction: sortOpts[1]
                    }
                }
                else {
                    return false;
                }
            }

            function setLimit(limit) {
                if (!limit) limit = 10;

                var opt = get();
                opt.params.limit = limit;
                set(opt);

                return this;
            }

            function getLimit() {
                return get().params.limit;
            }

            function setPage(page) {
                if (!page) page = 1;

                var opt = get();
                opt.params.page = page;
                set(opt);

                return this;
            }

            function getPage() {
                return get().params.page;
            }

            function setQuery(query) {
                if (!query) query = '';

                /* Reset page if set*/
                setPage(1);

                var opt = get();
                opt.params.q = query;
                set(opt);

                return this;
            }

            function getQuery() {
                return get().params.q;
            }


        }

    }

})();
