(function() {

    angular.module('conto').filter('suptexttoclass', function($sce) {
        return function(input) {
            if (input !== null && input !== undefined) {
                return input.toLowerCase().replace(' ', '-').replace('.', '');
            }
            else {
                return null;
            }
        };
    });


})();
