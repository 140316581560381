(function() {

    angular.module("conto")
        .factory('ColorSwitchInterceptor', ColorSwitchInterceptor);

    function ColorSwitchInterceptor($log, $q, $cookies, $window, AppConfig) {

        //$log.debug('$log is here to show you that this is a regular factory with injection');

        var myInterceptor = {
            response: function(response) {
                var deferred = $q.defer();
                //$log.info("response111", response);
                //$log.info("response", response.headers());

                var currentColor = $cookies.get('AppColor');
                var newColor = response.headers("app-color");


                //$log.info("Tenant color: " + newColor);

                if (newColor && (currentColor !== newColor)) {
                    $log.info("Current color cookie: " + currentColor);
                    $log.info("Kad bude dobar trenutak napraviti cu color SWITCH");
                    switchColor(newColor);
                }

                deferred.resolve(response);
                return deferred.promise;
            },
            request: function(request) {
                var deferred = $q.defer();

                var currentColor = $cookies.get('AppColor');
                if (currentColor) {
                    //$log.warn("POstavljam i header color na: " + currentColor);
                    request.headers['AppColor'] = currentColor;
                }

                deferred.resolve(request);
                return deferred.promise;
            }
        };

        return myInterceptor;

        function switchColor(color) {
            $log.info("Radim switch na color: " + color, "Domain: " + AppConfig.domain);
            var cookieOptions = {
                'path': '/'
            };
            if (AppConfig.domain && AppConfig.domain != 'localhost') {
                cookieOptions.domain = AppConfig.domain;
            }
            $cookies.put("AppColor", color, cookieOptions);
            $window.location.reload();
        }

    }

})();
