(function () {

    angular.module("conto")
        .factory('TreeboxPopupOpener', TreeboxPopupOpener);

    function TreeboxPopupOpener($log, ValidationService, ModalService, $q) {

        var service;
        service = {
            'showTreeBox': showTreeBox
        };

        /* class "constructor" and initialisation */
        //service.init();
        return service;

        function showTreeBox(ulaznaKategorijaList, stavka, initialCatId) {
            var dfd = $q.defer();

            $log.debug('showTreeBox', {
                'ulaznaKategorijaList': ulaznaKategorijaList,
                'stavka': stavka
            });

            $log.debug("initialCatId", initialCatId);

            ModalService.showModal({
                templateUrl: 'Modules/Racun/RacunForm/treewidget-modal.html',
                controller: "TreewidgetCtrl as tw",
                inputs: {
                    'ulazneKategorijeList': ulaznaKategorijaList,
                    'odabranaUlaznaKategorija': stavka.ulazna_kategorija,
                    'initialCatId': initialCatId
                }
            }).then(function (modal) {
                modal.element.modal();
                modal.close.then(function (ulazna_kategorija) {
                    $('.modal-backdrop').remove();

                    if (stavka) {
                        dfd.resolve({stavka: stavka, kategorija: ulazna_kategorija});
                    }
                    else {
                        dfd.reject();
                    }

                });
                return modal.close;
            }, function (err) {
                $log.error('TreeboxPopupOpener', err);
                dfd.reject(err);
            });

            return dfd.promise;

        }

    }

})();
