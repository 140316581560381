/*(function() {

    angular.module("conto")
        .config(NgAriaConfig);

    function NgAriaConfig($ariaProvider) {
        $ariaProvider.config({
            tabindex: false,
            ariaInvalid: false
        });
    }

})();*/
