(function () {

    angular.module("conto")
           .component('pocetnoStanjeGodina', {
               templateUrl: 'Modules/PocetnoStanje/templates/pocetno-stanje-godina.html',
               controller: PocetnoStanjeGodinaComponent,
               require: {
                   pocetnoStanjeCtrl: '^pocetnoStanje'
               }
           });

    function PocetnoStanjeGodinaComponent($scope, $element, PartnerRest,
                                          TreeboxPopupOpener, UlaznaKategorijaService,
                                          PocetnoStanjeIzvodBlagajna,
                                          ModalService, $attrs, FetchDataInBatch,
                                          $log, RestOptions) {

        var vm = this;
        vm.document = new PocetnoStanjeIzvodBlagajna;
        vm.getYears = getYears;
        vm.setYear = setYear;

        this.$onInit = function () {

        };

        function setYear(year) {
            vm.pocetnoStanjeCtrl.document.godina = year;
            vm.pocetnoStanjeCtrl.switchTab(1);
        }

        function getYears() {
            var currentYear = moment();
            var lastYear = moment(currentYear);
            lastYear.subtract(1, 'year');

            return [lastYear.format('YYYY'), currentYear.format('YYYY')];
        }

    }

})();
