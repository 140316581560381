/**
 * Created by danijelmaric on 21/08/15.
 */
(function () {

    angular.module("conto")
        .factory('SupNacinPlacanja', SupNacinPlacanja);

    function SupNacinPlacanja($log, ValidationService, ClassUtils, AppConstants) {

        return function () {
            var attributes = {
                nacin_placanja: 'required|object',
                broj_racuna: 'iban',
                model: 'numeric',
                poziv_na_broj: 'text',
                dodatni_podaci: 'object'
            };

            var _documentSlug;
            var service = ClassUtils.defineModel('SupNacinPlacanja', attributes)();

            var serviceValidate = service.validate;
            service.validate = validate;
            service.customFeldsFormat = customFeldsFormat;
            service.formatFieldsDefault = formatFieldsDefault;

            function customFeldsFormat(documentSlug) {
                if (!(service && service.nacin_placanja)) return;

                if (documentSlug) _documentSlug = documentSlug;

                var attrTmp = angular.copy(attributes);
                switch (service.nacin_placanja.metoda) {
                    case AppConstants.VIRMAN:
                        attrTmp['broj_racuna'] = 'iban';
                        if (documentSlug === AppConstants.IRA_SLUG) {
                            attrTmp['model'] = 'numeric';
                            attrTmp['poziv_na_broj'] = 'text';
                        }
                        else {
                            attrTmp['model'] = 'numeric';
                            attrTmp['poziv_na_broj'] = 'text';
                        }
                        break;
                    case AppConstants.GOTOVINA_ZA_REFUNDACIJU:
                        attrTmp['dodatni_podaci'] = 'object';
                        break;
                }

                service.setAttributes(attrTmp);
            }

            function formatFieldsDefault() {
                service.setAttributes(attributes);
            }

            function validate() {
                customFeldsFormat(_documentSlug);
                serviceValidate();
            }

            return service;

        }

    }

})();
