(function () {

    angular.module("conto")
           .factory('SupPopupMessagesUtils', SupPopupMessagesUtils);

    function SupPopupMessagesUtils($log, $q, SweetAlert, $injector) {


        var definition = null;


        /* title, bundle, type, uid, note */

        var service;
        service = {
            'CALLBACK_SUCCESS': 'success',
            'CALLBACK_FAIL': 'fail',
            'CALLBACK_REJECT': 'reject',

            'formSaveConfrm': formSaveConfrm
        };

        /* class return*/
        return service;

        function formSaveConfrm(objectWithData, callback) {

            var promiseObject = objectWithData.restClass;

            SweetAlert.swal({
                    title: "Jeste li sigurni?",
                    text: "Želite li zaključiti dokument?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Zaključi dokument!",
                    closeOnConfirm: false
                },
                function (confirm) {

                    if (confirm) {
                        promiseObject.save(objectWithData.data, objectWithData.updateId).then(
                            /**
                             * @param data {*}
                             */
                            function (data) {

                                fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a
                                $log.debug("Save status: ", data);

                                if (data.status === service.CALLBACK_SUCCESS) {
                                    SweetAlert.swal({
                                            title: "Huuura!",
                                            type: "success",
                                            text: "Dokument zaključen i spremljen!",
                                            timer: 1500,
                                            showConfirmButton: false
                                        },
                                        function () {
                                            if (callback && typeof callback === 'function') callback(service.CALLBACK_SUCCESS, data);
                                            swal.close();
                                        });
                                }
                                else {
                                    var msg = '';
                                    if (data && data.message) msg = data.message;
                                    SweetAlert.swal({
                                        title: "Ups..",
                                        type: "error",
                                        text: "Greška kod spremanja dokumenta! " + msg
                                    });

                                    if (callback && typeof callback === 'function') callback(service.CALLBACK_FAIL, data);
                                }


                            },
                            function (err) {
                                $log.error("Kritična greška kod spremanja dokumenta", err.data);
                                SweetAlert.swal("Ups...", "Kritična greška!.", "error");
                                if (callback && typeof callback === 'function') callback(service.CALLBACK_REJECT, err);
                            });
                    }
                });
        }
    }

})();
