(function () {

    angular.module("conto")
        .controller('HomeCtrl', HomeCtrl);

    function HomeCtrl($log, sessionData) {
        var vm = this;


        vm.tenant = sessionData.tenant;
        vm.tenantReadyStatus = sessionData.tenant.isTenantReady();

    }

})();
