(function() {

    angular.module("conto")
        .controller('TreewidgetCtrl', TreewidgetCtrl);

    /** @namespace $scope.RacunFormCtrl */
    function TreewidgetCtrl($scope, $log, close, $element, odabranaUlaznaKategorija, initialCatId, ulazneKategorijeList) {

        var vm = this;

        //$log.info("odabranaUlaznaKategorija", odabranaUlaznaKategorija, ulazneKategorijeList);
        vm.odabranaUlaznaKategorija = odabranaUlaznaKategorija;
        vm.ulazneKategorijeList = ulazneKategorijeList;
        vm.initialCatId = initialCatId;

        vm.hidePopup = function() {
            close(vm.odabranaUlaznaKategorija);
        };

    }


})();
