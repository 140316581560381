(function () {

    angular.module("conto")
           .directive('supTableColumn', SupTableColumnDirective);

    function SupTableColumnDirective($log, $compile) {

        return {
            restrict: 'EA',
            require: '^supTable',
            link: function (scope, element, attr, supTableCtrl) {
                var html = element.html();
                var retval = '<a href="">' + html + ' <span class="glyphicon th-sort-arrow"></span></a>';

                element.html(retval);
                /**
                 * Hint: nakon svakog diranja html-a nekog elementa potrebno je pozvati $compile
                 * kako bi se eventualne direktive unutar tog html-a mogle izvšriti (npr. translation)
                 */
                $compile(element.contents())(scope);

                attr.direction = 'asc'; // default
                element.find('a').bind('click', function (e) {
                    e.preventDefault();

                    var direction = (attr.direction === 'asc') ? attr.direction = 'desc' : attr.direction = 'asc';

                    element.closest('thead').find('.th-sort-arrow').removeClass('glyphicon-menu-down');
                    element.closest('thead').find('.th-sort-arrow').removeClass('glyphicon-menu-up');
                    if (direction === 'desc') {
                        element.find('.th-sort-arrow').addClass('glyphicon-menu-up');
                    }
                    else {
                        element.find('.th-sort-arrow').addClass('glyphicon-menu-down');
                    }

                    supTableCtrl.reloadList(supTableCtrl.restOptions.setSort(attr.supTableColumn, direction).get());
                });

                scope.$on('supTable:reloadList', function (newValue, oldValue, scope) {
                    element.find('.th-sort-arrow').removeClass('glyphicon-menu-down');
                    element.find('.th-sort-arrow').removeClass('glyphicon-menu-up');

                    if (supTableCtrl && supTableCtrl.restOptions) {
                        var appliedSort = supTableCtrl.restOptions.getSort();
                        if (appliedSort.fieldName === attr.supTableColumn) {
                            if (appliedSort.direction === 'desc') {
                                element.find('.th-sort-arrow').addClass('glyphicon-menu-up');
                            }
                            else {
                                element.find('.th-sort-arrow').addClass('glyphicon-menu-down');
                            }
                        }
                    }
                });
            }
        };

    }

})();
