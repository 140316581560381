(function () {

    angular.module("conto")
        .factory('Racun', Racun);

    function Racun($log, ValidationService, ClassUtils, ValidationException) {

        return function () {

            var attributes = {
                id: 'required|uuid',
                externa_oznaka: 'required',
                interna_oznaka: 'alpha_dash',
                json_partner: 'required|object',
                datum_prijema: 'required|date',
                datum_dospijeca: 'required|date',
                datum_izdavanja: 'required|date',
                dani_odgode: 'required|numeric|min:0',
                datum_obracuna: 'required|date',
                datoteka: 'text',
                json_stavke: 'required|array',
                json_rekapitulacija: 'object',
                json_nacin_placanja: 'required|object',
                storno: 'alpha',
                ponisteno: 'alpha',
                napomena: 'text',
                storno_ref: 'uuid', /* Referenca na račun kojeg smo stornirali ili obrnuto */
                revision: 'numeric', /* Broj revizije */
                revision_ref: 'uuid' /* Referenca na račun kojeg smo uredili */
            };

            var service = ClassUtils.defineModel('Racun', attributes)();
            var serviceValidate = service.validate;
            service.validate = validate;
            service.checkIfDaniOdgodeValid = checkIfDaniOdgodeValid;

            function checkIfDaniOdgodeValid() {
                if (!service.datum_dospijeca || !service.datum_izdavanja) return true;
                return service && service.dani_odgode >= 0
            }

            function validate() {
                var errArray = [];

                /* Pozovi glavnu validaciju Ulaznog računa */
                ValidationService.tryCatch(serviceValidate, errArray);

                /* Validiraj stavku po stavku */
                angular.forEach(service.json_stavke, function (stavka, i) {
                    ValidationService.tryCatch(stavka.validate, errArray);
                });

                /* Validiraj način plaćanja */
                if (service.json_nacin_placanja &&
                    typeof service.json_nacin_placanja.validate === "function") {
                    ValidationService.tryCatch(service.json_nacin_placanja.validate, errArray);
                }

                if (errArray.length) {
                    throw new ValidationException(errArray);
                }

            }

            return service;

        };


    }

})();
