(function () {

    angular.module("conto")
        .factory('TenantRest', TenantRest);

    function TenantRest(Tenant, RestService, $log, AppConstants) {

        var config = {
            'model': Tenant,
            'apiUrl': 'tenant',
            'prepareForDb': prepareForDb,
            'prepareForClient': prepareForClient
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

        function prepareForClient(item) {

            item.commercial_package = findItemById(item.naplatni_paket, AppConstants.PAKETI_LIST);
            item.billing_interval = findItemById(item.billing_interval, AppConstants.RAZDOBLJE_LIST);

            return item;
        }

        function prepareForDb(data) {
            if (data) {
                /* Defaultni, za sada zahardkodirani */
                data.pdv_obveznik = 0;
                if (data.naselje) data.naselje_id = data.naselje.id;
                if (data.commercial_package) data.naplatni_paket = data.commercial_package.id;
                if (data.billing_interval) data.billing_interval = data.billing_interval.id;
                return data;
            }

        }

    }

})();
