(function () {

    angular.module("conto")
        .config(HttpInterceptorConfig);

    function HttpInterceptorConfig(AppConfig, $httpProvider) {

        $httpProvider.interceptors.push('AuthInterceptor');

        if (!AppConfig.disableColorSwitch) {
            $httpProvider.interceptors.push('ColorSwitchInterceptor');
        }
        else {
            console.info('Color switch disabled.');
        }
    }

})();
