(function () {

    angular.module("conto")
           .factory('PartnerRest', PartnerRest);

    function PartnerRest(Partner, RestService, $log, AppConstants) {

        var config = {
            'model': Partner,
            'apiUrl': 'partner',
            'prepareForDb': prepareForDb
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

        function prepareForDb(data) {
            if (data.drzava && data.drzava.id) {
                data.drzava_id = data.drzava.id;
            }
            delete data.drzava;

            if (data.kategorija && data.kategorija.id) {
                data.kategorija_clanstva = data.kategorija.id;
            }
            delete data.kategorija;

            if (data.naselje && data.naselje.id) {
                data.naselje_id = data.naselje.id;
                data.postanski_broj = data.naselje.postanski_broj;
                data.mjesto = data.naselje.go_naziv;
            }

            delete data.naselje;

            return data;
        }

    }

})();
