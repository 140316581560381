(function () {

    angular.module("conto")
        .directive('treeboxSelect', TreeboxSelect);

    function TreeboxSelect($log, $compile) {

        return {
            restrict: 'EA',
            require: ['ngModel'],
            scope: {
                data: '=',
                onSelect: '&',
                initialCatId: '='
            },
            template: '<div class="suptreebox-directive"></div>',
            controller: function ($scope) {

            },
            link: function (scope, element, attr, ctrls) {
                //$log.debug("treeboxSelect", scope, element, attr, ctrls);
                var myHome = 'home';

                var ngModelCtrl = ctrls[0];
                element = element.find('.suptreebox-directive');

                ngModelCtrl.$formatters.push(function (modelValue) {
                    return modelValue;
                });

                ngModelCtrl.$render = function () {
                    if (scope.initialCatId) myHome = scope.initialCatId;
                    element.html(getTreebox(myHome));
                    if (ngModelCtrl.$viewValue) {

                        var viewVal = getItemById(ngModelCtrl.$viewValue.id);

                        element.html(getTreebox(myHome));
                        if (viewVal && viewVal.roditelj_id) {
                            element.append(getTreebox(viewVal.roditelj_id));
                        }
                    }
                    if (ngModelCtrl.$viewValue && ngModelCtrl.$viewValue.id) {
                        element.find('[data-id="' + ngModelCtrl.$viewValue.id + '"]').addClass("active");
                    }
                    refreshBinds();
                };

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return viewValue;
                });

                var tbData = scope.data;

                function getTreebox(parent, colorizeParent) {
                    element.find('.list-group').remove();

                    //$log.debug("PARENT ID", parent);
                    var htmlToConcatenate = '';

                    var selectedGroup;
                    angular.forEach(scope.data, function (item, i) {
                        if (!item.roditelj_id) item.roditelj_id = myHome;
                        if (item.id === parent) {
                            selectedGroup = item;
                        }

                        if ((item.roditelj_id === parent)) {
                            var spanIcon = '';
                            if (hasChilds(item.id)) {
                                spanIcon = '<span class="glyphicon glyphicon-chevron-right pull-right"></span>';
                            }

                            htmlToConcatenate += '<a href="" class="list-group-item tw-select-group" data-id="' + item.id + '">';
                            htmlToConcatenate += '<span class="tw-item-title">' + item.naziv +'</span>';
                            if (item.opis) {
                                htmlToConcatenate += '<p class="small desc">' + item.opis + '</p>';
                            }
                            htmlToConcatenate += spanIcon;
                            htmlToConcatenate += '</a>';
                        }
                    });


                    var htmlToWrite = '';

                    if (selectedGroup && selectedGroup.roditelj_id) {
                        htmlToWrite += getTreebox(selectedGroup.roditelj_id);
                    }


                    if (htmlToConcatenate) {
                        htmlToWrite += '<div class="list-group group-' + parent + '">';
                        htmlToWrite += htmlToConcatenate;
                        htmlToWrite += '</div>';

                        var newEl = angular.element("<tmp>" + htmlToWrite + "</tmp>");
                        newEl.find('[data-id="' + parent + '"]').addClass("active");
                        htmlToWrite = newEl.html();
                    }
                    else if (parent) {
                        //$log.info("Odabrat ću ovo", parent, getItemById(parent));
                        /*if (scope.onSelect && typeof scope.onSelect === 'function') {
                         scope.onSelect({selectedObject: getItemById(parent)});
                         }*/
                        ngModelCtrl.$setViewValue(getItemById(parent));
                        if (scope.onSelect && typeof scope.onSelect === "function") {
                            var getItem = getItemById(parent);
                            if (getItem) {
                                scope.onSelect({'selectedItem': getItem})
                            }
                        }
                    }

                    refreshBinds();
                    return htmlToWrite;
                }

                function refreshBinds() {
                    element.find('.tw-select-group').unbind();
                    element.find('.tw-select-group').bind('click', function (e) {
                        e.preventDefault();
                        var _data = $(this).data();
                        element.html(getTreebox(myHome));
                        element.append(getTreebox(_data.id));
                        refreshBinds();
                    });
                }

                function hasChilds(id) {
                    var retval = false;
                    angular.forEach(scope.data, function (item, i) {
                        if (item.roditelj_id === id) {
                            retval = true;
                        }
                    });

                    return retval;
                }

                function getItemById(id) {
                    var retval = null;
                    angular.forEach(scope.data, function (item, i) {
                        if (item.id === id) {
                            retval = item;
                        }
                    });

                    return retval;
                }

                //element.html(getTreebox(myHome));
                //refreshBinds();
            }
        };

    }

})();
