(function () {

    angular.module("conto")
           .controller('KategorijaClanstvaFormCtrl', KategorijaClanstvaFormCtrl);

    function KategorijaClanstvaFormCtrl(KategorijaClanstva, KategorijaClanstvaRest, supModalOptions, $stateParams, close, $state) {
        var vm = this;

        const LIST_ROUTE = 'KategorijaClanstva.list';
        vm.document = new KategorijaClanstva;
        vm.modelRest = KategorijaClanstvaRest;
        vm.afterFormCallback = afterFormCallback;
        vm.cancel = cancel;
        vm.hidePopup = hidePopup;

        activate();

        function afterFormCallback(item) {
            if (close) close(item);
            else $state.go(LIST_ROUTE);
        }

        function hidePopup() {
            close();
        }

        function cancel() {
            if (close) close();
            else $state.go(LIST_ROUTE);
        }

        function activate() {
            vm.editId = close ? 'new' : $stateParams.id;

            if (vm.editId === 'new' &&
                !vm.document.naziv &&
                supModalOptions &&
                supModalOptions.selectBoxData) {
                vm.document.naziv = supModalOptions.selectBoxData;
            }
        }

    }

})();
