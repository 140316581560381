(function() {

    angular.module("conto")
           .factory('BlagajnaRest', BlagajnaRest);

    function BlagajnaRest(Blagajna, RestService) {

        var config = {
            'model': Blagajna,
            'apiUrl': 'blagajna'
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

    }

})();
