(function () {

    angular.module("conto")
        .factory('TemeljnicaRest', TemeljnicaRest);

    function TemeljnicaRest($log, Temeljnica, RestService, TemeljnicaStavka, SupNacinPlacanja) {

        var config = {
            'model': Temeljnica,
            'apiUrl': 'temeljnica',
            'prepareForDb': prepareForDb,
            'prepareForClient': prepareForClient
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

        function prepareForClient(item) {
            angular.forEach(item.stavke, function (stavkaData, i) {
                var novaStavka = new TemeljnicaStavka;
                stavkaData.tip = stavkaData.kategorija;
                novaStavka.setData(stavkaData);
                item.stavke[i] = novaStavka;
            });

            return item;
        }

        /* Custom prepare for db functions */
        function prepareStavkeForDb(stavke) {
            var preparedStavke = [];
            angular.forEach(stavke, function (stavka, i) {
                var stavkaPrepared = {

                    "konto": stavka.konto,
                    "partner_id": stavka.partner.id,
                    "duguje": stavka.duguje ? stavka.duguje : 0,
                    "potrazuje": stavka.potrazuje ? stavka.potrazuje : 0,
                    'externa_oznaka': stavka.externa_oznaka,
                    'interna_oznaka': stavka.interna_oznaka,
                    'datum_dokumenta': stavka.datum_dokumenta,
                    'datum_dospijeca': stavka.datum_dospijeca,
                    'napomena': stavka.napomena
                };

                preparedStavke.push(stavkaPrepared);
            });

            $log.info("PREPARIRANE STAVKE", preparedStavke);

            return preparedStavke;
        }

        function prepareForDb(data) {
            data.stavke = prepareStavkeForDb(data.stavke);
            return data;
        }
    }

})();
