(function() {

    angular.module("conto")
        .factory('UlaznaKategorijaService', UlaznaKategorijaService);

    function UlaznaKategorijaService($log, ValidationService, AppConstants) {

        var service;
        service = {
            'formatForTreebox': formatForTreebox,
            'formatForModel': formatForModel,
            'getDostupneUlazneKategorije': getDostupneUlazneKategorije,
            'findKategorijaBySlug': findKategorijaBySlug,
            'findKategorijaById': findKategorijaById,
            'hasChilds': hasChilds
        };

        /* class "constructor" and initialisation */
        //service.init();
        return service;

        /*
         * Funkcija za formatiranje podataka kako bi se mogli prikazivati u treeboxu
         */
        function formatForTreebox(data) {
            ValidationService.validate(data, 'required', 'data');

            var treeBoxData = [];
            angular.forEach(data, function (value, key) {

                value.hasChilds = false;
                angular.forEach(data, function (value2, key2) {
                    if (value.id == value2.roditelj_id) {
                        value.hasChilds = true;
                    }
                });

                treeBoxData.push(value);

            });

            return treeBoxData;
        }

        function generateBreadCrumb() {

        }

        function formatForModel(ulaznaKategorijaRaw) {
            return {
                id: ulaznaKategorijaRaw.id,
                naziv: ulaznaKategorijaRaw.value,
                roditelj_id: ulaznaKategorijaRaw.parent
            }
        }

        function getDostupneUlazneKategorije(itemsList) {
            var dostupne = formatForTreebox(itemsList);
            var retval = [];
            angular.forEach(dostupne, function (item) {
                if (!item.hasChilds && item.vrsta_dokumenta === AppConstants.URA_SHORT_SLUG) {
                    retval.push(item);
                }
            });

            return retval;
        }

        function findKategorijaById(itemsList, id) {
            var retval = [];
            var dostupne = formatForTreebox(itemsList);
            angular.forEach(dostupne, function (item) {
                if (item.id === id) {
                    retval = item;
                }
            });

            return retval;
        }

        function findKategorijaBySlug(itemsList, slug) {
            var retval = [];
            var dostupne = formatForTreebox(itemsList);
            angular.forEach(dostupne, function (item) {
                if (item.uid === slug) {
                    retval = item;
                }
            });

            return retval;
        }

        function hasChilds(dataList, id) {
            var retval = false;
            angular.forEach(dataList, function (item, i) {
                if (item.roditelj_id === id) {
                    retval = true;
                }
            });

            return retval;
        }

    }

})();
