(function () {

    angular.module("conto")
        .factory('IzvodStavka', IzvodStavka);

    function IzvodStavka($log, ValidationService, ClassUtils, ValidationException, AppConstants) {


        return function () {
            var attributes = {
                datum: 'required|date',
                tip: 'required|object',
                partner: 'required|object',
                rbr: 'required|numeric',
                priljev: 'numeric|required',
                odljev: 'numeric|required',
                napomena: 'text'
            };

            var service = ClassUtils.defineModel('IzvodStavka', attributes)();

            service.getStavkaFlux = getStavkaFlux;
            return service;

            function getStavkaFlux() {
                var stavka = service;
                if (stavka.priljev > 0 || stavka.priljev < 0) {
                    return AppConstants.PRILJEV;
                }
                else if (stavka.odljev > 0 || stavka.odljev < 0) {
                    return AppConstants.ODLJEV;
                }
                else {
                    return AppConstants.NOT_DEFINED_PRILJEV_ODLJEV;
                }
            }
        };

    }

})();
