/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('Drzava', Drzava);

    function Drzava($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            brojcana_sifra: 'text',
            naziv: 'required'
        };

        return ClassUtils.defineModel('Drzava', attributes);

    }

})();
