/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('User', User);

    function User($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            name: 'text',
            print_name: 'text',
            email: 'required|email',
            password: 'alpha_num',
            oib: 'oib',
            tenant_id: 'required'
        };

        return ClassUtils.defineModel('User', attributes);

    }

})();
