(function() {

    angular.module("conto")
        .directive('supTableHeading', SupTableHeading);

    function SupTableHeading($log, $compile) {

        return {
            restrict: 'EA',
            require: '^supTable',
            link: function(scope, element, attr, supTableCtrl) {
                //$log.debug("SupTableHeading", scope, element, attr);
                //$log.debug("supTableCtrl", supTableCtrl);
                var html = $(element).html();
                
                var htmlCheck;
                htmlCheck = '<th class="text-center row-main-check"> <div> <label> <input type="checkbox" class="selectAll" value="" /> </label> </div> </th>';

                $(element).prepend(htmlCheck);

                $(element).find('.selectAll').bind("click", function(event) {
                    supTableCtrl.selectAll();
                    scope.$apply();
                });

                scope.$on('supTableRow:selectOne', function(event, mass) {
                    //$log.debug("BBBBB", mass);
                    if (mass === false) {
                        $(element).find('input[type=checkbox]').prop("checked", false);
                    }
                    else if (supTableCtrl.data.length === supTableCtrl.selectedItems.length) {
                        $(element).find('input[type=checkbox]').prop("checked", true);   
                    }
                })


            
            }
        };

    }

})();