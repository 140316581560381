(function() {

    angular.module("conto")
        .directive('supModuleHeading', SupModuleHeading);

    function SupModuleHeading() {

        return {
            restrict: 'E',
            scope: {
                title: '@',
                hasSidebar: '@'
            },
            templateUrl: 'Components/SupModuleHeading/sup-module-heading.html',
            link: function() {

            },
            controller: function ($scope, $log, SessionDataRest, AppConfig) {

                $scope.appColor = AppConfig.appColor;
                $scope.debugMode = AppConfig.debug;

                SessionDataRest.getList({cache: true}).then(function(sessionData) {
                    $scope.data = sessionData;
                })

            }
        };

    }

})();
