(function () {

    angular.module("conto")
           .controller('BlagajnickiDokumentPrintCtrl', BlagajnickiDokumentPrintCtrl);

    /** @namespace $scope.RacunFormCtrl */
    function BlagajnickiDokumentPrintCtrl($scope, $log, printData) {

        var vm = this;
        vm.printObj = printData;
        vm.tenant = printData.fetchInBatch.SessionDataRest.tenant;
        vm.user = printData.fetchInBatch.SessionDataRest.user;

    }


})();
