(function() {

    angular.module("conto")
        .factory('RestService', RestService);

    function RestService($log, $q, $http, AppConfig, RestServiceGetList, RestServiceGetOne, RestServiceSave, RestServiceRemove) {

        return function() {
            /* Params: apiUrl, model */
            var config;
            var restServiceGetList = RestServiceGetList();
            var restServiceGetOne = RestServiceGetOne();
            //var restServiceSave = RestServiceSave();
            //var restServiceRemove = RestServiceRemove();

            var service;
            service = {
                'setConfig': setConfig,
                'getList': getList,
                'getOne': getOne,
                'save': save,
                'remove': remove
            };

            /* class return*/
            return service;

            function setConfig(params) {
                if (!params) throw new Error("Config object missing!");
                if (!params.apiUrl) throw new Error("Config apiUrl param missing!");
                config = params;
            }

            /*
             * @param options
             * {query: 'some test query, sort: {'naziv': 'desc'}}
             *
             */
            function getList(options) {
                if (!options) options = {};
                //var restServiceGetList = new RestServiceGetList;
                return restServiceGetList.activate(options, config);
            }

            function getOne(id, options) {
                if (!id) throw new Error("RestService getOne 'id' param missing!");
                if (!options) options = {};
                //var restServiceGetOne = new RestServiceGetOne;
                return restServiceGetOne.activate(id, options, config);
            }

            /**
             *
             * @param data
             * @param updateId Ako je updateId={UUID} onda se šalje PUT request;
             *               defaultno se šalje POST (create)
             * @returns {*}
             */
            function save(data, updateId) {
                if (!data) throw new Error("RestService data missing!");
                if (!config.model) throw new Error("RestService config.model missing!");

                config.updateId = updateId ? updateId : null;
                var restServiceSave = new RestServiceSave;
                return restServiceSave.activate(data, config);
            }

            function remove(id) {
                if (!id) throw new Error("RestService id missing!");
                var rs = new RestServiceRemove;
                return rs.activate(id, config);
            }

        }

    }

})();
