(function () {

    angular.module("conto")
           .component('partnerAutocomplete', {
               templateUrl: 'Components/PartnerAutocomplete/partner-autocomplete.html',
               controller: PartnerAutocompleteCtrl,
               require: {
                   ngModel: 'ngModel'
               },
               bindings: {
                   partnerType: '<',
                   batchData: '<?'
               }
           });

    function PartnerAutocompleteCtrl($scope, $element, PartnerRest, ModalService, $attrs, FetchDataInBatch, $log, RestOptions) {
        var vm = this;

        vm.partnerListReload = partnerListReload;
        vm.activate = activate;
        vm.chooseItem = chooseItem;
        vm.addNewPartner = addNewPartner;
        vm.joinBy = joinBy;

        vm.partnerList = [];
        vm.selected = null;

        /*$log.info('COMPONENT this', this);
        $log.info('COMPONENT $scope', $scope);
        $log.info('COMPONENT $element', $element);
        $log.info('COMPONENT $attrs', $attrs);*/


        function activate() {
            partnerListReload();
        }

        this.$onInit = function () {
            this.ngModel.$render = function () {
                vm.selected = vm.ngModel.$viewValue;
            };

            $scope.$watch('$ctrl.partnerType', function (newVal) {
                partnerListReload();
            });

            activate();
        };

        function chooseItem() {
            vm.ngModel.$setViewValue(vm.selected);
        }

        function partnerListReload(e) {
            var partnerType = vm.partnerType;
            //alert(partnerType);

            var restOptions = new RestOptions;
            if (partnerType && partnerType !== '*') {
                restOptions.setCustomOption(partnerType, true);
            }
            $log.debug("restOptions", restOptions.get());
            PartnerRest.getList(restOptions.setQuery(e).get()).then(function (data) {
                if (data && data.length) {
                    vm.partnerList = data;
                }
                else {
                    vm.partnerList = [{'naziv': e, id: 'new'}];
                }
            });
        }

        function joinBy(arr) {
            var myArr = [];
            angular.forEach(arr, function(item) {
                if (item) myArr.push(item);
            });

            return myArr.join(', ');
        }

        function addNewPartner(selectRetval) {
            if (!vm.batchData) {
                var fetchInBatch = new FetchDataInBatch;
                fetchInBatch.extendItemsToFetch('UlaznaKategorijaRest').getData().then(function(response) {
                    vm.batchData = response;
                    callPartnerModal(selectRetval);
                });
            }
            else {
                callPartnerModal(selectRetval);
            }
        }

        function callPartnerModal(selectRetval) {
            var partnerType = vm.partnerType;
            ModalService.showModal({
                templateUrl: 'Modules/Partner/PartnerForm/partner-modal.html',
                controller: "PartnerFormCtrl as PartnerFormCtrl",
                inputs: {
                    'fetchInBatch': vm.batchData,
                    'supModalOptions': {
                        'autoFlag': partnerType,
                        'selectBoxData': selectRetval
                    },
                    'partnerType': function () {
                        return null;
                    }
                }
            }).then(function (modal) {
                modal.element.modal();
                modal.close.then(function (partner) {
                    $('.modal-backdrop').remove();
                    if (partner) {
                        vm.selected = partner;
                        chooseItem();
                    }
                });
            });
        }

    }

})();
