(function () {

    angular.module("conto")
        .factory('IzlazniRacunStavka', IzlazniRacunStavka);

    function IzlazniRacunStavka($log, RacunStavka) {


        return function () {
            var service = new RacunStavka();
            service.removeValidator('validateOsaSiValue'); //TODO: temporary solution
            service.removeFormatter('formatFeildsForOsa'); //TODO: temporary solution
            var attributes = service.getAttributes();
            attributes['opis'] = 'required|text';
            service.setAttributes(attributes);
            $log.debug(service.getAttributes());
            return service;
        };

    }

})();
