(function () {

    angular.module("conto")
        .factory('IzlazniRacun', IzlazniRacun);

    function IzlazniRacun($log, Racun) {

        return function () {

            var service = Racun();
            var attributes = service.getAttributes();
            attributes['externa_oznaka'] = 'text';
            service.setAttributes(attributes);
            return service;

        };

    }

})();
