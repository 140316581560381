(function() {

    angular.module("conto")
        .factory('SupPrintService', SupPrintService);

    function SupPrintService($log, Drzava, $q, RestService, $document, $compile, $controller, $rootScope, $templateRequest, $timeout, $animate) {

        return function() {

            var service;
            service = {
                'print': print
            };

            return service;

            function print(options, callback) {
                if (!options.divId) throw new Error("options.divId not set for SupPrintService");
                var body = $document.find('body');

                body.append('<iframe id="frame-' + options.divId + '" name="frame-' + options.divId + '" src="" class="hidden"></iframe>');

                //$log.debug(options);
                var printContents = document.getElementById(options.divId).innerHTML;
                var iframe = body.find('#frame-' + options.divId);
                iframe.contents().find('html').html('<html><head><link href="dist/css/vendor.css" rel="stylesheet"></head><body>' + printContents + '<script type="text/javascript">setTimeout(function(){ window.parent.frames["frame-' + options.divId + '"].print()}, 100);</script></body></html>');

                if (callback && typeof callback === 'function') {
                    return callback();
                }

                // Makni iframe iz DOM-a nakon printanja
                body.find('#frame-' + options.divId).remove();
                //$log.debug(iframe.contents().find('html').html());

            }

        }

    }

})();

