/**
 * Created by danijelmaric on 12/12/15.
 */
(function() {

    angular.module("conto")
        .config(CompileProvider);

    function CompileProvider($compileProvider, AppConfig) {
        /**
         * debugMode mora biti upaljen dok se vrte testovi
         * jer bez toga oni ne mogu raditi
         */
        var debugMode = AppConfig.debug || true;
        $compileProvider.debugInfoEnabled(debugMode);
    }

})();
