(function () {

    angular.module("conto")
           .controller('TemeljnicaFormCtrl', TemeljnicaFormCtrl);

    function TemeljnicaFormCtrl($scope,
                                $log,
                                SessionDataRest,
                                NacinPlacanjaRest,
                                Temeljnica,
                                TemeljnicaRest,
                                PartnerRest,
                                uuid4,
                                $stateParams,
                                $http,
                                documentSlug,
                                RacunService,
                                ModalService,
                                SupPopupMessagesUtils,
                                SweetAlert,
                                $state,
                                AppConstants,
                                fetchInBatch,
                                PrintService,
                                AppConfig) {
        var vm = this;
        vm.documentSlug = documentSlug;

        vm.racun = new Temeljnica();

        vm.fetchInBatch = fetchInBatch;
        vm.tenantData = fetchInBatch.tenant;
        vm.editId = $stateParams.id || 'new';
        vm.updateId = (vm.editId !== 'new') ? vm.editId : null;
        vm.disabled = false;
        vm.isActivated = false;
        vm.saveForm = saveForm;

        activate();

        function activate() {
            if (vm.editId !== 'new') {
                TemeljnicaRest.getOne(vm.editId).then(
                    formatDocumentForEdit,
                    function (err) {
                        $log.error(err);
                    });
            }
            else {
                vm.racun.id = uuid4.generate();
                vm.racun.datum = moment.utc();
                vm.isActivated = true;
            }

        }

        function formatDocumentForEdit(data) {
            vm.racun.setData(data);
            vm.disabled = true;
            vm.isActivated = true;
        }

        /* Završna provjera i spremanje ulaznog računa */
        function saveForm() {
            vm.racun.datum_obracuna = new Date();
            vm.racun.datum_prijema = new Date(); //TODO za sada preskačemo, trebat će nam kasnije

            $log.info("saveForm", vm.racun, vm.racun.rawData);


            try {
                vm.racun.validate(); // Throws exception
                $log.info('TEMELJNICA PRIJE SPREMANJA', vm.racun.getData());
            }
            catch (err) {
                $log.warn("Validation error", err.errorsList);
                setTimeout(function () {
                    angular.element(".has-error:first").focus();
                }, 10);
                return false;
            }


            SupPopupMessagesUtils.formSaveConfrm({
                restClass: TemeljnicaRest,
                data: vm.racun,
                updateId: vm.updateId
            }, function (status, data) {
                if (status === SupPopupMessagesUtils.CALLBACK_SUCCESS) {
                    $state.go(vm.documentSlug + '.list');
                }
            });

        }

    }
})();
