(function() {

    angular.module("conto")
        .factory('KategorijaClanstvaRest', KategorijaClanstvaRest);

    function KategorijaClanstvaRest(KategorijaClanstva, RestService) {

        var config = {
            'model': KategorijaClanstva,
            'apiUrl': 'kategorija-clanstva'
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;
    }

})();
