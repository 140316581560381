(function () {

    angular.module("conto")
           .controller('RacunFormCtrl', RacunFormCtrl);

    function RacunFormCtrl($scope,
                           $log,
                           SessionDataRest,
                           NacinPlacanjaRest,
                           RacunDocument,
                           PartnerRest,
                           uuid4,
                           $stateParams,
                           $http,
                           RacunStavka,
                           RacunService,
                           RacunRest,
                           ModalService,
                           SweetAlert,
                           $state,
                           AppConstants,
                           fetchInBatch,
                           documentSlug,
                           SupPopupMessagesUtils,
                           PrintService,
                           SupNacinPlacanja,
                           AppConfig) {
        var vm = this;
        vm.documentSlug = documentSlug;

        vm.racun = new RacunDocument();
        /**
         * Prosljeđivanje modela u kontroler za stavke
         */
        vm.stavkaModel = RacunStavka;

        vm.fetchInBatch = fetchInBatch;
        vm.nacinPlacanjaList = RacunService.getDozvoljeniNaciniPlacanja(fetchInBatch.NacinPlacanjaRest, vm.documentSlug);
        vm.tenantData = fetchInBatch.tenant;
        vm.editId = $stateParams.id || 'new';
        vm.updateId = (vm.editId !== 'new') ? vm.editId : null;
        vm.disabled = false;
        vm.isActivated = false;
        vm.nacinPlacanjaDefaults = {};
        vm.groupByPdvList = {};
        vm.print = print;

        vm.calculateDaniPlacanja = calculateDaniPlacanja;
        vm.reloadRekapitulacija = reloadRekapitulacija;
        vm.saveForm = saveForm;
        vm.addNewPartner = addNewPartner;
        vm.extractPartnerDefaultsForDocument = extractPartnerDefaultsForDocument;

        activate();

        function activate() {
            if (vm.editId !== 'new') {
                RacunRest.getOne(vm.editId).then(
                    formatDocumentForEdit,
                    function (err) {
                        $log.error(err);
                    });
            }
            else {
                vm.racun.id = uuid4.generate();

                // TODO: refaktorirati ovo nakon laucnha
                if (vm.documentSlug === AppConstants.IRA_SLUG) {
                    vm.racun.datum_izdavanja = moment.utc();

                    var jsonNacinPlacanja = new SupNacinPlacanja;
                    jsonNacinPlacanja.setData({'broj_racuna': fetchInBatch.SessionDataRest.tenant.iban});
                    vm.racun.json_nacin_placanja = jsonNacinPlacanja;
                }

                vm.isActivated = true;
                refreshDateOptions();

                $scope.$watch('RacunFormCtrl.racun.json_partner', extractPartnerDefaultsForDocument);
            }
        }

        function formatDocumentForEdit(data) {
            /*if (data.storno_ref) {
             vm.disabled = true;
             } TODO: privremeno maknuto do nekog sljedećeg releasa! */
            vm.racun.setData(data);
            vm.disabled = true;

            // getRevisions(); TODO: privremeno maknuto do nekog sljedećeg releasa!
            vm.isActivated = true;
        }

        function addNewPartner(vars) {
            $log.info('Prosljedjeno', vars);
            ModalService.showModal({
                templateUrl: 'Modules/Partner/PartnerForm/partner-modal.html',
                controller: "PartnerFormCtrl as PartnerFormCtrl",
                inputs: {
                    'fetchInBatch': fetchInBatch,
                    'supModalOptions': {'selectBoxData': vars},
                    'partnerType': function() {
                        return null;
                    }
                }
            }).then(function (modal) {
                modal.element.modal();
                modal.close.then(function (partner) {
                    $('.modal-backdrop').remove();
                    if (partner) {
                        vm.racun.json_partner = partner;
                    }
                });
            });
        }

        function extractPartnerDefaultsForDocument() {
            vm.nacinPlacanjaDefaults = RacunService.extractPartnerDefaultsForDocument(vm.racun);
            if (vm.nacinPlacanjaDefaults.ura_dani_odgode && vm.documentSlug === AppConstants.URA_SLUG) {
                vm.racun.dani_odgode = vm.nacinPlacanjaDefaults.ura_dani_odgode;
                RacunService.calculateDatumDospijeca(vm.racun);
            }

            if (vm.nacinPlacanjaDefaults.ira_dani_odgode && vm.documentSlug === AppConstants.IRA_SLUG) {
                vm.racun.dani_odgode = vm.nacinPlacanjaDefaults.ira_dani_odgode;
                RacunService.calculateDatumDospijeca(vm.racun);
            }

            $log.debug("extractPartnerDefaultsForDocument", {
                "Datum Izdavanja": vm.racun.datum_izdavanja,
                "Datum dospijeca": vm.racun.datum_dospijeca,
                "Dani odgode": vm.racun.dani_odgode,
                "vm.nacinPlacanjaDefaults": vm.nacinPlacanjaDefaults

            })
        }

        function getRevisions() {
            $http({
                method: 'GET',
                url: AppConfig.server + '/api/ulazni-racun/' + vm.updateId + '/revisions'
            }).then(function (response) {
                vm.revisionsList = response.data;
                vm.isActivated = true;
            });
        }

        function calculateDaniPlacanja() {
            refreshDateOptions();
            if (vm.racun.dani_odgode && !moment(vm.racun.datum_dospijeca).isValid()) {
                RacunService.calculateDatumDospijeca(vm.racun);
            }
            else {
                RacunService.calculateDaniPlacanja(vm.racun);
            }
        }

        function reloadRekapitulacija() {
            vm.rekapitulacija = RacunService.calculateRekapitulacija(vm.racun);
            vm.groupByPdvList = RacunService.grupirajPoreznuRekapitulaciju(vm.racun);
        }

        function refreshDateOptions() {
            vm.dateDatumDospijecaOptions = {
                startDate: moment.utc(vm.racun.datum_izdavanja).format(AppConstants.DDMMYYY)
            };

            var danasnjiDatum = moment.utc();
            var datumDospijeca = moment.utc(vm.racun.datum_dospijeca);
            //$log.debug(vm.racun.datum_dospijeca);
            var datumIzdavanjaLimit = (vm.racun.datum_dospijeca && datumDospijeca && datumDospijeca.isValid() && datumDospijeca.isBefore(danasnjiDatum)) ? datumDospijeca : danasnjiDatum;
            vm.dateDatumIzdavanjaOptions = {
                //endDate: moment.utc(vm.racun.datum_dospijeca).format(AppConstants.DDMMYYY)
                endDate: datumIzdavanjaLimit.format(AppConstants.DDMMYYY)
            };
        }

        function print(data) {
            if (!data) data = vm.racun;
            PrintService.print({
                templateUrl: 'Modules/Racun/RacunPrint/izlazni-racun.html',
                controller: "IzlazniRacunPrintCtrl as print",
                inputs: {
                    printData: {
                        'racun': data,
                        'fetchInBatch': fetchInBatch
                    }
                }
            });
        }

        /* Završna provjera i spremanje ulaznog računa */
        function saveForm(options) {
            //vm.racun.datum_obracuna = moment.utc(); //TODO WTF? ovo se nebi trebalo slati
            //vm.racun.datum_prijema = moment.utc(); //TODO za sada preskačemo, trebat će nam kasnije

            /**
             * TODO: OVO MAKNUTI NAKON FIX-a NA SERVERU
             * @type {string|string|*}
             */
            vm.racun.datum_obracuna = vm.racun.datum_izdavanja;
            vm.racun.datum_prijema = vm.racun.datum_izdavanja;

            try {
                vm.racun.validate(); // Throws exception
            }
            catch (err) {
                $log.warn("Validation error", err.errorsList);
                setTimeout(function () {
                    angular.element(".has-error:first").focus();
                }, 10);
                return false;
            }


            SupPopupMessagesUtils.formSaveConfrm({
                restClass: RacunRest,
                data: vm.racun,
                updateId: vm.updateId
            }, function (status, data) {
                if (status === SupPopupMessagesUtils.CALLBACK_SUCCESS) {
                    vm.racun.interna_oznaka = data.interna_oznaka;
                    vm.racun.updated_at = data.updated_at;
                    if (options && options.print) print();
                    $state.go(vm.documentSlug + '.list');
                }
            });

        }

    }
})();
