(function () {

    angular.module("conto")
           .component('treeboxAutocomplete', {
               templateUrl: 'Components/TreeboxAutocomplete/treebox-autocomplete.html',
               controller: TreeboxAutocompleteCtrl,
               require: {
                   ngModel: 'ngModel'
               },
               bindings: {
                   documentType: '<',
                   parentUid: '<?',
                   batchData: '<?'
               }
           });

    function TreeboxAutocompleteCtrl($scope, $element, PartnerRest, TreeboxPopupOpener, UlaznaKategorijaService, ModalService, $attrs, FetchDataInBatch, $log, RestOptions) {
        var vm = this;

        vm.activate = activate;
        vm.showTreeBox = showTreeBox;
        vm.chooseItem = chooseItem;

        vm.partnerList = [];
        vm.selected = null;
        vm.show = false;

        $log.info('COMPONENT this', this);
        $log.info('COMPONENT $scope', $scope);
        $log.info('COMPONENT $element', $element);
        $log.info('COMPONENT $attrs', $attrs);


        function activate() {
            vm.intData = fetchUlazneKategorije();
            vm.getKategorijeForSelectboxData = getKategorijeForSelectbox();
            vm.show = true;
        }

        this.$onInit = function () {
            this.ngModel.$render = function () {
                vm.selected = vm.ngModel.$viewValue;
            };

            if (!vm.batchData) {
                var fetchInBatch = new FetchDataInBatch;
                fetchInBatch.extendItemsToFetch('UlaznaKategorijaRest').getData().then(function (response) {
                    vm.batchData = response;
                    activate();
                });
            }
            else {
                activate();
            }
        };

        function chooseItem() {
            vm.ngModel.$setViewValue(vm.selected);
        }

        function setKeyByAttrVal(arrOfObjects, attrName) {
            var retval = {};
            angular.forEach(arrOfObjects, function (item) {
                retval[item[attrName]] = item;
            });

            return retval;
        }

        function isRelatedOfCategory(kategorijeArray, categoryObjectId, parentId) {
            var retval = false;

            if (!(kategorijeArray[categoryObjectId] && kategorijeArray[categoryObjectId].roditelj_id)) return false;

            if (kategorijeArray[categoryObjectId].roditelj_id === parentId) {
                retval = true;
            }
            else {
                retval = isRelatedOfCategory(kategorijeArray, kategorijeArray[categoryObjectId].roditelj_id, parentId)
            }

            return retval;
        }

        function fetchUlazneKategorije() {
            var catRetvalArr = [];
            var flux = vm.parentUid;
            var mainParent;

            angular.forEach(vm.batchData.UlaznaKategorijaRest, function (item) {
                if (item.uid === flux) mainParent = item.id;
            });

            var cats = setKeyByAttrVal(vm.batchData.UlaznaKategorijaRest, 'id');

            angular.forEach(vm.batchData.UlaznaKategorijaRest, function (item) {
                if (item.vrsta_dokumenta === vm.documentType) {
                    if (isRelatedOfCategory(cats, item.id, mainParent)) {
                        var bc = breadcrumb(item, cats);
                        if (bc) {
                            item.breadcrumb = bc.trim().substr(2);
                        }
                        catRetvalArr.push(item);
                    }
                }
            });

            //$log.debug("fetchUlazneKategorije", JSON.stringify(catRetvalArr));
            return {list: catRetvalArr, initialCatId: mainParent};
        }

        function getKategorijeForSelectbox() {
            var retval = [];
            var list = fetchUlazneKategorije().list;
            angular.forEach(list, function (item) {
                if (!UlaznaKategorijaService.hasChilds(list, item.id)) {
                    retval.push(item);
                }
            });

            return retval;

        }

        function breadcrumb(item, obj) {
            var bc = [];
            if (item.roditelj_id) {
                if (obj[item.roditelj_id]) {
                    bc.push(obj[item.roditelj_id].naziv);
                    bc.push(breadcrumb(obj[item.roditelj_id], obj));
                }
            }

            return bc.reverse().join(' > ');
        }

        function showTreeBox(stavka) {
            //stavka.ulazna_kategorija = stavka.tip; //todo: kako ovo bolje napraviti?
            //TreeboxPopupOpener.showTreeBox(twData.list, stavka, twData.initialCatId).then(konfigurirajStavku);
            ModalService.showModal({
                templateUrl: 'Modules/Racun/RacunForm/treewidget-modal.html',
                controller: "TreewidgetCtrl as tw",
                inputs: {
                    'ulazneKategorijeList': vm.intData.list,
                    'odabranaUlaznaKategorija': vm.selected,
                    'initialCatId': vm.intData.initialCatId
                }
            }).then(function (modal) {
                modal.element.modal();
                modal.close.then(function (ulazna_kategorija) {
                    $('.modal-backdrop').remove();
                    vm.selected = ulazna_kategorija;
                    chooseItem();
                });
                return modal.close;
            }, function (err) {
                $log.error('TreeboxPopupOpener', err);
                dfd.reject(err);
            });
        }

    }

})();
