(function() {

    angular.module("conto")
        .directive('supTableButtonDelete', SupTableButtonDelete);

    function SupTableButtonDelete($log, $compile) {

        return {
            restrict: 'EA',
            require: '^supTable',
            template: '<button sup-table-options="many|one" class="btn btn-default obrisi-sup-opt"><span class="glyphicon glyphicon-trash"></span> {{\'obrisi\' | translate}}</button>',
            replace: true,
            link: function(scope, element, attr, supTableCtrl) {
                element.bind("click", function() {
                    return supTableCtrl.removeSelectedItems();
                });
            }
        };

    }

})();
