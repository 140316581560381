
(function() {

    angular.module("conto")
        .directive('supInputSelect', SupInputSelect);

    function SupInputSelect($log, $compile, $injector, RestOptions) {

        return {
            restrict: 'AE',
            templateUrl: 'Components/SupFormDirective/sup-select.html',
            require: ['ngModel', 'supInputSelect', '^supForm'],
            controllerAs: 'SupInputSelect',
            bindToController: true,
            scope: {
                list: '=?',
                ngModel: '=',
                modelRest: '@',
                label: '@',
                addNewMethod: '='
            },
            controller: function($scope) {
                var vm = this;

                $scope.SupInputSelect.restOptions = new RestOptions;

                vm.reload = function(e) {
                    //$log.debug("test", e, $scope);

                    var query = $scope.SupInputSelect.restOptions.setQuery(e).get();
                    $scope.SupInputSelect.restInjected.getList(query).then(function(data) {
                        //$log.debug("data asjnc",data.length, data);

                        if (data.length === 0) {
                            $scope.SupInputSelect.list = [{'naziv': e, id: 'new'}];
                            $scope.$apply;
                        }
                        else {
                            $scope.SupInputSelect.list = data;
                        }

                        //$log.debug("data as", $scope.SupInputSelect.list);

                    });
                };

                SupInputSelect.ngModel = {id: SupInputSelect.ngModel}
            },
            link: function(scope,element,attr,ctrls) {

                //$log.warn("MODEL REST", scope)
                scope.SupInputSelect.restInjected = $injector.get(scope.SupInputSelect.modelRest); //TODO: zašto je ovo zahardkodirano?

                scope.SupInputSelect.list = [];

                //TODO: treba napraivit da su prikazani svi rezultati
                // TODO2: treba napraviti filtriranje u bazi (autocomplete)
                /*scope.SupInputSelect.restInjected.getList().then(function(data) {
                    scope.SupInputSelect.list = data;
                });*/

                var ngModelCtrl = ctrls[0];
                var supInputSelect = ctrls[1];
                var supFormCtrl = ctrls[2];

                var nameFromModel = function(modelName) {
                    modelName = modelName.split(".");
                    return modelName[modelName.length-1];
                };
                var name;
                if (!scope.document) {
                    name = supFormCtrl.document.getModelName() + "." + nameFromModel(attr.ngModel);
                }
                else {
                    name = scope.document.getModelName() + "." + nameFromModel(attr.ngModel);
                }

                var input = element.find(".sup-ui-select");

                var validityClass = function(validateVar) {
                    input.removeClass("has-error");
                    if (validateVar &&
                        validateVar.errors &&
                        validateVar.errors[name]) {

                        input.addClass("has-error");
                    }
                };

                ngModelCtrl.$formatters.push(function(modelValue) {
                    return modelValue;
                });

                ngModelCtrl.$render = function() {
                    scope.inputVal = ngModelCtrl.$viewValue;
                };

                ngModelCtrl.$parsers.push(function(viewValue) {
                    return viewValue;
                });

                scope.$watch('SupInputSelect.ngModel', function(newValue) {

                    angular.forEach(scope.SupInputSelect.list, function(value, key){
                        if (value.id === scope.inputVal) {
                            newValue = value;
                        }
                    });

                    ngModelCtrl.$setViewValue(newValue);
                    validityClass();
                });

                /* Watch for errors */
                scope.$watch(function() {
                    if (supFormCtrl.document.rawData.errors) return supFormCtrl.document.rawData.errors;
                },
                function(newValue) {
                    if (newValue && newValue[name]) {
                        validityClass(supFormCtrl.document.rawData);
                    }
                });

            }
        };

    }

})();
