(function () {

    angular.module("conto")
        .factory('RestServiceRemove', RestServiceRemove);

    function RestServiceRemove($http, $q, AppConfig, $log) {

        return function () {
            var service;

            var config;
            /**
             * Query string params etc.
             * @type {*}
             */
            var instanceId;

            var _resolve;
            var _reject;

            service = {
                'activate': activate
            };

            /* class return*/
            return service;

            function activate(id, configObj) {
                instanceId = id;
                config = configObj;
                return $q(deleteResolve)
            }

            function deleteResolve(resolve, reject) {
                _resolve = resolve;
                _reject = reject;

                $http({
                    method: 'DELETE',
                    url: AppConfig.server + '/api/' + config.apiUrl + "/" + instanceId
                })
                    .then(successCallback)
                    .catch(errorCallback);

            }

            /**
             * @param response {*}
             * @returns {*}
             */
            function successCallback(response) {
                if (response.data) {
                    return _resolve(response.data);
                }
                else {
                    _resolve();
                }
            }

            function errorCallback(err) {
                return _reject(err);
            }

        }

    }

})();

