angular.module("conto")

    .run(function ($rootScope,
                   $state,
                   $auth,
                   $log,
                   AclService,
                   SessionDataRest,
                   $location,
                   AppConstants,
                   $stateParams) {

        /**
         *  Postavi aplikativne konstante u rootScope
         *  kako bi bile globalno dostupne unutar svih templateova */
        $rootScope.AppConstants = AppConstants;

        /**
         * Na svaku promjenu rute provjeriti ima li trenutno logirani korisnik permisije za pristup,
         * ako nema permisije prebacujemo ga na "home" rutu
         */
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

            if (!$auth.isAuthenticated()) return;

            // TODO: kad bude vremena ovo preseliti sve u zaseban servis
            SessionDataRest.getList({cache: true}).then(function (sessionData) {

                function aclReslove(state) {
                    $log.debug("AclResolve: " + state);
                    var bundle;
                    try {
                        var tenantData = sessionData.tenant;
                        bundle = sessionData.tenant.naplatni_paket;

                        /* Sentry data */
                        Raven.setUserContext({
                            email: sessionData.user.email,
                            id: sessionData.user.id
                        });
                    }
                    catch (err) {
                        bundle = 'free';
                        $log.warn("Tenant ne postoji (vjerojatno nije prijavljen). Postavljam 'free' bundle");
                    }

                    if (!bundle) bundle = 'free';

                    AclService.getDefinition().then(function (definition) {
                        if (!AclService.isAllowed(definition, state, bundle)) {
                            event.preventDefault();
                            $log.error("No perms for this state! " + state, "Bundle " + bundle);
                            $state.go("home");
                        }
                    });

                }

                aclReslove(toState.name);

            }, function (err) {
                $log.warn("SessionDataRest:", err.data, err);
            });
        });

        /**
         * Bindanje svih ui-router errora i prosljeđivanje istih u konzolu
         */
        $rootScope.$on("$stateChangeError", function(event, toState, toParams, fromState, fromParams, error){
            $log.warn('StateChangeError...', error);
            console.log.bind(console)
        });

        /**
         * Postavi listu svih state-ova u rootScope
         * kako bi bili globalno dostupni u svim templateovima
         * @type {$state|*}
         */
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;


    });
