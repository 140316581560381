(function () {

    angular.module("conto")
        .factory('RestServiceGetOne', RestServiceGetOne);

    function RestServiceGetOne($http, $q, AppConfig, $log) {

        return function () {
            var service;

            var config;
            /**
             * Query string params etc.
             * @type {*}
             */
            var options = {};
            var instanceId;

            var _resolve;
            var _reject;

            service = {
                'activate': activate
            };

            /* class return*/
            return service;

            function activate(id, optionsList, configObj) {
                instanceId = id;
                options = optionsList;
                config = configObj;
                return $q(getOneResolve)
            }

            function getOneResolve(resolve, reject) {
                _resolve = resolve;
                _reject = reject;

                $http({
                    method: 'GET',
                    url: AppConfig.server + '/api/' + config.apiUrl + '/' + instanceId
                })
                    .then(successCallback)
                    .catch(errorCallback);

            }

            /**
             * @param response {*}
             * @returns {*}
             */
            function successCallback(response) {
                var item = serverData = response.data;
                if (config.model) {
                    var newObj = new config.model;
                    try {

                        if (config.prepareForClient && typeof config.prepareForClient === "function") {
                            item = config.prepareForClient(item);
                        }

                        newObj.setData(item);

                        /* throws exception */
                        newObj.validate();
                    }
                    catch(err) {
                        errorCallback({
                            'item': item,
                            'error': err
                        });
                    }

                    _resolve(newObj);
                }
                else {
                    _resolve(serverData);
                }
            }

            function errorCallback(err) {
                return _reject(err);
            }

        }

    }

})();

