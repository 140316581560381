(function() {

    angular.module("conto")
        .factory('DrzavaRest', DrzavaRest);

    function DrzavaRest(Drzava, RestService) {

        var config = {
            'model': Drzava,
            'apiUrl': 'drzava'
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

    }

})();
