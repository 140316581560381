/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('Tenant', Tenant);

    function Tenant($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            naziv: 'required',
            oib: 'required|oib',
            tenant_id: 'required',
            pdv_obveznik: 'required',
            ulica: 'text|required',
            kucni_broj: 'numeric',
            kucni_broj_dodatak: 'text',
            postanski_broj: 'text',
            iban: 'iban|required',
            ira_dani_odgode: 'numeric',
            naselje: 'object|required',
            drzava: 'object',
            memorandumski_podaci: 'text',
            naplatni_paket: 'required',
            email: 'email|required',
            telefon: 'numeric|required',
            fax: 'numeric',
            datum_osnivanja: 'date',
            rno_broj: 'numeric',
            maticni_broj: 'numeric',
            sifra_djelatnosti: 'text',
            zakonski_zastupnik: 'text',
            osoba_za_kontakt: 'text',
            coupon_code: 'text',
            commercial_package: 'object|required', /* Ovo se koristi kod promjene paketa, ne postoji takvo polje u bazi */
            billing_interval: 'object'

        };

        var service = ClassUtils.defineModel('Tenant', attributes)();
        service.isPdvObveznik = isPdvObveznik;
        service.isTenantReady = isTenantReady;
        service.isCouponValid = isCouponValid;

        function isPdvObveznik() {
            //$log.debug("isPdvObveznik", service.pdv_obveznik);
            return !!(service.pdv_obveznik == 1 || service.pdv_obveznik == true || service.pdv_obveznik == "true");
        }

        function isCouponValid() {

            return service.coupon_code && service.coupon_code.toLowerCase() === 'NEOCONTOSTART'.toLowerCase() && service.billing_interval && service.billing_interval.id === 'yearly';
        }

        function isTenantReady() {
            var tenantReadyBool = service.naziv && service.oib && service.telefon && service.iban && service.naselje && service.ulica && service.email ? true : false;
            $log.info('isTenantReady', tenantReadyBool);
            return tenantReadyBool;
        }

        return function() {
            return service;
        }

    }

})();
