(function() {

    angular.module("conto")
        .controller('IzvodListCtrl', IzvodListCtrl);

    function IzvodListCtrl(IzvodRest, $scope, $log, $state, fetchInBatch) {
        var vm = this;

        vm.modelRest = IzvodRest;
        vm.response = [];
        vm.selectedItems = [];

    }
})();
