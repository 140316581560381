(function() {

    angular.module("conto")
        .directive('supTableButtonEdit', SupTableButtonEdit);

    function SupTableButtonEdit($log, $compile) {

        return {
            restrict: 'EA',
            require: '^supTable',
            template: '<button sup-table-options="one" class="btn btn-default uredi-sup-opt"><span class="glyphicon glyphicon-pencil"></span> {{\'uredi\' | translate}}</button>',
            replace: true
        };

    }

})();
