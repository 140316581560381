(function () {

    angular.module("conto")
        .factory('AclService', AclService);

    function AclService($log, $q, SessionDataRest) {


        var definition = null;


        /* title, bundle, type, uid, note */

        var service;
        service = {
            'getDefinition': getDefinition,
            'getAllowedItems': getAllowedItems,
            'isAllowed': isAllowed
        };

        /* class return*/
        return service;

        /*
         * @return
         */
        function getDefinition() {

            return $q(function (resolve, reject) {

                if (definition === null) {


                    SessionDataRest.getList({cache: true}).then(function (data) {
                        //$log.debug("fromOnline", data);

                        definition = [];
                        angular.forEach(data.permission, function (permItem, key) {
                            var obj = {
                                title: permItem.naziv,
                                bundle: permItem.naplatni_paket,
                                state: permItem.naziv,
                                stateGroup: ''
                            };

                            definition.push(obj);
                        });

                        resolve(definition);

                    });

                }
                else {
                    resolve(definition);
                }


            });

        }

        /*
         * @return
         */
        function getAllowedItems(definition, bundle) {
            //$log.debug("gett definition", definition);
            var allowedNavigation = {};
            angular.forEach(definition, function (item, key) {
                var expl = item.bundle.split("|");
                if ($.inArray(bundle, expl) > -1) {
                    allowedNavigation[item.title] = item;
                }
            });

            return allowedNavigation;
        }

        function isAllowed(definition, state, bundle) {
            var retval = false;

            if (state === 'auth.logout') return true;

            angular.forEach(definition, function (item, key) {

                var expl = item.bundle.split("|");

                if (item.state === state && $.inArray(bundle, expl) > -1) {
                    retval = true;
                }
            });

            return retval;
        }


    }

})();
