/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('UlaznaKategorija', UlaznaKategorija);

    function UlaznaKategorija(ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            naziv: 'required',
            roditelj_id: 'uuid',
            uid: 'alpha_dash',
            vrsta_dokumenta: 'text',
            opis: 'text',
            vrijedi_od: 'date',
            vrijedi_do: 'date',
            partner_role: 'string'
        };

        return ClassUtils.defineModel('UlaznaKategorija', attributes);

    }

})();
