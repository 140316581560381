(function() {

    angular.module("conto")
        .directive('supInputNumber', supInputNumber);

    function supInputNumber($log, $compile) {

        return {
            restrict: 'A',
            require: ['supInput'],
            link: function(scope, element, attr, ctrls) {
                var input = element.find('input');
                input.attr('type', 'number');
                input.css("text-align", "right");
            }
        };

    }

})();
