(function () {

    angular.module("conto")
           .component('pocetnoStanjeRacun', {
               templateUrl: 'Modules/PocetnoStanje/templates/pocetno-stanje-racun.html',
               controller: PocetnoStanjeRacunComponent,
               require: {
                   pocetnoStanjeCtrl: '^pocetnoStanje'
               },
               bindings: {
                   type: '@'
               }
           });

    function PocetnoStanjeRacunComponent($scope, $element, PartnerRest,
                                         TreeboxPopupOpener, UlaznaKategorijaService,
                                         PocetnoStanjeRacun,
                                         ModalService, $attrs, FetchDataInBatch,
                                         $log, RestOptions) {

        var vm = this;
        vm.newRow = newRow;
        vm.removeRow = removeRow;

        this.$onInit = function () {
            /*if (vm.pocetnoStanjeCtrl.document[vm.type] && !vm.pocetnoStanjeCtrl.document[vm.type].length) {
                newRow();
            }*/
        };

        function newRow() {
            $log.warn("NEWROW", vm.pocetnoStanjeCtrl.document);
            vm.pocetnoStanjeCtrl.document[vm.type].push(new PocetnoStanjeRacun);
        }

        function removeRow($index) {
            if ($index !== undefined || $index !== null) {
                vm.pocetnoStanjeCtrl.document[vm.type].splice($index, 1);
            }
        }


    }

})();
