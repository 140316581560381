(function () {

    angular.module("conto")
        .factory('UserRest', UserRest);

    function UserRest(User, RestService, $log) {

        var config = {
            'model': User,
            'apiUrl': 'user',
            'prepareForDb': prepareForDb
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

        function prepareForDb(data) {
            var retval = {
                'name': data.name,
                'oib': data.oib,
                'print_name': data.print_name
            };

            if (data.password) retval.password = data.password;

            return retval;
        }

    }

})();
