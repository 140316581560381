(function () {

    angular.module("conto")
           .factory('PocetnoStanjeRest', PocetnoStanjeRest);

    function PocetnoStanjeRest($log, PocetnoStanje, RestService, PocetnoStanjeRacun) {

        var config = {
            'model': PocetnoStanje,
            'apiUrl': 'temeljnica-ps',
            'prepareForDb': prepareForDb,
            'prepareForClient': prepareForClient
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

        function prepareForClient(item) {
            var tmp = {};
            tmp.godina = item.godina;
            tmp = item.json_wizard;
            tmp.ura = prepareRacunForClient(tmp.ura);
            tmp.ira = prepareRacunForClient(tmp.ira);

            return tmp;
        }

        function prepareRacunForClient(stavke) {
            var preparedStavke = [];
            angular.forEach(stavke, function (stavka, i) {

                var stavkaPrepared = new PocetnoStanjeRacun;
                stavkaPrepared.setData(stavka);

                preparedStavke.push(stavkaPrepared);
            });

            return preparedStavke;
        }

        /* Custom prepare for db functions */
        function prepareRacunForDb(stavke) {
            var preparedStavke = [];
            angular.forEach(stavke, function (stavka, i) {
                stavka = stavka.getDataForRest();
                var stavkaPrepared = {
                    iznos: stavka.iznos*1,
                    partner: stavka.partner.getData(),
                    partner_id: stavka.partner.id,
                    externa_oznaka: stavka.externa_oznaka,
                    interna_oznaka: stavka.interna_oznaka,
                    datum_dokumenta: stavka.datum_dokumenta,
                    datum_dospijeca: stavka.datum_dospijeca,
                    napomena: stavka.napomena
                };

                preparedStavke.push(stavkaPrepared);
            });

            return preparedStavke;
        }

        function prepareForDb(data) {
            return {
                godina: data.godina,
                stanje_izvoda: data.stanje_izvoda*1,
                stanje_blagajne: data.stanje_blagajne*1,
                prihodi_iz_razdoblja: data.prihodi_iz_razdoblja*1,
                ura: prepareRacunForDb(data.ura),
                ira: prepareRacunForDb(data.ira)
            };
        }
    }

})();
