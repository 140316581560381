(function () {

    angular.module("conto")
        .controller('PartnerListCtrl', PartnerListCtrl);

    function PartnerListCtrl($scope, PartnerRest, ngDialog, $log, RestOptions, $timeout, $q, SupPrintService, AppConstants) {
        var vm = this;

        vm.modelRest = PartnerRest;
        vm.response = [];

        vm.restOptions = new RestOptions;
        vm.filterBy = filterBy;

        /* Initialize reload list after init */
        setClearedPartnerTypeFilters();

        activate(vm.restOptions.get(), true);

        /* Custom reload list*/
        function activate(options, special) {
            vm.tblLoader = true;

            /* Resetiraj filter aktivnosti kod pretrage */
            if (!special) {
                vm.restOptions.setCustomOption('aktivan', '');
            }
            //$log.debug(vm.restOptions.get());
            vm.modelRest.getList(vm.restOptions.get()).then(function (response) {
                vm.response = response;
            });
        }

        function setClearedPartnerTypeFilters() {
            vm.restOptions.setCustomOption('clan', null);
            vm.restOptions.setCustomOption('suradnik', null);
            vm.restOptions.setCustomOption('volonter', null);
            vm.filterSelected = 'prikazi_sve';
        }

        function filterBy(status) {
            setClearedPartnerTypeFilters();

            vm.filterSelected = status;
            if (status !== 'prikazi_sve') {
                vm.restOptions.setCustomOption(status, true);
            }

            vm.restOptions.setPage(1);

            //$log.debug("Filtriram po: " + status, vm.restOptions.get());

            activate(vm.restOptions.get(), true);
        }

    }
})();
