(function () {

    angular.module("conto")
           .controller('IzvjestajiCtrl', IzvjestajiCtrl);

    function IzvjestajiCtrl($log, $auth, AppConfig, $http) {
        var vm = this;

        vm.apiServer = AppConfig.server;
        vm.token = $auth.getToken();

        vm.izvjestajiList = [
            {'naziv': 'Knjiga primitaka i izdataka', url: '/api/knjiga/izvod'},
            {'naziv': 'Knjiga izlaznih računa', url: '/api/knjiga/ira'},
            {'naziv': 'Knjiga ulaznih računa', url: '/api/knjiga/ura'},
            {'naziv': 'Popis dugotrajne nefinancijske imovine', url: '/api/knjiga/osasi'},
            {'naziv': 'Dnevnik blagajne', url: '/api/knjiga/blagajna'}
            //{'naziv': 'Excel datoteka financijskih izvještaja neprofitnih organizacija', url: '/api/reports/bilanca/2015', withoutYear: true} TODO: za kasnije
        ];

        vm.godineList = [2015, 2016];

    }
})();
