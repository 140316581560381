(function () {

    angular.module("conto")
        .factory('Izvod', Izvod);

    function Izvod($log, ValidationService, ClassUtils, ValidationException) {

        return function () {

            var attributes = {
                id: 'required|uuid',
                // valuta: 'required|object', todo: razmisliti kako napraviti kasnije
                bankovni_racun: 'required|object',
                datum: 'required|date',
                externa_oznaka: 'required|numeric',
                stavke: 'required|object',
                storno: 'alpha',
                ponisteno: 'alpha'
            };

            var service = ClassUtils.defineModel('Izvod', attributes)();

            var serviceValidate = service.validate;
            service.validate = validate;
            service.dodajStavku = dodajStavku;
            service.obrisiStavku = obrisiStavku;
            service.calculateTotals = calculateTotals;

            return service;

            function validate() {
                var errArray = [];

                /* Pozovi glavnu validaciju Ulaznog računa */
                ValidationService.tryCatch(serviceValidate, errArray);

                /* Validiraj stavku po stavku */
                angular.forEach(service.stavke, function (stavka, i) {
                    ValidationService.tryCatch(stavka.validate, errArray);
                });

                if (errArray.length) {
                    throw new ValidationException(errArray);
                }

            }

            function dodajStavku(stavka) {
                if (stavka) {
                    if (!service.stavke) service.stavke = [];
                    stavka.rbr = service.stavke.length + 1;
                    service.stavke.push(stavka);
                }
            }

            function obrisiStavku($index) {
                if ($index !== undefined && $index !== null) {
                    service.stavke.splice($index, 1);
                }
            }

            function calculateTotals() {
                var rekapitulacija = {priljev: 0, odljev: 0};
                angular.forEach(service.stavke, function(stavka) {
                    if (stavka.priljev) {
                        rekapitulacija.priljev += parseFloat(stavka.priljev);
                    }
                    if (stavka.odljev) {
                        rekapitulacija.odljev += parseFloat(stavka.odljev);
                    }
                });

                rekapitulacija.priljev = round2(rekapitulacija.priljev);
                rekapitulacija.odljev = round2(rekapitulacija.odljev);

                return rekapitulacija;
            }



        };


    }

})();
