(function () {

    angular.module("conto")
        .factory('OsaSiValidationMessages', OsaSiValidationMessages);

    function OsaSiValidationMessages($log, ValidationService, SweetAlert, $translate, AppConstants, $q) {

        var service;
        service = {
            'showOSANotValidMessage': showOSANotValidMessage,
            'showSINotValidMessage': showSINotValidMessage,
            'isStavkaOSAorSI': isStavkaOSAorSI
        };

        return service;

        function isStavkaOSAorSI(stavka) {
            return stavka &&
                stavka.ulazna_kategorija &&
                (typeof stavka.ulazna_kategorija.uid === 'string' || typeof stavka.ulazna_kategorija.uid === 'number') &&
                (stavka.ulazna_kategorija.uid.toLowerCase() === AppConstants.OSNOVNO_SREDSTVO.toLowerCase() ||
                stavka.ulazna_kategorija.uid.toLowerCase() === AppConstants.SITAN_INVENTAR.toLowerCase())
        }

        function showOSANotValidMessage(stavka) {
            var dfd = $q.defer();

            SweetAlert.swal({
                    title: $translate.instant('upozorenje'),
                    text: $translate.instant('Racun.osaNotValidMessage', {'siMax': AppConstants.OSNOVNO_SREDSTVO_MIN_IZNOS}),
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonText: $translate.instant('Racun.odustaniProvjeraPodataka'),
                    confirmButtonText: $translate.instant('Racun.odaberiKategorijuOsa'),
                    closeOnConfirm: true
                },
                function (confirm) {
                    fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                    if (confirm) dfd.resolve(stavka); else dfd.reject();
                });

            return dfd.promise;
        }

        function showSINotValidMessage(stavka, batchData) {

            SweetAlert.swal({
                    title: $translate.instant('upozorenje'),
                    text: $translate.instant('Racun.siNotValidMessage', {'siMax': AppConstants.OSNOVNO_SREDSTVO_MIN_IZNOS}),
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonText: $translate.instant('Racun.odustaniProvjeraPodataka'),
                    confirmButtonText: $translate.instant('Racun.zavediKaoSitanInventar'),
                    closeOnConfirm: true
                },
                function (confirm) {
                    fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                    if (confirm) {
                        var sitanInventarCat = {};
                        var ulaznaKategorijaList = batchData.UlaznaKategorijaRest;
                        /**
                         *  Pronađi stavku Sitan invetar iz ulaznih kategorija
                         **/
                        angular.forEach(ulaznaKategorijaList, function (item) {
                            if (item.uid === AppConstants.SITAN_INVENTAR) {
                                sitanInventarCat = item;
                            }
                        });
                        stavka.ulazna_kategorija = sitanInventarCat;
                    }
                });
        }

    }

})();
