(function() {

    angular.module("conto")
        .controller('LogoutCtrl', LogoutCtrl);

		function LogoutCtrl($auth, $log, $state, $cookies, $window) {

		    $auth.logout().then(function() {
		        $log.debug("Korisnik uspješno odjavljen!");
                $cookies.remove('AppColor');
                //$window.location.reload();
		        $state.go('auth.login', {reload: true});
		    });

		}

})();
