(function () {

    angular.module("conto")
        .factory('IzvodRest', IzvodRest);

    function IzvodRest($log, Izvod, RestService, IzvodStavka, SupNacinPlacanja) {

        var config = {
            'model': Izvod,
            'apiUrl': 'izvod',
            'prepareForDb': prepareForDb,
            'prepareForClient': prepareForClient
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

        function prepareForClient(item) {
            angular.forEach(item.stavke, function (stavkaData, i) {
                var novaStavka = new IzvodStavka;
                stavkaData.tip = stavkaData.kategorija;
                stavkaData.priljev = stavkaData.priljev*1;
                stavkaData.odljev = stavkaData.odljev*1;
                novaStavka.setData(stavkaData);
                item.stavke[i] = novaStavka;
            });

            // transfer to int
            item.externa_oznaka = item.externa_oznaka*1;

            return item;
        }

        /* Custom prepare for db functions */
        function prepareStavkeForDb(stavke) {
            var preparedStavke = [];
            angular.forEach(stavke, function (stavka, i) {
                var stavkaPrepared = {

                    "datum": stavka.datum,
                    "tip_id": stavka.tip.id,
                    "partner_id": stavka.partner.id,
                    "rbr": i,
                    "priljev": stavka.priljev,
                    "odljev": stavka.odljev,
                    "napomena": stavka.napomena
                };

                preparedStavke.push(stavkaPrepared);
            });

            return preparedStavke;
        }

        function prepareForDb(data) {
            return {
                //"valuta_id": 'fd0a11c0-df1f-4f75-b636-b320237e11c4', //TODO: maknuti ovo zahardkodirano
                "bankovni_racun_id": data.bankovni_racun.id,
                "datum": data.datum,
                "externa_oznaka": data.externa_oznaka,
                "stavke": prepareStavkeForDb(data.stavke)
            };
        }
    }

})();
