(function () {

    angular.module("conto")
        .factory('Temeljnica', Temeljnica);

    function Temeljnica($log, ValidationService, ClassUtils, ValidationException) {

        return function () {

            var attributes = {
                id: 'required|uuid',
                datum: 'required|date',
                broj: 'numeric',
                napomena: 'text',
                stavke: 'required|object'
            };

            var service = ClassUtils.defineModel('Temeljnica', attributes)();

            var serviceValidate = service.validate;
            service.validate = validate;
            service.dodajStavku = dodajStavku;
            service.obrisiStavku = obrisiStavku;
            service.calculateTotals = calculateTotals;

            return service;

            function validate() {
                var errArray = [];

                /* Pozovi glavnu validaciju Ulaznog računa */
                ValidationService.tryCatch(serviceValidate, errArray);

                /* Validiraj stavku po stavku */
                angular.forEach(service.stavke, function (stavka, i) {
                    ValidationService.tryCatch(stavka.validate, errArray);
                });

                if (errArray.length) {
                    throw new ValidationException(errArray);
                }

            }

            function dodajStavku(stavka) {
                if (stavka) {
                    if (!service.stavke) service.stavke = [];
                    stavka.rbr = service.stavke.length + 1;
                    service.stavke.push(stavka);
                }
            }

            function obrisiStavku($index) {
                if ($index) {
                    service.stavke.splice($index, 1);
                }
            }

            function calculateTotals() {
                var rekapitulacija = {duguje: 0, potrazuje: 0};
                angular.forEach(service.stavke, function(stavka) {
                    if (stavka.duguje) {
                        rekapitulacija.duguje += parseFloat(stavka.duguje);
                    }
                    if (stavka.potrazuje) {
                        rekapitulacija.potrazuje += parseFloat(stavka.potrazuje);
                    }
                });

                rekapitulacija.duguje = round2(rekapitulacija.duguje);
                rekapitulacija.potrazuje = round2(rekapitulacija.potrazuje);

                return rekapitulacija;
            }



        };


    }

})();
