(function() {

    angular.module("conto")
        .directive('supTableButtonAdd', SupTableButtonAdd);

    function SupTableButtonAdd($log, $compile) {

        return {
            restrict: 'EA',
            require: '^supTable',
            template: '<button class="btn btn-primary sup-table-btn-add"><span class="glyphicon glyphicon-plus"></span> {{\'dodaj\' | translate}}</button>',
            replace: true
        };

    }

})();
