/**
 * Created by danijelmaric on 21/08/15.
 */
(function () {

    angular.module("conto")
           .factory('Partner', Partner);

    function Partner($log, ValidationService, ClassUtils, ValidationException, AppConstants, $translate) {

        return function () {

            var attributes = {
                id: 'required|uuid',
                naziv: 'required',
                status: 'required|in:P,F,S',
                drzava: 'required|object',
                naselje: 'object',
                oib: 'oib',
                ulica_i_broj: 'text',
                postanski_broj: 'numeric',
                mjesto: 'text',
                telefon: 'numeric',
                mobitel: 'numeric',
                email: 'email',
                iban: 'iban',
                ura_dani_odgode: 'numeric|min:0',
                ira_dani_odgode: 'numeric|min:0',
                suradnik: 'bool',
                volonter: 'bool',
                clan: 'bool',
                kategorija: 'object',
                aktivan_od: 'date',
                aktivan_do: 'date',
                datum_rodjenja: 'date',
                napomena: 'text'
            };

            var service = ClassUtils.defineModel('Partner', attributes)();
            service.customFieldsFormat = customFieldsFormat;
            service.getTypesFormatted = getTypesFormatted;
            service.isPartnerOfType = isPartnerOfType;

            service.extendFormatters('customFieldsFormat', customFieldsFormat);

            function getTypesList() {
                var retval = ['kupac_dobavljac'];
                if (service.volonter) retval.push('volonter');
                if (service.clan) retval.push('clan');
                if (service.suradnik) retval.push('suradnik');

                return retval;
            }

            function isPartnerOfType(type) {
                var types = getTypesList();
                return types.indexOf(type) !== -1;
            }

            function getTypesFormatted() {
                var retval = [];

                angular.forEach(getTypesList(), function (item) {
                    retval.push($translate.instant('Partner.' + item));
                });

                return retval.join('<br/>');
            }

            function isDefaultCountry() {
                return service.drzava && service.drzava.brojcana_sifra * 1 === AppConstants.HR_DRZAVNA_SIFRA;
            }

            function customFieldsFormat() {
                if (!(service)) return;

                var attrTmp = attributes;

                if (service.status === AppConstants.PARTNER_PRAVNA_OSOBA && isDefaultCountry()) {
                    attrTmp['oib'] = 'oib|required';
                }
                else if (service.status === AppConstants.PARTNER_FIZICKA_OSOBA && isDefaultCountry()) {
                    if (service.clan) {
                        attrTmp['oib'] = 'oib|required';
                    }
                    else {
                        attrTmp['oib'] = 'oib';
                    }
                }
                else {
                    if (service.clan) {
                        attrTmp['oib'] = 'required|text';
                    }
                    else {
                        attrTmp['oib'] = 'text';
                    }

                    //$log.info(attrTmp['oib']);
                }

                if (service.suradnik) {
                    attrTmp['iban'] = 'iban|required';
                }
                else if (service.clan) {
                    attrTmp['kategorija'] = 'object|required';
                    attrTmp['aktivan_od'] = 'date|required';
                    attrTmp['datum_rodjenja'] = 'date|required';
                }
                else {
                    attrTmp['iban'] = 'iban';
                }


                //$log.debug('ARRTMP', attrTmp);

                service.setAttributes(attrTmp);

            }

            return service;

        };

    }

})();
