(function() {

    angular.module("conto")
        .factory('NaseljeRest', NaseljeRest);

    function NaseljeRest(Naselje, RestService) {

        var config = {
            'model': Naselje,
            'apiUrl': 'naselje'
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

    }

})();
