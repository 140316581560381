(function() {

    angular.module("conto")
        .factory('JmjRest', JmjRest);

    function JmjRest(Jmj, RestService) {

        var config = {
            'model': Jmj,
            'apiUrl': 'jedinica-mjere'
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

    }

})();
