/**
 * Created by danijelmaric on 21/08/15.
 */
(function () {

    angular.module("conto")
        .factory('PasswordReset', PasswordReset);

    function PasswordReset($log, ValidationService, ClassUtils) {

        var attributes = {
            password1: 'required|min:6',
            password2: 'required|min:6'
        };

        var service = ClassUtils.defineModel('PasswordReset', attributes)();
        var serviceValidate = service.validate;
        service.validate = validate;

        return function () {
            return service;
        };

        function validate() {
            if (service.password1 !== service.password2) {
                throw new Error("Password1 i Password2 must match each other!");
            }
            return serviceValidate();
        }

    }

})();
