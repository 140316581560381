/**
 * Created by danijelmaric on 15/02/16.
 */
(function() {

    angular.module("conto")
           .factory('PocetnoStanjeIzvodBlagajna', PocetnoStanjeIzvodBlagajna);

    function PocetnoStanjeIzvodBlagajna($log, ValidationService, ClassUtils) {

        var attributes = {
            stanje_izvoda: 'required|numeric',
            stanje_blagajne: 'required|numeric',
            prihod_iz_razdoblja: 'required|numeric'
        };

        return ClassUtils.defineModel('PocetnoStanjeIzvodBlagajna', attributes);

    }

})();
