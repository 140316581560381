(function () {

    angular.module("conto")
           .directive('supNacinPlacanja', NacinPlacanjaDirective);

    function NacinPlacanjaDirective($log, $compile, $translate, SupNacinPlacanja) {

        return {
            restrict: 'EA',
            require: ['^supForm', 'ngModel'],
            scope: {
                data: '=',
                fetchInBatch: '=',
                defaultData: '=',
                documentSlug: '='
            },
            templateUrl: 'Components/SupNacinPlacanja/sup-nacin-placanja.html',
            controllerAs: 'SupNacinPlacanjaCtrl',
            bindToController: true,
            controller: function ($scope, $log, $translate, SupNacinPlacanja, ModalService, AppConstants, PartnerRest, RestOptions) {
                var vm = this;
                var timeoutId = 0;
                vm.suradnikExists = false;

                vm.odabranoPlacanje = new SupNacinPlacanja;
                vm.AppConstants = AppConstants;
                vm.restOptions = new RestOptions;

                vm.addNewPartner = addNewPartner;
                vm.reloadSuradnici = reloadSuradnici;

                vm.onChange = function () {
                    if (vm.documentSlug) {
                        vm.odabranoPlacanje.customFeldsFormat(vm.documentSlug);
                    }
                };



                function activate() {
                    checkIfSuradnikExistsInDb();
                }

                this.$onInit = function () {
                    activate();
                };

                /**
                 *  Postavi defaultni broj računa ako postoji kod partnera
                 **/
                $scope.$watch('SupNacinPlacanjaCtrl.defaultData', function (newVal) {
                    //$log.warn('SupNacinPlacanjaCtrl.defaultData', newVal, $scope, $scope.SupNacinPlacanjaCtrl.supForm.disabled);
                    if ($scope &&
                        $scope.SupNacinPlacanjaCtrl &&
                        $scope.SupNacinPlacanjaCtrl.supForm && !$scope.SupNacinPlacanjaCtrl.supForm.disabled) {
                        $log.info("here", vm.defaultData);
                        vm.odabranoPlacanje.broj_racuna = vm.defaultData.iban;
                    }
                });

                /**
                 * Ukoliko postoji samo jedna metoda plaćanja odaberi ju automatski
                 */
                $scope.$watch('SupNacinPlacanjaCtrl.data', function (newVal) {
                    if (vm.data && vm.data.length === 1) {
                        vm.odabranoPlacanje.nacin_placanja = vm.data[0];
                        if (vm.documentSlug) {
                            vm.odabranoPlacanje.customFeldsFormat(vm.documentSlug);
                        }
                    }
                });

                function checkIfSuradnikExistsInDb() {
                    setTimeout(function () {
                        var query = vm.restOptions.setLimit(1).setCustomOption('suradnik', 'true').get();
                        PartnerRest.getList(query).then(function (data) {
                            if (data && data.length > 0) {
                                $log.info('Suradnik exists', data);
                                vm.suradnikExists = true;
                            }
                        });
                    }, 100);
                }

                function reloadSuradnici(e) {
                    var query = vm.restOptions.setLimit(100).setCustomOption('suradnik', 'true').get();
                    PartnerRest.getList(query).then(function (data) {
                        vm.suradniciList = data;
                    });
                }

                function addNewPartner() {
                    ModalService.showModal({
                        templateUrl: 'Modules/Partner/PartnerForm/partner-modal.html',
                        controller: "PartnerFormCtrl as PartnerFormCtrl",
                        inputs: {
                            'fetchInBatch': vm.fetchInBatch,
                            'partnerType': function () {
                                return null;
                            },
                            'supModalOptions': {
                                autoFlag: AppConstants.PARTNER_TYPE.SURADNIK
                            }
                        }
                    }).then(function (modal) {
                        modal.element.modal();
                        modal.close.then(function (partner) {
                            $('.modal-backdrop').remove();
                            if (partner) {
                                vm.odabranoPlacanje.dodatni_podaci = partner.getData();
                                vm.suradnikExists = true;
                            }
                        });
                    });
                }

            },
            link: function (scope, element, attr, ctrls) {
                //$log.debug("supFormInput", scope, element, attr, ctrls);
                var supFormCtrl = ctrls[0];
                var ngModelCtrl = ctrls[1];

                scope.SupNacinPlacanjaCtrl.supForm = supFormCtrl;


                ngModelCtrl.$formatters.push(function (modelValue) {
                    return modelValue;
                });

                ngModelCtrl.$render = function () {
                    if (ngModelCtrl.$viewValue) {
                        scope.SupNacinPlacanjaCtrl.odabranoPlacanje = ngModelCtrl.$viewValue;
                    }
                };

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return viewValue;
                });

                scope.$watch(function () {
                    if (scope.SupNacinPlacanjaCtrl.odabranoPlacanje) return scope.SupNacinPlacanjaCtrl.odabranoPlacanje;
                }, function (newVal) {
                    if (newVal) {
                        ngModelCtrl.$setViewValue(newVal);
                    }
                });
            }
        };

    }

})();
