(function() {

    angular.module("conto")
        .config(ExceptionHandlerConfig);

        function ExceptionHandlerConfig($provide) {

        	$provide.decorator('$exceptionHandler', ['$log', '$delegate', '$injector',
            function($log, $delegate, $injector) {
                return function(exception, cause) {
                    $log.debug('TODO: Conto custom exception.');
                    Raven.captureException(exception);
                    $delegate(exception, cause);

                    /*var SweetAlert = $injector.get('SweetAlert');
                    SweetAlert.swal("Ups...", "Kritična greška!", "error");*/
                };
            }
        ]);
		}

})();
