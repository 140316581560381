/**
 * Created by danijelmaric on 21/10/15.
 */
(function() {

    angular.module("conto")
        .factory('Permisije', Permisije);

    function Permisije($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            naziv: 'required',
            naplatni_paket: 'required',
            vrsta: 'required',
            napomena: 'alpha_num'
        };

        return ClassUtils.defineModel('Permisije', attributes);

    }

})();
