(function () {

    angular.module("conto")
        .controller('RacunFormStavkeCtrl', RacunFormStavkeCtrl);

    /** @namespace $scope.RacunFormCtrl */
    function RacunFormStavkeCtrl($scope,
                                 $log,
                                 SessionDataRest,
                                 $q,
                                 FetchDataInBatch,
                                 RacunService,
                                 PdvRest,
                                 UlaznaKategorijaRest,
                                 JmjRest,
                                 $rootScope,
                                 ModalService,
                                 SweetAlert,
                                 $http,
                                 ValidationService,
                                 AppConfig,
                                 AppConstants,
                                 $translate,
                                 TreeboxPopupOpener,
                                 OsaSiValidationMessages,
                                 UlaznaKategorijaService) {

        var vm = this;
        var batchData;

        vm.editItem = {};
        vm.editItemIndex = null;
        vm.jsonStavke = [];
        vm.tenantData = {};

        vm.calculateTotals = RacunService.calculateStavkaTotal;
        vm.isStavkaOSAorSI = OsaSiValidationMessages.isStavkaOSAorSI;

        vm.newRow = newRow;
        vm.showTreeBox = showTreeBox;
        vm.deleteItem = deleteItem;
        vm.konfigurirajStavku = konfigurirajStavku;
        vm.promjeneNaStavci = promjeneNaStavci;
        vm.detaljiNeispravneStavke = detaljiNeispravneStavke;

        activate();

        function activate() {
            batchData = $scope.RacunFormCtrl.fetchInBatch;
            vm.tenantData = batchData.SessionDataRest.tenant;
            vm.pdv = batchData.PdvRest;
            vm.jmj = batchData.JmjRest;
            vm.ulaznaKategorijaList = batchData.UlaznaKategorijaRest;
            vm.ulaznaKategorijaDostupne = UlaznaKategorijaService.getDostupneUlazneKategorije(batchData.UlaznaKategorijaRest);

            if (!$scope.RacunFormCtrl.disabled && !$scope.RacunFormCtrl.updateId) {
                newRow();
            }
        }

        function pametnaProvjeraOsaSi(stavka) {
            if (stavka.validateOsaSiValue() === 'MUST_BE_OSA') {
                OsaSiValidationMessages.showOSANotValidMessage(stavka).then(showTreeBox);
            }
            else if (stavka.validateOsaSiValue() === 'MUST_BE_SI') {
                OsaSiValidationMessages.showSINotValidMessage(stavka, batchData);
            }
        }

        function detaljiNeispravneStavke(stavka) {
            pametnaProvjeraOsaSi(stavka, batchData);
        }

        function fetchUlazneKategorijeZaRacun() {
            var r = [];

            var mainParent;
            angular.forEach(batchData.UlaznaKategorijaRest, function(item) {
                //$log.debug(item.uid);
                if (item.vrsta_dokumenta === AppConstants.URA_SHORT_SLUG) {
                    r.push(item);
                }
            });
            return r;
        }

        function showTreeBox(stavka) {
            TreeboxPopupOpener.showTreeBox(fetchUlazneKategorijeZaRacun(), stavka).then(function(retval) {
                stavka.ulazna_kategorija = retval.kategorija;
                konfigurirajStavku(stavka);
            });
        }

        function konfigurirajStavku(stavka) {

            /**
             *  Ako je stavka OSA onda količina mora biti 1
             *  Ako je stavka OSA onda inventar_broj mora biti obavezno polje
             **/
            if (stavka.ulazna_kategorija &&
                stavka.ulazna_kategorija.uid &&
                stavka.ulazna_kategorija.uid === AppConstants.OSNOVNO_SREDSTVO) {
                stavka.kolicina = 1;
            }

            /* Ako je stavka OSA ili SI otvori detalje stavke*/
            if (vm.isStavkaOSAorSI(stavka)) {
                stavka.showDetails = true;
            }
            stavka.formatFieldsDefault();
        }

        function promjeneNaStavci(stavka) {
            try {
                RacunService.calculateStavkaCijenaFromTotal(stavka)
            }
            catch (err) {
            }

            $scope.RacunFormCtrl.reloadRekapitulacija();
            pametnaProvjeraOsaSi(stavka, batchData);
        }

        function deleteItem($index) {
            RacunService.removeStavka($scope.RacunFormCtrl.racun, $index);
        }

        /**
         * Funkcija koja kreira novu stavku, konfigurira ju s defaultnim parametrima,
         * dodaje ju u ulazni račun, a nakon toga i na sučelje
         */
        function newRow() {
            var stavkaModel = $scope.RacunFormCtrl.stavkaModel;
            var stavka = RacunService.createStavkaDirtyAndPreconfigured(batchData, $scope.RacunFormCtrl.racun, stavkaModel);

            /* Postavi defaultnu kategoriju ako je dokument URA */
            if ($scope.RacunFormCtrl.documentSlug === AppConstants.IRA_SLUG) {
                stavka.ulazna_kategorija = UlaznaKategorijaService.findKategorijaBySlug(batchData.UlaznaKategorijaRest, AppConstants.IRA_SLUG_USLUGA);
            }

        }

    }

})();
