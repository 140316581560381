(function () {

    angular.module("conto")
        .factory('SessionDataRest', SessionDataRest);

    function SessionDataRest($log, Tenant, User, Permisije, RestService, $q, AppConfig, $http, AppConstants) {

        var modelClass = Tenant;
        var cacheStorage = null;

        var config = {
            /* Define API url here */
            'apiUrl': 'session-data'
        };

        var rs = new RestService();
        rs.setConfig(config);

        function prepareForClient(item) {

            item.commercial_package = findItemById(item.naplatni_paket, AppConstants.PAKETI_LIST);
            item.billing_interval = findItemById(item.billing_interval, AppConstants.RAZDOBLJE_LIST);

            return item;
        }

        rs.getList = function (options) {
            return $q(function (resolve, reject) {
                if (!options) options = {};

                // Ako su podaci unaprijed keširani nemoj raditi novi request
                if (cacheStorage && options.cache) {
                    return resolve(cacheStorage);
                }

                $http({
                    method: 'GET',
                    url: AppConfig.server + '/api/' + config.apiUrl

                }).then(
                    /**
                     * @param response {*}
                     */
                    function successCallback(response) {
                        var data = response.data;
                        //$log.debug("SessionDataRest", data);

                        var tenant = new Tenant;
                        prepareForClient(data.tenant);
                        tenant.setData(data.tenant);

                        var user = new User;
                        user.setData(data.user);

                        var permissionList = [];
                        angular.forEach(data.permission, function (value, key) {
                            var permItem = new Permisije;
                            permItem.setData(value);
                            permissionList.push(permItem.getData());
                        });

                        var retval = {
                            'tenant': tenant,
                            'user': user,
                            'permission': permissionList
                        };


                        if (options.cache) {
                            cacheStorage = retval;
                        }

                        resolve(retval);

                    }, function (err) {
                        reject(err);
                    });

            });


        };

        return rs;

    }

})();
