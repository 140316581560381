(function() {

    angular.module("conto")
        .factory('UlaznaKategorijaRest', UlaznaKategorijaRest);

    function UlaznaKategorijaRest($log, UlaznaKategorija, RestService) {

        var config = {
            'model': UlaznaKategorija,
            'apiUrl': 'ulazna-kategorija'
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;
    }

})();
