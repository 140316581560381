(function () {

    angular.module("conto")
           .component('pocetnoStanjeIzvodBlagajna', {
               templateUrl: 'Modules/PocetnoStanje/templates/pocetno-stanje-izvod-blagajna.html',
               controller: PocetnoStanjeIzvodBlagajnaComponent,
               require: {
                   pocetnoStanjeCtrl: '^pocetnoStanje'
               }
           });

    function PocetnoStanjeIzvodBlagajnaComponent($scope, $element, PartnerRest,
                                                 TreeboxPopupOpener, UlaznaKategorijaService,
                                                 PocetnoStanjeIzvodBlagajna,
                                                 ModalService, $attrs, FetchDataInBatch,
                                                 $log, RestOptions) {

        var vm = this;
        vm.document = new PocetnoStanjeIzvodBlagajna;

        this.$onInit = function () {

        };


    }

})();
