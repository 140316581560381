/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('Naselje', Naselje);

    function Naselje($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            zupanija_naziv: 'required|text',
            go_naziv: 'required|text',
            naselje_naziv: 'required|text',
            postanski_ured_naziv: 'required|text',
            postanski_broj: 'required|text'
        };


        return ClassUtils.defineModel('Naselje', attributes);

    }

})();
