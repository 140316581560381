(function () {

    angular.module("conto")
        .factory('SupValidation', SupValidation);

    function SupValidation($log, AppConstants) {

        var DEFINED_RULES = {};
        var VALIDATOR_SPLITTER = '|';
        var VALIDATOR_ATTR_SPLITTER = ':';

        return function () {

            var definedRules;

            var service;
            service = {
                'validate': validate,
                'register': register
            };

            /* class "constructor" and initialisation */
            registerDefaults();
            return service;


            function validate(data, rules) {
                definedRules = rules;
                var errors = {};
                var retval = true;
                //console.log("before foreach", data, rules);
                angular.forEach(data, function (item, attributeName) {
                    //console.log(item, attributeName, data, rules);
                    if (rules && rules[attributeName]) {
                        var parsedRules = getRules(rules[attributeName]);
                        angular.forEach(parsedRules, function (ruleParam, ruleKey) {
                            if (!DEFINED_RULES[ruleKey]) {
                                throw new Error('SupValidation rule: ' + ruleKey + ' does not exist!');
                            }

                            var tryIt = DEFINED_RULES[ruleKey](item, attributeName, ruleParam);
                            if (!tryIt) {
                                retval = false;
                                if (!errors[attributeName]) {
                                    errors[attributeName] = [];
                                }
                                errors[attributeName].push(ruleKey);
                            }
                        });
                    }
                });

                return retval === true ? true : errors;
            }

            function getRules(rulesConcatenated) {
                if (typeof rulesConcatenated === 'string') {
                    var rules = rulesConcatenated.split(VALIDATOR_SPLITTER);
                    /* Get rule attr if exist */
                    var rulesObject = {};
                    angular.forEach(rules, function (rule) {
                        var attrSplit = rule.split(VALIDATOR_ATTR_SPLITTER);
                        rulesObject[attrSplit[0]] = attrSplit[1];
                    });

                    return rulesObject;
                }
                else return {};
            }

            function register(ruleName, ruleFunction) {
                if (!(ruleName && typeof ruleFunction === 'function')) {
                    throw new Error("SupValidation rule registration failed");
                }
                DEFINED_RULES[ruleName] = ruleFunction;

                return this;
            }

            function hasRule(ruleName, itemName) {
                var parsedRules = getRules(definedRules[itemName]);
                //$log.warn(ruleName, itemName, definedRules, parsedRules);
                return (parsedRules && ruleName in parsedRules);
            }


            function getSize(value, attribute) {

                if (value instanceof Array) {
                    return value.length;
                }

                if (typeof value === 'number') {
                    return value;
                }

                //$log.debug("prije has rule", value, attribute, parseFloat(value));
                if (hasRule('numeric', attribute)) {
                    //$log.debug("hasrulenumeric", attribute);
                    return parseFloat(value);
                }

                return value.length;
            }


            /* Default rules defined */

            function registerDefaults() {
                register('numeric', function (val) {
                    if (!val) return true;
                    var num;
                    num = Number(val);
                    return !!(typeof num === 'number' && !isNaN(num) && typeof val !== 'boolean');
                });

                register('required', function (val) {
                    if (val === undefined || val === null) return false;

                    var str = String(val).replace(/\s/g, "");
                    return str.length > 0;
                });

                register('min', function (val, attributeName, ruleParam) {
                    if (val === undefined || val === null) return true;

                    var size = getSize(val, attributeName);
                    return size >= ruleParam;
                });

                register('object', function (value, attribute) {
                    if (value === undefined || value === null) return true;
                    return typeof value === 'object'
                });

                register('uuid', function (value, attribute) {
                    if (!value) return true;

                    var _isUUID = function (id, ignoreDashes) {
                        if (typeof id != 'string') {
                            return false;
                        }

                        if (ignoreDashes) {
                            id = id.replace(/\-/g, '');
                            return id.match(/^([0-9a-fA-F]){32}$/) !== null;
                        }

                        return id.match(/^([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}$/) !== null;
                    };

                    return _isUUID(value);
                });

                register('email', function(value) {
                    if (!value) return true;
                    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(value);
                });

                register('date', function (value, attribute) {
                    if (value === undefined || value === null) return true;
                    if (value && typeof value.isValid === 'function') {
                        return value.isValid();
                    }

                    return value && (moment(value, AppConstants.DDMMYYY).isValid() || moment(value, AppConstants.DDMMYYY_INTERNATIONAL).isValid());
                });

                register('oib', function (value, attribute) {
                    if (!value) return true;
                    return validateOib(value);

                });


                register('text', function (value, attribute) {
                    if (!value || value === null) return true;

                    //TODO!
                    if (!(/^[a-zA-ZžŽčČšŠćĆđĐ0-9!?()\n\r'",_ :/\\.\-]+$/).test(value)) console.error(value); else return true;

                });

                register('iban', function (value, attribute) {
                    if (!value || value === null) return true;

                    return IBAN.isValid(value);

                });

                register('alpha_num', function(value) {
                    if (value === undefined || value === null) return true;
                    return (/^[a-zA-Z0-9]+$/).test(value);
                });

                register('array', function(val) {
                    return val instanceof Array;
                });

                register('alpha', function(value) {
                    if (value === undefined || value === null) return true;
                    return (/^[a-zA-Z]+$/).test(value);
                });

                register('alpha_dash', function(value) {
                    if (value === undefined || value === null) return true;
                    return (/^[a-zA-Z0-9_\-]+$/).test(value);
                });

                register('string', function(value) {
                    if (!value) return true;
                    return typeof value === 'string';
                });

                register('bool', function(val) {
                    if (val === undefined || val === null || val === false) return true;
                    return !!(val === 'true' || val === true || val === 'yes' || val === 1 || val === '1');
                });

                register('in', function(val, attribute, req) {
                    if (val === undefined || val === null) return true;
                    var list, i;

                    if (val) {
                        list = req.split(',');
                    }

                    if (val && !(val instanceof Array)) {
                        val = String(val); // if it is a number

                        for (i = 0; i < list.length; i++) {
                            if (val === list[i]) {
                                return true;
                            }
                        }

                        return false;
                    }

                    if (val && val instanceof Array) {
                        for (i = 0; i < val.length; i++) {
                            if (list.indexOf(val[i]) < 0) {
                                return false;
                            }
                        }
                    }

                    return true;
                });

            }
        }


    }

})();
