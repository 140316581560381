(function() {

    angular.module("conto")
        .factory('NacinPlacanjaRest', NacinPlacanjaRest);

    function NacinPlacanjaRest(NacinPlacanja, RestService) {

        var config = {
            'model': NacinPlacanja,
            'apiUrl': 'nacin-placanja'
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

    }

})();
