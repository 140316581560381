(function () {

    angular.module("conto")
           .controller('IzvodFormStavkeCtrl', IzvodFormStavkeCtrl);

    /** @namespace $scope.IzvodFormCtrl */
    function IzvodFormStavkeCtrl($scope,
                                 $log,
                                 SessionDataRest,
                                 $q,
                                 FetchDataInBatch,
                                 RestOptions,
                                 RacunService,
                                 PdvRest,
                                 UlaznaKategorijaRest,
                                 JmjRest,
                                 $rootScope,
                                 ModalService,
                                 SweetAlert,
                                 $http,
                                 ValidationService,
                                 IzvodStavka,
                                 PartnerRest,
                                 AppConfig,
                                 AppConstants,
                                 $translate,
                                 TreeboxPopupOpener,
                                 OsaSiValidationMessages,
                                 UlaznaKategorijaService) {

        var vm = this;
        var batchData;

        const ODLJEVI = 'odljevi';
        const PRILJEVI = 'priljevi';

        vm.editItem = {};
        vm.editItemIndex = null;
        vm.jsonStavke = [];
        vm.tenantData = {};
        vm.rekapitulacija = {odljev: 0, priljev: 0};

        vm.calculateTotals = RacunService.calculateStavkaTotal;
        vm.isStavkaOSAorSI = OsaSiValidationMessages.isStavkaOSAorSI;

        vm.newRow = newRow;
        vm.showTreeBox = showTreeBox;
        vm.deleteItem = deleteItem;
        vm.konfigurirajStavku = konfigurirajStavku;
        vm.promjeneNaStavci = promjeneNaStavci;
        vm.setStavkaFlux = setStavkaFlux;
        vm.addNewPartner = addNewPartner;
        vm.partnerListReload = partnerListReload;

        activate();

        function activate() {
            batchData = $scope.IzvodFormCtrl.fetchInBatch;
            vm.batchData = batchData;
            vm.tenantData = batchData.SessionDataRest.tenant;
            vm.pdv = batchData.PdvRest;
            vm.jmj = batchData.JmjRest;
            vm.ulaznaKategorijaList = batchData.UlaznaKategorijaRest;
            vm.ulaznaKategorijaDostupne = getKategorijeForSelectbox;

            if (!$scope.IzvodFormCtrl.disabled && !$scope.IzvodFormCtrl.updateId) {
                newRow();
            }
        }

        function partnerListReload(stavka, e) {
            $log.debug('STAVKA', stavka);
            $log.debug('STAVKA SEARCH', e);
            if (!(stavka && stavka.tip)) return;
            stavka.partnerList = [];
            var restOptions = new RestOptions;
            var partnerType = stavka.tip.partner_role;
            if (partnerType && partnerType !== '*') {
                restOptions.setCustomOption(partnerType, true);

                /**
                 * Ako je kategorija stavke promjenjena a partner je prije bio odabran te
                 * tip partnera ne udovoljaval kriteriju partner_role resetiraj parntera iz stavke
                 */
                if (stavka.partner && !stavka.partner.isPartnerOfType(partnerType)) {
                    stavka.partner = null;
                }

            }
            $log.debug("restOptions", restOptions.get());
            PartnerRest.getList(restOptions.setQuery(e).get()).then(function (data) {
                if (data && data.length) {
                    stavka.partnerList = data;
                }
                else {
                    stavka.partnerList = [{'naziv': e, id: 'new'}];
                }
            });
        }

        function addNewPartner(stavka, selectRetval) {
            batchData = $scope.IzvodFormCtrl.fetchInBatch;

            ModalService.showModal({
                templateUrl: 'Modules/Partner/PartnerForm/partner-modal.html',
                controller: "PartnerFormCtrl as PartnerFormCtrl",
                inputs: {
                    'fetchInBatch': batchData,
                    'supModalOptions': {
                        'autoFlag': stavka.tip.partner_role,
                        'selectBoxData': selectRetval
                    },
                    'partnerType': function () {
                        return null;
                    }
                }
            }).then(function (modal) {
                modal.element.modal();
                modal.close.then(function (partner) {
                    $('.modal-backdrop').remove();
                    if (partner) {
                        stavka.partner = partner;
                    }
                });
            });
        }

        function setKeyByAttrVal(arrOfObjects, attrName) {
            var retval = {};
            angular.forEach(arrOfObjects, function (item) {
                retval[item[attrName]] = item;
            });

            return retval;
        }

        function isRelatedOfCategory(kategorijeArray, categoryObjectId, parentId) {
            var retval = false;

            if (!(kategorijeArray[categoryObjectId] && kategorijeArray[categoryObjectId].roditelj_id)) return false;

            if (kategorijeArray[categoryObjectId].roditelj_id === parentId) {
                retval = true;
            }
            else {
                retval = isRelatedOfCategory(kategorijeArray, kategorijeArray[categoryObjectId].roditelj_id, parentId)
            }

            return retval;
        }

        function fetchUlazneKategorijeZaIzvod(stavka) {
            var catRetvalArr = [];

            var flux = 'all';
            if (stavka && stavka.getStavkaFlux() === AppConstants.ODLJEV) {
                flux = ODLJEVI;
            }
            else if (stavka && stavka.getStavkaFlux() === AppConstants.PRILJEV) {
                flux = PRILJEVI;
            }

            var mainParent;
            angular.forEach(batchData.UlaznaKategorijaRest, function (item) {
                if (item.uid === flux) mainParent = item.id;
            });

            var cats = setKeyByAttrVal(batchData.UlaznaKategorijaRest, 'id');

            angular.forEach(batchData.UlaznaKategorijaRest, function (item) {
                if (item.vrsta_dokumenta === 'IZV') {
                    if (isRelatedOfCategory(cats, item.id, mainParent)) {
                        var bc = breadcrumb(item, cats);
                        if (bc) {
                            item.breadcrumb = bc.trim().substr(2);
                        }
                        catRetvalArr.push(item);
                    }
                }
            });

            //$log.debug("fetchUlazneKategorijeZaIzvod", JSON.stringify(catRetvalArr));
            return {list: catRetvalArr, initialCatId: mainParent};
        }

        function getKategorijeForSelectbox(stavka) {
            var retval = [];
            var list = fetchUlazneKategorijeZaIzvod(stavka).list;
            angular.forEach(list, function (item) {
                if (!UlaznaKategorijaService.hasChilds(list, item.id)) {
                    retval.push(item);
                }
            });

            return retval;

        }

        function breadcrumb(item, obj) {
            var bc = [];
            if (item.roditelj_id) {
                if (obj[item.roditelj_id]) {
                    bc.push(obj[item.roditelj_id].naziv);
                    bc.push(breadcrumb(obj[item.roditelj_id], obj));
                }
            }

            return bc.reverse().join(' > ');
        }

        function showTreeBox(stavka) {
            stavka.ulazna_kategorija = stavka.tip; //todo: kako ovo bolje napraviti?
            var twData = fetchUlazneKategorijeZaIzvod(stavka);
            TreeboxPopupOpener.showTreeBox(twData.list, stavka, twData.initialCatId).then(konfigurirajStavku);
        }

        function konfigurirajStavku(retval) {
            $log.debug('konfigurirajStavku');
            retval.stavka.tip = retval.kategorija;
            retval.stavka.partnerList = [];
            vm.partnerListReload(retval.stavka, '');
        }

        function promjeneNaStavci(stavka) {
            calculateTotals();
        }

        function deleteItem($index) {
            $scope.IzvodFormCtrl.racun.obrisiStavku($index);
            calculateTotals();
        }

        function setStavkaFlux(stavka, fluxType) {

            stavka.disablePriljev = false;
            stavka.disableOdljev = false;
            var parentCategory;

            if (fluxType === 'priljev' && (stavka.priljev > 0 || stavka.priljev < 0)) {
                stavka.odljev = 0;
                stavka.disableOdljev = true;

                if (stavka.tip) {
                    parentCategory = UlaznaKategorijaService.findKategorijaById(batchData.UlaznaKategorijaRest, stavka.tip.roditelj_id);
                    if (parentCategory.uid === ODLJEVI) {
                        stavka.tip = null;
                    }
                }

            }
            else if (fluxType === 'odljev' && (stavka.odljev > 0 || stavka.odljev < 0)) {
                stavka.priljev = 0;
                stavka.disablePriljev = true;

                if (stavka.tip) {
                    parentCategory = UlaznaKategorijaService.findKategorijaById(batchData.UlaznaKategorijaRest, stavka.tip.roditelj_id);
                    if (parentCategory.uid === PRILJEVI) {
                        stavka.tip = null;
                    }
                }
            }

            promjeneNaStavci(stavka);
        }

        function calculateTotals() {
            vm.rekapitulacija = $scope.IzvodFormCtrl.racun.calculateTotals();
        }

        function getLastDate() {
            var retval = $scope.IzvodFormCtrl.racun.datum;
            if ($scope.IzvodFormCtrl.racun.stavke &&
                $scope.IzvodFormCtrl.racun.stavke.length) {

                var stLength = $scope.IzvodFormCtrl.racun.stavke.length;

                if ($scope.IzvodFormCtrl.racun.stavke[stLength - 1] &&
                    $scope.IzvodFormCtrl.racun.stavke[stLength - 1].datum) {
                    retval = $scope.IzvodFormCtrl.racun.stavke[stLength - 1].datum;
                }
            }

            return retval;
        }

        /**
         * Funkcija koja kreira novu stavku, konfigurira ju s defaultnim parametrima,
         * dodaje ju u ulazni račun, a nakon toga i na sučelje
         */
        function newRow() {
            //var stavka = RacunService.createStavkaDirtyAndPreconfigured(batchData, $scope.IzvodFormCtrl.izvod, stavkaModel);
            var stavka = (new IzvodStavka);
            stavka.datum = getLastDate();
            $scope.IzvodFormCtrl.racun.dodajStavku(stavka);
            calculateTotals();

            if ($scope.IzvodFormCtrl.racun.stavke && $scope.IzvodFormCtrl.racun.stavke.length > 1) {
                setTimeout(function () {
                    angular.element('[name="IzvodStavka.odljev"]:last').focus();
                }, 10);
            }

        }

    }

})();
