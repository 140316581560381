(function () {

    angular.module("conto")
        .directive('supForm', SupFormDirective);

    function SupFormDirective() {

        return {
            restrict: 'EA',
            scope: {
                document: '=',
                beforeSubmit: '=?',
                modelRest: '=?',
                callback: '=?',
                disabled: '=?',
                editId: '=?'
            },
            templateUrl: 'Components/SupFormDirective/sup-form.html',
            controllerAs: 'SupForm',
            transclude: 'true',
            bindToController: true,
            controller: function ($scope, $log, SweetAlert, ValidationUtils, $state, uuid4, $translate) {
                var vm = this;

                if (!vm.document) throw new Error("supForm document missing");
                if (vm.modelRest) {
                    //$log.info("modelRest", vm.modelRest, Object.keys(vm.modelRest));

                    //vm.editId = $stateParams.id || 'new';
                    if (!vm.editId) vm.editId = 'new';
                    vm.updateId = (vm.editId !== 'new') ? vm.editId : null;


                    //$log.debug("SupForm edit id", vm.editId);

                    if (vm.editId !== 'new') {

                        if (vm.document && vm.document.id !== vm.editId) {
                            vm.tblLoader = true;
                            vm.modelRest.getOne(vm.editId).then(function (data) {
                                    vm.document.setData(data);
                                    vm.customStatus = {id: data.status, naziv: data.status};
                                    vm.tblLoader = false;
                                },
                                function (err) {
                                    $log.error(err);
                                    // TODO: prikazati errore
                                });
                        }
                    }
                    else {
                        vm.document.id = uuid4.generate();
                    }

                    vm.deleteItem = function () {
                        var _id = vm.editId;

                        SweetAlert.swal({
                                title: "Jeste li sigurni?",
                                text: "Jeste li sigurni da želite obrisati dokument?",
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                cancelButtonText: "Odustani",
                                confirmButtonText: "Da, obriši!", closeOnConfirm: false
                            },
                            function (isConfirm) {
                                fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                                if (isConfirm) {

                                    $scope.$broadcast('supForm:deleteRequestInProgress', true);
                                    vm.modelRest.remove(_id).then(function (data) {
                                            //$log.warn("Spremanje člana", data);
                                            $scope.$broadcast('supForm:deleteRequestInProgress', false);

                                            //TODO: refaktorirati sweetalert poziv
                                            SweetAlert.swal({
                                                    title: "Bravo!",
                                                    type: "success",
                                                    text: "Uspješno ste obrisali dokument!",
                                                    timer: 1500,
                                                    showConfirmButton: false
                                                },
                                                function () {
                                                    fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                                                    swal.close();
                                                    if (vm.callback && typeof vm.callback === "function") {
                                                        vm.callback();
                                                    }
                                                });
                                        },
                                        function (err) {
                                            $log.error(err);
                                            SweetAlert.swal("Upozorenje", "Stavka nije mogla biti obrisana!", "error");
                                            $scope.$broadcast('supForm:deleteRequestInProgress', false);
                                        });

                                }

                            });
                    };

                    vm.save = function () {

                        $scope.$broadcast('supForm:saveRequestInProgress', true);

                        if (vm.beforeSubmit && typeof vm.beforeSubmit === "function") {
                            if (vm.beforeSubmit(vm.document)) {
                                saveDocument();
                            }
                            else {
                                $scope.$broadcast('supForm:saveRequestInProgress', false);
                            }
                        }
                        else {
                            saveDocument();
                        }
                    };


                    function saveDocument() {
                        try {
                            vm.document.validate(); // Throws exception
                        }
                        catch (err) {
                            //TODO: refaktorirati sweetalert poziv
                            SweetAlert.swal({
                                    title: $translate.instant('supForm.saveErrorTitle'),
                                    type: "error",
                                    text: err.prittyPrint()
                                },
                                function () {
                                    fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                                    swal.close();
                                    setTimeout(function () {
                                        angular.element(".has-error:first").focus();
                                    }, 10);
                                });
                            $scope.$broadcast('supForm:saveRequestInProgress', false);
                            return false;
                        }

                        vm.modelRest.save(vm.document, vm.updateId).then(function (data) {
                                // TODO: validacija sa servera
                                $scope.$broadcast('supForm:saveRequestInProgress', false);

                                vm.saveInProgress = false;
                                //TODO: refaktorirati sweetalert poziv
                                SweetAlert.swal({
                                        title: "Bravo!",
                                        type: "success",
                                        text: "Podatak uspješno spremljen!",
                                        timer: 1500,
                                        showConfirmButton: false
                                    },
                                    function () {
                                        fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                                        swal.close();
                                        if (vm.callback && typeof vm.callback === "function") {
                                            vm.callback(vm.document);
                                        }
                                    });
                            },
                            function (err) {
                                $scope.$broadcast('supForm:saveRequestInProgress', false);

                                SweetAlert.swal({
                                    title: $translate.instant('supForm.saveErrorTitle'),
                                    type: "error",
                                    text: ValidationUtils.formatErrorMsg(err.data, vm.document.getModelName())
                                });
                                $log.error(err);
                            });
                    }

                }
            }
        };

    }

})();
