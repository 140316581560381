(function () {

    angular.module("conto")
           .directive('supInput', SupInputDirective);

    function SupInputDirective($log, $compile) {

        return {
            restrict: 'EA',
            require: ['^supForm', 'ngModel'],
            scope: {
                document: '='
            },
            template: ['<div class="form-group">',
                       '    <label></label>',
                       '    <div class="input-group-eventually">',
                       '        <input title="" type="text" ng-model="inputVal" class="form-control" />',
                       '    </div>',
                       '</div>'].join(''),
            controller: function ($scope, $log) {
                var vm = this;
            },
            link: function (scope, element, attr, ctrls) {
                //$log.debug("supFormInput", scope, element, attr, ctrls);

                var supFormCtrl = ctrls[0];
                var ngModelCtrl = ctrls[1];

                var ALLOWED_TYPES = ['text', 'password', 'checkbox'];

                var nameFromModel = function (modelName) {
                    modelName = modelName.split(".");
                    return modelName[modelName.length - 1];
                };
                var name, doc;
                if (!scope.document) {
                    doc = supFormCtrl.document;
                    name = supFormCtrl.document.getModelName() + "." + nameFromModel(attr.ngModel);
                }
                else {
                    doc = scope.document;
                    name = scope.document.getModelName() + "." + nameFromModel(attr.ngModel);
                }


                var input = element.find("input");
                input.attr("name", name);

                /**
                 *  Focus this field if focus-this="true"
                 **/
                if (attr.focusThis) {
                    setTimeout(function () {
                        element.find(".input-group-eventually input:first").focus();
                    }, 100);
                }

                /* Postavi custom addon ako postoji*/
                if (attr.inputGroupAddon) {
                    element.find(".input-group-eventually")
                           .addClass("input-group")
                           .prepend('<div class="input-group-addon"> ' + attr.inputGroupAddon + '</div>');
                }

                if (supFormCtrl.disabled) {
                    input.attr('disabled', true);
                }

                if ($.inArray(attr.type, ALLOWED_TYPES) > -1) {
                    input.attr("type", attr.type);
                }

                if (attr.autofocus) {
                    input.attr("autofocus", true);
                }

                var validityClass = function (validateVar) {
                    input.removeClass("has-error");

                    if (validateVar &&
                        validateVar.errors &&
                        validateVar.errors[name]) {
                        input.addClass("has-error");
                    }
                };

                var requiredClass = function () {
                    input.removeClass("input-required");
                    var attrs = doc.getAttributes();
                    if (attrs && attrs[nameFromModel(attr.ngModel)]) {
                        var attrWithRule = attrs[nameFromModel(attr.ngModel)];
                        if (attrWithRule.indexOf("required") > -1) {
                            input.addClass("input-required");
                            //$log.info("Postavljam klasu required na: " + nameFromModel(attr.ngModel));
                        }

                    }
                };
                requiredClass();

                ngModelCtrl.$formatters.push(function (modelValue) {
                    return modelValue;
                });

                ngModelCtrl.$render = function () {
                    scope.inputVal = ngModelCtrl.$viewValue;
                };

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return viewValue;
                });

                scope.$watch('inputVal', function (newVal) {
                    ngModelCtrl.$setViewValue(newVal);
                    validityClass(doc);
                });

                var label = $(element).find("label");
                if (attr.label) {
                    label.html(attr.label);
                }
                else {
                    label.remove();
                }

                /* Watch for errors */
                scope.$watch(function () {
                        if (doc.rawData.errors) return doc.rawData.errors;
                    },
                    function (newValue) {
                        if (newValue && newValue[name]) {
                            validityClass(doc.rawData);
                        }
                    });


            }
        };

    }

})();
