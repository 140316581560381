(function () {

    angular.module("conto")
        .directive('supInputNaselje', SupInputNaselje);

    function SupInputNaselje($log, $compile, $translate) {

        return {
            restrict: 'EA',
            require: ['^supForm', 'ngModel'],
            scope: {},
            templateUrl: 'Components/SupFormDirective/sup-input-naselje.html',
            controllerAs: 'SupInputNaseljeCtrl',
            controller: function ($scope, $log, RestOptions, NaseljeRest, $translate) {
                var vm = this;

                vm.naseljaList = [];
                vm.inputVal = {};
                vm.reloadNaselja = reloadNaselja;
                vm.restOptions = new RestOptions;

                var timeoutId = 0;

                function reloadNaselja(e) {
                    //var query = vm.restOptions.setQuery(e).get();
                    if (!e) e = '%';
                    var query = vm.restOptions.setLimit(100).setCustomOption('naselje_naziv', e + '%').get(); //TODO:!
                    NaseljeRest.getList(query).then(function (data) {
                        vm.naseljaList = data;
                    });
                }
            },
            link: function (scope, element, attr, ctrls) {
                //$log.debug("supFormInput", scope, element, attr, ctrls);
                var supFormCtrl = ctrls[0];
                var ngModelCtrl = ctrls[1];

                ngModelCtrl.$formatters.push(function (modelValue) {
                    return modelValue;
                });

                ngModelCtrl.$render = function () {
                    scope.SupInputNaseljeCtrl.inputVal = ngModelCtrl.$viewValue;
                };

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return viewValue;
                });

                /* Dodaj heading row */
                var headingHtml = [
                    '<div class="row naselje-top-row">',
                    '<div class="col-md-5">' + $translate.instant('Naselje.naselje') + '</div>',
                    '<div class="col-md-4">' + $translate.instant('Naselje.gradopcina') + '</div>',
                    '<div class="col-md-3">' + $translate.instant('Naselje.postanskibroj') + '</div>',
                    '</div>'
                ].join('');
                element.find(".ui-select-choices").prepend(headingHtml);

                scope.$watch(function () {
                    if (scope.SupInputNaseljeCtrl.inputVal) return scope.SupInputNaseljeCtrl.inputVal;
                }, function (newVal) {
                    ngModelCtrl.$setViewValue(newVal);
                });
            }
        };

    }

})();
