(function () {

    angular.module("conto")
        .controller('UserDataCtrl', UserDataCtrl);

    function UserDataCtrl(UserRest, $stateParams, sessionData, $log, $state, PasswordReset, SweetAlert, $translate) {
        var vm = this;

        const LIST_ROUTE = 'UserData';
        vm.document = sessionData.user;
        vm.modelRest = UserRest;
        vm.password = new PasswordReset;
        vm.showPasswordChangeBox = false;

        vm.afterFormCallback = afterFormCallback;
        vm.cancel = cancel;
        vm.checkIfPasswordEqual = checkIfPasswordEqual;

        function afterFormCallback(item) {
            vm.showPasswordChangeBox = false;
            $state.go(LIST_ROUTE);
        }

        function cancel() {
            $state.go('home');
        }

        function checkIfPasswordEqual() {
            if (!vm.showPasswordChangeBox) return true;

            try {
                vm.password.validate();
                vm.document.password = vm.password.password1;
                return true;
            }
            catch(err) {
                SweetAlert.swal({
                    title: $translate.instant('supForm.saveErrorTitle'),
                    type: "error",
                    text: 'Morate ispravno unijeti lozinku te ponoviti upis.'
                });
            }
        }

    }

})();
