(function() {
    angular
        .module('conto', [
            'templates'
            ,'satellizer'
            ,'ui.router'
            ,'ui.select'
            ,'ngSanitize'
            ,'angular-loading-bar'
            ,'oitozero.ngSweetAlert'
            ,'ngDialog'
            ,'uuid4'
            ,'ui.event'
            ,'bw.paging'
            ,'pascalprecht.translate'
            ,'translations'
            ,'ngCookies'
            ,'angularModalService'
            ,'720kb.tooltips'
        ])

})();
