/**
 * Created by danijelmaric on 15/02/16.
 */
(function () {

    angular.module("conto")
           .factory('PocetnoStanje', PocetnoStanje);

    function PocetnoStanje($log, ValidationService, ClassUtils, ValidationException) {


        return function () {

            var attributes = {
                godina: 'required|numeric',
                stanje_izvoda: 'required|numeric',
                stanje_blagajne: 'required|numeric',
                prihodi_iz_razdoblja: 'required|numeric',
                ura: 'object',
                ira: 'object'
                /*  osa: 'object',
                 si: 'object'*/
            };

            var service = ClassUtils.defineModel('PocetnoStanje', attributes)();
            var serviceValidate = service.validate;
            service.validate = validate;

            function validate() {
                var errArray = [];

                /* Pozovi glavnu validaciju Ulaznog računa */
                ValidationService.tryCatch(serviceValidate, errArray);

                /* Validiraj stavku po stavku */
                angular.forEach(service.ura, function (stavka, i) {
                    ValidationService.tryCatch(stavka.validate, errArray);
                });

                /* Validiraj stavku po stavku */
                angular.forEach(service.ira, function (stavka, i) {
                    ValidationService.tryCatch(stavka.validate, errArray);
                });

                if (errArray.length) {
                    throw new ValidationException(errArray);
                }

            }

            return service;

        };

    }

})
();
