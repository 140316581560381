(function () {

    angular.module("conto")
           .controller('PocetnoStanjeFormCtrl', PocetnoStanjeFormCtrl);

    function PocetnoStanjeFormCtrl($log, fetchInBatch) {
        var vm = this;

        vm.batchData = fetchInBatch;

    }
})();
