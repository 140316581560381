(function () {

    angular.module("conto")
        .factory('FetchDataInBatch', FetchDataInBatch);

    function FetchDataInBatch($log, $q, $injector, RestOptions) {


        return function () {

            var restItemsToFetch = ['SessionDataRest', 'JmjRest', 'PdvRest', 'NacinPlacanjaRest'];

            var retvalData;
            var service;
            service = {
                'getData': getData,
                'extendItemsToFetch': extendItemsToFetch
            };

            /* class return*/
            return service;

            /**
             *
             * @param arrOrItem RestObject factory
             * @returns {extendItemsToFetch}
             */
            function extendItemsToFetch(arrOrItem) {
                if (angular.isArray(arrOrItem)) {
                    restItemsToFetch = restItemsToFetch.concat(arrOrItem);
                }
                else {
                    restItemsToFetch.push(arrOrItem);
                }

                return this;
            }

            function getData() {

                /* retvalData.jsonStavke = [];

                 var initializeStavka = function() {
                 retvalData.stavka = new UlazniRacunStavka();
                 // Set defaults
                 retvalData.stavka.kolicina = 1;
                 retvalData.ulazna_kategorija = {};

                 // Ako tenant nije obveznik PDV-a postavi defaultnu vrijednost PDV-a na nulu
                 if (!retvalData.tenantData.isPdvObveznik()) {
                 RacunService.setStavkaPdvByVrijednost(retvalData.stavka, 0, retvalData.pdv);
                 }
                 };*/

                var qArray = [];
                angular.forEach(restItemsToFetch, function (item, i) {
                    $log.debug('Fetching: ' + item);
                    qArray.push(fetchObject(item));
                });

                retvalObj = {};
                return $q(function (resolve, reject) {
                    $q.all(qArray).then(function (data) {
                        angular.forEach(restItemsToFetch, function (restItem, i) {
                            retvalObj[restItem] = data[i];
                        });

                        resolve(retvalObj);
                    });
                });
            }

            function fetchObject(restName) {
                var query = (new RestOptions).setLimit(1000).get();
                var restClass = $injector.get(restName);
                return restClass.getList(query);
            }

        };


    }

})();
