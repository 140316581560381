(function() {

    angular.module("conto")
        .directive('supTableRow', SupTableRow);

    function SupTableRow($log, $compile) {

        return {
            restrict: 'EA',
            require: '^supTable',
            link: function(scope, element, attr, supTableCtrl) {
                //$log.debug("SupTableRow", scope, element, attr);
                var html = $(element).html();

                var htmlCheck;
                htmlCheck = '<td class="text-center row-sub-check"> <div> <label> <input type="checkbox" value="" /> </label> </div> </td>';
                element.prepend(htmlCheck);

                element.bind("click", function(event) {
                    supTableCtrl.selectItem(scope.row);
                    checkItem(supTableCtrl.isItemSelected(scope.row));
                    scope.$apply();
                });

                scope.$on('supTable:selectAll', function(event, mass) {
                    //$log.debug("supTable:selectAll", mass);
                    checkItem(mass);
                });

                var checkItem = function(newVal) {
                    $log.debug("checkItem", newVal);
                    element.find('input[type=checkbox]').prop("checked", !!newVal);
                    scope.$emit('supTableRow:selectOne', !!newVal);
                }

            }
        };

    }

})();
