(function () {

    angular.module("conto")
           .component('pocetnoStanje', {
               templateUrl: 'Modules/PocetnoStanje/templates/pocetno-stanje-component.html',
               controller: PocetnoStanjeComponentCtrl,
               bindings: {
                   batchData: '<?'
               }
           });

    function PocetnoStanjeComponentCtrl($scope, $element, PartnerRest, PocetnoStanjeRest,
                                        TreeboxPopupOpener, UlaznaKategorijaService, SupPopupMessagesUtils,
                                        ModalService, $attrs, FetchDataInBatch, PocetnoStanje, $state,
                                        $log, RestOptions) {
        var vm = this;

        vm.document = new PocetnoStanje;
        vm.activate = activate;
        vm.switchTab = switchTab;
        vm.nextTab = nextTab;

        vm.partnerList = [];
        vm.selected = null;
        vm.show = false;

        vm.tabList = [
            'Godina početka',
            'Izvod, blagajna, viškovi/manjkovi',
            'Izlazni računi',
            'Ulazni računi'
        ];

        function activate() {

            PocetnoStanjeRest.getList().then(function (data) {
                if (data && data[0]) {
                    vm.document = data[0];
                    vm.disabled = true;
                }
                else {
                    vm.document['ura'] = [];
                    vm.document['ira'] = [];
                    vm.document.stanje_izvoda = 0;
                    vm.document.stanje_blagajne = 0;
                    vm.document.prihodi_iz_razdoblja = 0;
                }
                switchTab(0);
                vm.show = true;
            });
        }

        this.$onInit = function () {

            if (!vm.batchData) {
                var fetchInBatch = new FetchDataInBatch;
                fetchInBatch.extendItemsToFetch('UlaznaKategorijaRest').getData().then(function (response) {
                    vm.batchData = response;
                    activate();
                });
            }
            else {
                activate();
            }
        };

        function switchTab(tabSelected) {
            vm.tabSelected = tabSelected;
        }

        function nextTab() {
            $log.warn(vm.tabSelected, vm.tabList.length);

            try {
                vm.document.validate(); // Throws exception

                if (vm.tabSelected + 1 === vm.tabList.length) {
                    submit();
                }
                else {
                    vm.tabSelected += 1;
                }
            }
            catch (err) {
                $log.warn("Validation error nexTab", err.errorsList);
                setTimeout(function () {
                    var tabNum = angular.element(".has-error:first").closest('.my-tab').data('num');
                    switchTab(tabNum);
                    angular.element(".has-error:first").focus();
                }, 100);
                return false;
            }


        }

        function submit() {
            $log.info('Submit...');
            $log.debug('JSON', JSON.stringify(vm.document.getData()));

            try {
                vm.document.validate(); // Throws exception
            }
            catch (err) {
                $log.warn("Validation error", err.errorsList);
                setTimeout(function () {
                    var tabNum = angular.element(".has-error:first").closest('.my-tab').data('num');
                    switchTab(tabNum);
                    angular.element(".has-error:first").focus();
                }, 10);
                return false;
            }


            SupPopupMessagesUtils.formSaveConfrm({
                restClass: PocetnoStanjeRest,
                data: vm.document
            }, function (status, data) {
                if (status === SupPopupMessagesUtils.CALLBACK_SUCCESS) {

                    $log.info("SAVED DATA", data);
                    $state.go('PocetnoStanje.form', {}, {reload: true});

                }
            });
        }

    }

})();
