/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('BankovniRacun', BankovniRacun);

    function BankovniRacun($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            naziv: 'required|text',
            iban: 'required|iban'
        };

        return ClassUtils.defineModel('BankovniRacun', attributes);

    }

})();
