(function () {

    angular.module("conto")
        .factory('RestServiceSave', RestServiceSave);

    function RestServiceSave($http, $q, AppConfig, $log) {

        return function () {
            var service;

            var config;
            /**
             * Query string params etc.
             * @type {*}
             */
            var instanceData;

            var _resolve;
            var _reject;

            service = {
                'activate': activate
            };

            /* class return*/
            return service;

            function activate(data, configObj) {
                instanceData = data;
                config = configObj;
                return $q(saveResolve)
            }

            function saveResolve(resolve, reject) {
                _resolve = resolve;
                _reject = reject;

                try {
                    var newObj = new config.model;
                    newObj.setData(instanceData);
                    newObj.validate(); /* throws exception */

                    var dataToSave = newObj.getDataForRest();
                    if (config.prepareForDb && typeof config.prepareForDb === "function") {
                        $log.debug("prepareForDb function in use!");
                        dataToSave = config.prepareForDb(dataToSave);
                    }

                    var httpMethod = config.updateId ? 'PUT' : 'POST';
                    var idUrl = config.updateId ? '/' + config.updateId : '';

                    $http({
                        'method': httpMethod,
                        'url': AppConfig.server + '/api/' + config.apiUrl + idUrl,
                        'data': dataToSave
                    })
                        .then(successCallback)
                        .catch(errorCallback);

                }
                catch(err) {
                    $log.error(err);
                    _reject(err);
                }


            }

            /**
             * @param response {*}
             * @returns {*}
             */
            function successCallback(response) {
                $log.debug("Updated: ", response);
                if (response.data) {
                    return _resolve(response.data);
                }
                else {
                    _resolve();
                }
            }

            function errorCallback(err) {
                return _reject(err);
            }

        }

    }

})();

