/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('Jmj', Jmj);

    function Jmj($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            naziv: 'required'
        };

       return ClassUtils.defineModel('Jmj', attributes);

    }

})();
