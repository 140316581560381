(function() {

	angular.module("conto")
		.directive('supFormButtonCancel', SupFormButtonCancel);

	function SupFormButtonCancel($log) {

		return {
			restrict: 'EA',
			require: '^supForm',
			template: '<button type="button" class="btn btn-default btn-lg">{{\'odustani\' | translate}}</button>',
			replace: true,
            link: function (scope, element, attr, supFormCtrl) {
                element.on('click tap', function(event) {
                    var answer = confirm("Jeste li sigurni da želite napustiti formu za uređivanje?");
                    if (!answer) {
                        event.preventDefault();
                    }
                });

            }
		};

	}

})();
