(function () {

    angular.module("conto")
           .factory('BlagajnickiDokumentRest', BlagajnickiDokumentRest);

    function BlagajnickiDokumentRest($log, BlagajnickiDokument, RestService, BlagajnickiDokumentStavka, SupNacinPlacanja) {

        var config = {
            'model': BlagajnickiDokument,
            'apiUrl': 'blagajnicki-dokument',
            'prepareForDb': prepareForDb,
            'prepareForClient': prepareForClient
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

        function prepareForClient(item) {
            angular.forEach(item.stavke, function (stavkaData, i) {
                var novaStavka = new BlagajnickiDokumentStavka;
                stavkaData.vrsta_prometa = stavkaData.kategorija;

                novaStavka.setData(stavkaData);
                item.stavke[i] = novaStavka;
            });

            return item;
        }

        /* Custom prepare for db functions */
        function prepareStavkeForDb(stavke) {
            var preparedStavke = [];
            angular.forEach(stavke, function (stavka, i) {
                var stavkaPrepared = {

                    "vrsta_prometa": stavka.vrsta_prometa.id,
                    "rbr": i,
                    //"pnbr": stavka.jmj.id,
                    "priljev": stavka.priljev,
                    "odljev": stavka.odljev,
                    "napomena": stavka.napomena
                };

                preparedStavke.push(stavkaPrepared);
            });

            return preparedStavke;
        }

        function prepareForDb(data) {
            return {
                "blagajna_id": data.blagajna.id,
                "partner_id": data.partner.id,
                "datum": data.datum,
                "broj": data.broj,
                "stavke": prepareStavkeForDb(data.stavke)
            };
        }
    }

})();
