(function () {

    angular.module("conto")
           .controller('NavigationCtrl', NavigationCtrl);

    function NavigationCtrl($log, $auth, AppConfig, $state, SessionDataRest, AclService) {
        var vm = this;

        vm.apiServer = AppConfig.server;
        vm.token = $auth.getToken();

        SessionDataRest.getList().then(function (sessionData) {

            vm.tenantData = sessionData.tenant;
            vm.allowedNavigation = {};
            vm.isNavItemAllowed = isNavItemAllowed;
            vm.getLinkUrl = getLinkUrl;

            vm.inNav = {
                'RegistarClanova.list': {
                    name: 'registarClanova',
                    icon: 'glyphicon-user',
                    stateGroup: 'RegistarClanova'
                },
                'Partner.list': {
                    name: 'partneri',
                    icon: 'glyphicon-briefcase',
                    stateGroup: 'Partner'
                },
                'UlazniRacun.list': {
                    name: 'Dokumenti',
                    icon: 'glyphicon-book',
                    stateGroup: 'Knjigovodstvo',
                    childs: {
                        'UlazniRacun.list': {
                            name: 'Ulazni račun',
                            stateGroup: 'UlazniRacun'
                        },
                        'IzlazniRacun.list': {
                            name: 'Izlazni račun',
                            stateGroup: 'IzlazniRacun'
                        },
                        'Izvod.list': {
                            name: 'Izvod',
                            stateGroup: 'Izvod'
                        },
                        'BlagajnickiDokument.list': {
                            name: 'Blagajnički dokument',
                            stateGroup: 'Blagajnički dokument'
                        }
                    }
                },
                'Izvjestaji': {
                    name: 'izvjestaji',
                    icon: 'glyphicon-stats',
                    stateGroup: 'Izvjestaji'
                },
                'Pomoc': {
                    name: 'pomoc',
                    icon: 'glyphicon-question-sign',
                    stateGroup: 'Pomoc'
                },
                'Temeljnica.list': {
                    name: 'Napredno',
                    icon: 'glyphicon-wrench',
                    stateGroup: 'Napredno',
                    childs: {
                        /*'Temeljnica.list': {
                            name: 'Temeljnica'
                        },*/
                        'PocetnoStanje.form': {
                            name: 'Početno stanje'
                        }
                    }
                },
                'TenantData': {
                    name: 'Postavke',
                    icon: 'glyphicon-cog',
                    stateGroup: 'Postavke',
                    childs: {
                        'TenantData': {
                            name: 'Podaci o udruzi'
                        },
                        'UserData': {
                            name: 'Podaci o korisniku'
                        },
                        'Statut': {
                            name: 'Statut'
                        },
                        'KategorijaClanstva.list': {
                            name: 'Kategorije članstva'
                        }
                    }
                }
            };

            activate();

            function activate() {
                /* Naplatni paket*/
                if (vm.tenantData.naplatni_paket) {
                    vm.bundle = vm.tenantData.naplatni_paket;
                }
                else {
                    vm.bundle = 'free';
                }

                AclService.getDefinition().then(function (definition) {
                    angular.forEach(AclService.getAllowedItems(definition, vm.bundle), function (item, key) {
                        vm.allowedNavigation[item.title] = item;
                    });

                });
            }

            function isNavItemAllowed(state) {
                return !!vm.allowedNavigation[state];
            }

            function getLinkUrl(state) {
                return $state.href(state);
            }

        });

    }
})();
