(function () {

    angular.module("conto")
        .factory('RestServiceGetList', RestServiceGetList);

    function RestServiceGetList($http, $q, AppConfig, $log) {

        return function () {
            var service;

            var config;
            /**
             * Query string params etc.
             * @type {*}
             */
            var options = {};

            var cacheStorage = null;
            var _resolve;
            var _reject;

            service = {
                'activate': activate
            };

            /* class return*/
            return service;

            function activate(optionsList, configObj) {
                options = optionsList;
                config = configObj;
                return $q(getListResolve)
            }

            function getListResolve(resolve, reject) {
                _resolve = resolve;
                _reject = reject;

                // Ako su podaci unaprijed keširani nemoj raditi novi request
                if (cacheStorage && options.cache) {
                    $log.debug('Reading data from cache.');
                    cacheStorage.fromCache = true;
                    return _resolve(cacheStorage);
                }

                $http({
                    method: 'GET',
                    url: AppConfig.server + '/api/' + config.apiUrl,
                    params: options.params
                })
                    .then(successCallback)
                    .catch(errorCallback);

            }

            /**
             * @param response {*}
             * @returns {*}
             */
            function successCallback(response) {
                if (config.model) {

                    if (response && response.data && response.data.data) {
                        finalResponse = response.data.data;
                        var dataToResolve = sanitizeObjects(finalResponse);
                        dataToResolve.meta = response.data;

                        if (options.cache) {
                            cacheStorage = dataToResolve;
                        }

                        return _resolve(dataToResolve);
                    }
                    else {
                        throw new Error("Wrong data structure received from API source!")
                    }
                }
                else {
                    return _resolve(response);
                }
            }

            function errorCallback(err) {
                $log.error(err);
                return _reject(err);
            }

            function sanitizeObjects(finalResponse) {
                var list = [];
                var rejectedList = [];
                angular.forEach(finalResponse, function (item, key) {

                    try {
                        var newObj = new config.model;

                        if (config.prepareForClient && typeof config.prepareForClient === "function") {
                            item = config.prepareForClient(item);
                        }

                        newObj.setData(item);

                        /* throws exception */
                        newObj.validate();

                        //list.push(newObj.getData()); todo: dali je ovako oK?
                        list.push(newObj);
                    }
                    catch (err) {
                        $log.error(err, config.model);
                        rejectedList.push({
                            'item': item,
                            'error': err
                        });
                    }

                });

                if (rejectedList.length) {
                    $log.warn("getList modelClass purification failed! " + config.apiUrl);
                    $log.warn("REJECTED ITEMS:", rejectedList);
                }

                return list;
            }

        }

    }

})();
