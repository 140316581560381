(function () {

    angular.module("conto")
        .factory('IzlazniRacunRest', IzlazniRacunRest);

    function IzlazniRacunRest($log, IzlazniRacun, RestService, UlazniRacunStavka, SupNacinPlacanja) {

        var config = {
            'model': IzlazniRacun,
            'apiUrl': 'izlazni-racun',
            'prepareForDb': prepareForDb,
            'prepareForClient': prepareForClient
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

        function prepareForClient(item) {
            angular.forEach(item.json_stavke, function (stavkaData, i) {
                var novaStavka = new UlazniRacunStavka;
                stavkaData.jmj = stavkaData.json_jmj;
                stavkaData.pdv = stavkaData.json_pdv;
                stavkaData.ulazna_kategorija = stavkaData.json_ulazna_kategorija;
                stavkaData.storno = item.storno;
                novaStavka.setData(stavkaData);
                item.json_stavke[i] = novaStavka;
            });

            var jsonNacinPlacanja = new SupNacinPlacanja;
            var tmpObj = item.json_nacin_placanja;
            jsonNacinPlacanja.setData(tmpObj);
            item.json_nacin_placanja = jsonNacinPlacanja;

            return item;
        }

        /* Custom prepare for db functions */
        function prepareStavkeForDb(stavke) {
            var preparedStavke = [];
            angular.forEach(stavke, function (stavka, i) {
                var stavkaPrepared = {
                    "ulazna_kategorija_id": stavka.ulazna_kategorija.id,
                    "kolicina": stavka.kolicina,
                    "cijena": stavka.cijena,
                    "pdv_iznos": stavka.pdv_iznos,
                    "osnovica": stavka.osnovica,
                    "jmj_id": stavka.jmj.id,
                    "pdv_id": stavka.pdv.id,
                    "ukupno": stavka.ukupno,
                    "opis": stavka.opis
                };

                preparedStavke.push(stavkaPrepared);
            });

            return preparedStavke;
        }

        function prepareNacinPlacanjaForDb(json_nacin_placanja) {
            var np = json_nacin_placanja.getData();
            np.metoda = np.nacin_placanja.metoda;
            if (np.nacin_placanja && typeof np.nacin_placanja.getData === 'function') {
                np.nacin_placanja = np.nacin_placanja.getData();
            }
            if (np.dodatni_podaci && typeof np.dodatni_podaci.getData === 'function') {
                np.dodatni_podaci = np.dodatni_podaci.getData();
            }
            return np;
        }

        function prepareForDb(data) {
            return {
                "externa_oznaka": data.externa_oznaka,
                "partner_id": data.json_partner.id,
                "datum_prijema": data.datum_prijema,
                "datum_dospijeca": data.datum_dospijeca,
                "datum_izdavanja": data.datum_izdavanja,
                "dani_odgode": data.dani_odgode*1,
                "datum_obracuna": data.datum_obracuna,
                "datoteka": "TODO",
                "json_stavke": prepareStavkeForDb(data.json_stavke),
                "json_nacin_placanja": prepareNacinPlacanjaForDb(data.json_nacin_placanja)
            };
        }
    }

})();
