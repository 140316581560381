(function () {

    angular.module("conto")
        .factory('TemeljnicaStavka', TemeljnicaStavka);

    function TemeljnicaStavka($log, ValidationService, ClassUtils, ValidationException, AppConstants) {


        return function () {
            var attributes = {
                rbr: 'numeric',
                konto: 'required|numeric',
                duguje: 'numeric|required',
                potrazuje: 'numeric|required',
                partner: 'required|object',
                externa_oznaka: 'text',
                interna_oznaka: 'text',
                datum_dokumenta: 'date',
                datum_dospijeca: 'date',
                napomena: 'text'
            };

            var service = ClassUtils.defineModel('TemeljnicaStavka', attributes)();

            service.getStavkaFlux = getStavkaFlux;
            return service;

            function getStavkaFlux() {
                var stavka = service;
                if (stavka.duguje > 0 || stavka.duguje < 0) {
                    return AppConstants.DUGUJE;
                }
                else if (stavka.potrazuje > 0 || stavka.potrazuje < 0) {
                    return AppConstants.POTRAZUJE;
                }
                else {
                    return AppConstants.NOT_DEFINED_DUGUJE_POTRAZUJE;
                }
            }
        };

    }

})();
