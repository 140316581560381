(function() {

    angular.module("conto")
        .factory('BankovniRacunRest', BankovniRacunRest);

    function BankovniRacunRest(BankovniRacun, RestService) {

        var config = {
            'model': BankovniRacun,
            'apiUrl': 'bankovni-racun'
        };

        var rs = new RestService();
        rs.setConfig(config);

        return rs;

    }

})();
