(function () {

    angular.module("conto")
        .factory('UlazniRacun', UlazniRacun);

    function UlazniRacun($log, ValidationService, Racun, Partner, ClassUtils, ValidationException) {

        return function () {

            var service = Racun();
            return service;

        };

    }

})();
