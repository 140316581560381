(function () {

    angular.module("conto")
           .controller('PartnerFormCtrl', PartnerFormCtrl);

    function PartnerFormCtrl(Partner, PartnerRest, partnerType, RestOptions, SweetAlert, NaseljeRest,
                             close, supModalOptions, $log, $state, $translate, $scope, fetchInBatch, $stateParams, AppConstants) {
        var vm = this;

        var LIST_ROUTE = 'Partner.list';

        if (partnerType && partnerType === AppConstants.PARTNER_TYPE.CLAN) {
            LIST_ROUTE = 'RegistarClanova.list';
        }

        vm.hasCategories = true;
        vm.naseljaList = [];
        vm.naselje = {};
        vm.supModalOptions = supModalOptions;
        //vm.setStatusPartnera = setStatusPartnera;
        vm.afterFormCallback = afterFormCallback;
        vm.cancel = cancel;
        vm.hidePopup = hidePopup;
        vm.isDefaultCountry = isDefaultCountry;
        vm.changePartnerFlag = changePartnerFlag;
        vm.statusOrTypeClassReset = statusOrTypeClassReset;

        activate();

        function activate() {
            vm.editId = close ? 'new' : $stateParams.id;
            initForm();

            $scope.$watch('PartnerFormCtrl.document.drzava', function (odabranaDrzava) {
                if (odabranaDrzava) {
                    vm.document.customFieldsFormat();
                    if (!isDefaultCountry()) {
                        angular.element('[name="Partner.oib"]').removeClass('has-error')
                    }
                }

            })
        }

        function afterFormCallback(item) {
            if (close) close(item);
            else $state.go(LIST_ROUTE);
        }

        function hidePopup() {
            close();
        }

        function cancel() {
            if (close) close();
            else $state.go(LIST_ROUTE);
        }

        function initForm() {
            vm.document = new Partner;
            vm.modelRest = PartnerRest;

            if (vm.editId === 'new' && !vm.document.naziv &&
                supModalOptions &&
                supModalOptions.selectBoxData) {
                vm.document.naziv = supModalOptions.selectBoxData;
            }


            /**
             * Postavi defaultnu drzavu
             */
            if (!vm.document.drzava) {
                $log.info(fetchInBatch);
                vm.document.drzava = fetchInBatch.SessionDataRest.tenant.drzava;
            }

            /**
             * Ako se partner unosi kroz formu RegistraClanova automatski mu dodjeli flag clan=true
             */
            if (partnerType && partnerType === AppConstants.PARTNER_TYPE.CLAN) {
                vm.document.clan = true;
            }

            /**
             *  Kod kreiranja novog partnera automatski dodjeli
             *  default - partner kao pravna osoba
             **/
            if (!vm.document.status && vm.document.clan) {
                vm.document.status = AppConstants.PARTNER_FIZICKA_OSOBA;
            }
            else if (!vm.document.status && !vm.document.clan) {
                vm.document.status = AppConstants.PARTNER_PRAVNA_OSOBA;
            }

            /**
             * Ako se poziva popup kod odabira načina plaćanja automatski uključi checkbox za suradnika
             **/
            if (supModalOptions && supModalOptions.autoFlag) {
                vm.document[supModalOptions.autoFlag] = true;

                if (supModalOptions.autoFlag === AppConstants.PARTNER_TYPE.SURADNIK) {
                    /* Postavi obavezno polje za IBAN */
                    vm.document.customFieldsFormat();
                }
            }
        }

        function changePartnerFlag() {
            if (vm.editId !== 'new' && vm.document.clan === false) {


                SweetAlert.swal({
                        title: $translate.instant('upozorenje'),
                        text: $translate.instant('RegistarClanova.changeFlagWarning'),
                        type: "warning",
                        showCancelButton: true,
                        cancelButtonText: $translate.instant('cancel'),
                        confirmButtonText: $translate.instant('u_redu'),
                        closeOnConfirm: true
                    },
                    function (deselectClanFlag) {
                        fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                        if (!deselectClanFlag) {
                            vm.document.clan = true;
                        }
                        else {
                            statusOrTypeClassReset();
                        }

                    });
            }
            else {
                statusOrTypeClassReset();
            }
        }

        function isDefaultCountry() {
            if (fetchInBatch && fetchInBatch.SessionDataRest &&
                fetchInBatch.SessionDataRest.tenant &&
                fetchInBatch.SessionDataRest.tenant.drzava &&
                fetchInBatch.SessionDataRest.tenant.drzava.id) {

                return vm.document.drzava.id === fetchInBatch.SessionDataRest.tenant.drzava.id;
            }
            else {
                throw new Error('Tenant default country missing!');
            }
        }

        function statusOrTypeClassReset() {
            if (!vm.document.clan) {
                angular.element('[name="Partner.oib"]').removeClass('has-error')
            }
        }


    }

})();
