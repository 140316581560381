(function () {

    angular.module("conto")
        .controller('LoginCtrl', LoginCtrl);

    function LoginCtrl($log, $auth, $state, $stateParams, $http, AppConfig, $window, Login, Registration, SweetAlert) {

        var vm = this;

        vm.display = 'login';
        vm.buttonsDisabled = false;

        vm.loginData = new Login;
        vm.registrationData = new Registration;

        vm.showLoading = function () {
            vm.loading = true;
        };

        vm.hideLoading = function () {
            vm.loading = false;
        };

        /*
         * Token based autentikacija
         * Automatska prijava putem linka, zaobilazi se login screen
         */
        $log.debug($stateParams, $state);
        if ($stateParams.token && $state.current.name == 'auth.token') {
            $auth.setToken($stateParams.token);
            $state.go('home', {reload: true});
        }

        vm.uvijetiKoristenjaUrl = $window.location.origin + '/uvijeti-koristenja.html';

        vm.login = function () {
            try {
                vm.loginData.validate();

                vm.showLoading();
                $auth.login({
                    'email': vm.loginData.email,
                    'password': vm.loginData.password
                }).then(function (response) {
                        $log.debug("Korisnik uspješno prijavljen!", response);
                        vm.hideLoading();
                        $state.go('home', {}, {reload: true});
                    })
                    .catch(function (response) {
                        $log.error("Greška kod prijave!", response);
                        if (response && response.data && response.data.status === 'error') {
                            SweetAlert.swal("Greška", 'E-mail ili lozinka koju ste unijeli nije ispravna.', "error");
                        }
                        else {
                            SweetAlert.swal("Greška", 'Postoje poteškoće kod prijave u sustav. Molimo kontaktirajte podršku.', "error");
                        }
                        vm.hideLoading();
                    });
            }
            catch (e) {
                SweetAlert.swal("Greška", 'Morate ispravno unijeti e-mail i lozinku.', "error");
            }
        };

        vm.showRegistration = function () {
            vm.loginData = new Login;
            vm.registrationData = new Registration;
            vm.display = 'registration';
        };

        vm.showLogin = function () {
            vm.display = 'login';
        };

        vm.showForgotPassword = function () {
            //vm.loginData = new Login;
            vm.registrationData = new Registration;
            vm.display = 'forgot-password';
        };

        vm.showResetSuccess = function () {
            vm.display = 'reset-success';
        };

        vm.showRegistered = function () {
            vm.loginData = new Login;
            vm.registrationData = new Registration;
            vm.display = 'registered';
        };

        vm.recoverPassword = function () {
            vm.showResetEmailError = false;
            vm.showEmailDosentExist = false;


            try {

                vm.loginData.password = 'hardcoded'; // You are not suppsed to understand this
                vm.loginData.validate();

                var data = {
                    email: vm.loginData.email
                };

                vm.showLoading();
                // TODO: uvesti ApiUtils response validaciju
                $http.post(AppConfig.server + '/password/email', data).then(
                    /**
                     * @param response {*}
                     */
                    function (response) {
                        vm.hideLoading();
                        $log.debug("recover", response);
                        if (response && response.data) {
                            var r = response.data;
                            if (r.status === 'success') {
                                vm.showResetSuccess();
                            }
                            else {
                                SweetAlert.swal("Greška", 'Nepoznata greška', "error");
                                $log.error("Unknown error", response);
                            }
                            // TODO: better error handling
                        }

                    },
                    function (response) {
                        vm.hideLoading();

                        if (response && response.data) {
                            var r = response.data;
                            if (r.status === 'error' && r.code === 4000) {
                                SweetAlert.swal("Greška", 'Ne postoji registrirani korisnik s e-mailom kojeg ste unijeli.', "error");
                            }
                        }
                    });
            }
            catch (e) {
                SweetAlert.swal("Greška", 'Morate ispravno unijeti odgovarajući e-mail', "error");
            }


            if (!vm.resetEmail) {
                vm.showResetEmailError = true;
            }
            else {

            }
        };

        vm.register = function () {

            try {
                vm.registrationData.validate();
                if (!vm.prihvacamUvijete) throw new Error("Must accept rules"); //TODO: vidjeti kako ovo pametnije riješiti

                var data = {
                    email: vm.registrationData.email,
                    telefon: vm.registrationData.telephone
                };

                vm.showLoading();
                // TODO: uvesti ApiUtils response validaciju
                $http.post(AppConfig.server + '/auth/register', data).then(function (response) {
                        vm.hideLoading();

                        if (response && response.data && response.data.status === 'success') {
                            vm.showRegistered();

                            /* Google and Facebook staff */
                            ga('send', 'event', 'Mail', 'Completed', 'App');
                            //goog_report_conversion();
                            facebook_report_conversion();
                            /* end */
                        }
                        else {
                            SweetAlert.swal("Greška", 'Nepoznata greška', "error");
                            $log.error("Unknown error", response);
                        }
                    },
                    function (response) {
                        vm.hideLoading();

                        SweetAlert.swal("Greška", 'Korisnik s ovom e-mail adresom je već regisriran.', "error");
                        $log.info("User with this e-mail already exist");
                    });

            }
            catch (e) {
                SweetAlert.swal("Greška", 'Morate ispravno unijeti e-mail, broj telefona te prihvatiti uvijete korištenja.', "error");
            }
        };

    }
})();
