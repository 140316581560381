(function () {

    angular.module("conto")
        .controller('TenantDataCtrl', TenantDataCtrl);

    function TenantDataCtrl(KategorijaClanstva, TenantRest, $stateParams, sessionData, $log, $state, AppConstants, Tenant) {
        var vm = this;

        const LIST_ROUTE = 'TenantData';
        var tenantCopy = {};

        vm.document = sessionData.tenant;
        vm.userDocument = sessionData.user;
        vm.modelRest = TenantRest;
        vm.afterFormCallback = afterFormCallback;
        vm.cancel = cancel;
        vm.isTenantBillingAlreadySet = isTenantBillingAlreadySet;


        vm.paketiList = angular.copy(AppConstants.PAKETI_LIST);
        //vm.paketiListCopy = vm.paketiList;
        vm.paketiList.splice(1,4); // todo Temp. fix, maknuti paketi M i L

        $log.debug(vm.paketiList, AppConstants.PAKETI_LIST);


        vm.razdobljeList = AppConstants.RAZDOBLJE_LIST;

        activate();

        function activate() {
            angular.extend(tenantCopy, sessionData.tenant.rawData);
        }

        function afterFormCallback(item) {
            $state.go(LIST_ROUTE, {},  {reload: true});
        }

        function cancel() {
            $state.go('home');
        }

        function isTenantBillingAlreadySet() {
            return !!(tenantCopy &&
            /*tenantCopy.naplatni_paket !== 'free' && TODO: maknuti nakon što dozvolimo ostale pakete */
            tenantCopy.billing_interval);
        }

    }

})();
