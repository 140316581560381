(function () {

    angular.module("conto")
           .directive('partnerFormNote', PartnerFormNote);

    function PartnerFormNote() {
        return {
            restrict: 'EA',
            templateUrl: 'Modules/Partner/PartnerForm/partner-form-note.html',
            controllerAs: 'PartnerFormCtrl',
            transclude: 'true'
        }
    }
})();

