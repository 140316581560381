(function() {

	angular.module("conto")
		.directive('supFormButtonDelete', SupFormButtonDelete);

	function SupFormButtonDelete($log) {

		return {
			restrict: 'EA',
			require: '^supForm',
			template: '<button type="button" class="btn btn-danger btn-lg" name="savefrm"><span class="glyphicon glyphicon-trash desc-icon"></span><span class="input-spinner"></span> {{\'obrisi\' | translate}}</button>',
			replace: true,
			scope: {
			},
			link: function(scope, element, attr, supFormCtrl) {


				if (supFormCtrl.editId === 'new') {
					return element.remove();
				}

				var inputSpinner = element.find(".input-spinner");
				var descIcon = element.find(".desc-icon");

				inputSpinner.hide();

				element.bind("click", function() {
					return supFormCtrl.deleteItem();
				});

				scope.$on('supForm:deleteRequestInProgress', function(event, mass) {
					if (mass) {
						element.attr("disabled", "disabled");
						inputSpinner.show();
						descIcon.hide();
					}
					else {
						element.attr("disabled", false);
						inputSpinner.hide();
						descIcon.show();
					}
				});


			}
		};

	}

})();
