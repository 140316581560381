(function() {

    angular.module("conto")
        .factory('ValidationUtils', ValidationUtils);

    function ValidationUtils($log, $translate) {

        var service;
        service = {
            'formatErrorMsg': formatErrorMsg
        };

        /* class "constructor" and initialisation */
        return service;


        function formatErrorMsg(errorsArray, modelName) {
            //$log.info("errorsArray", errorsArray);
            if (!modelName) {
                modelName = '';
            }
            else {
                modelName = modelName + '.';
            }
            if (typeof errorsArray === "string") {
                $log.debug("formatErrorMsg", errorsArray);
                errorsArray = JSON.parse(errorsArray);
            }

            var formatted = '';
            if (errorsArray.fields) {
                errorsArray = errorsArray.fields;
                angular.forEach(errorsArray, function(errItem, key){
                    formatted += $translate.instant(modelName + key)+ ": " + $translate.instant('apiErrors.' + errItem) + '\r\n';
                });
            }
            else {
                angular.forEach(errorsArray, function(errItem, key){
                    //$log.warn("HIIIIIII", errItem, key);
                    angular.forEach(errItem, function(ruleItem, ruleKey){
                        //formatted += translateFieldIfPossible(ruleItem, modelName) + '\r\n';
                        formatted += $translate.instant(modelName + key) + ": " + $translate.instant("validationRule." + ruleItem)+ '\r\n';
                    });
                });
            }

            return formatted;
        }

        /*function translateFieldIfPossible(message, modelName) {
            //$log.info(message, modelName);
            var r = new RegExp(modelName + "\\w+");
            var found = r.exec(message);
            if (found && found.length) {
                //$log.warn("found", found[0]);
                return message.replace(found[0], $translate.instant(found[0]));
            } else {
                return message;
            }
        }*/

    }

})();
