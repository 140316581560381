(function() {

    angular.module("conto")
        .directive('ngRepeatFinished', NgRepeatFinished);

    function NgRepeatFinished($log, $compile, $timeout) {

        return{
            scope:false, //don't need a new scope
            restrict: 'A', //Attribute type
            link: function (scope, element, attr) {
                var additional = '';
                if (attr.ngRepeatFinished) additional = ':' + attr.ngRepeatFinished;

                if (scope.$last === true) {
                    $timeout(function () {
                        $log.debug('ngRepeatFinished' + additional);
                        scope.$emit('ngRepeatFinished' + additional);
                    });
                }
            }
        }

    }

})();