/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('Login', Login);

    function Login($log, ValidationService, ClassUtils) {

        var attributes = {
            email: 'required|email',
            password: 'required'
        };

       return ClassUtils.defineModel('Login', attributes);

    }

})();
