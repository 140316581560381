(function() {

    angular.module("conto")
        .config(OtherConfig);

        function OtherConfig(uiSelectConfig) {
        	moment.locale('hr');
        	uiSelectConfig.theme = 'bootstrap';
		}

})();