(function() {

    angular.module('conto').filter('supdate', function(AppConstants) {
        return function(input) {
            if (input !== null && input !== undefined) {
                return moment.utc(input).format(AppConstants.DDMMYYY);
            }
            else {
                return null;
            }
        };
    });

    angular.module('conto').filter('supdatetime', function(AppConstants) {
        return function(input) {
            if (input !== null && input !== undefined) {
                return moment.utc(input).format(AppConstants.DDMMYYYHHMMSS);
            }
            else {
                return null;
            }
        };
    });


})();
