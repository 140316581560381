(function () {

    angular.module("conto")
        .directive('registarClanovaForm', SupFormRegistarClanovaDirective);

    function SupFormRegistarClanovaDirective() {
        return {
            restrict: 'EA',
            scope: {
                document: '='
            },
            templateUrl: 'Modules/RegistarClanova/RegistarClanovaForm/registar-clanova-form.html',
            controllerAs: 'RegistarClanovaFormCtrl',
            transclude: 'true',
            bindToController: true,
            controller: SupFormRegistarClanovaCtrl
        }
    }

    function SupFormRegistarClanovaCtrl(RegistarClanova, RegistarClanovaRest, uuid4, KategorijaClanstva, KategorijaClanstvaRest,
                                     SweetAlert, ModalService, RestOptions, $log, $state, $translate, $stateParams) {
        var vm = this;

        vm.hasCategories = true;
        vm.ulaznaKategorijaModal = ulaznaKategorijaModal;
        vm.isActivated = true;
        vm.editId = $stateParams.id || 'new';

        activate();

        function activate() {
            var restOptions = new RestOptions;
            restOptions.setLimit(2);
            vm.isActivated = false;
            KategorijaClanstvaRest.getList(restOptions.get()).then(function (data) {
                vm.isActivated = true;
                $log.debug("Provjera postoji li kategorija članstva: ", data);
                if (!data.length) {
                    //vm.hasCategories = false;
                    showKategorijeInfoPopup();
                }
                else if (data.length === 1) {
                    /* Ako postoji samo jedna kategorija postavi ju automatski */
                    vm.document.kategorija = data[0];
                }
            });

            initForm();
        }

        function initForm() {
            vm.afterFormCallback = function () {
                $state.go('RegistarClanova.list');
            }
        }

        function ulaznaKategorijaModal(selectRetval) {
            ModalService.showModal({
                templateUrl: 'Modules/RegistarClanova/KategorijaClanstva/kategorija-clanstva-modal.html',
                controller: 'KategorijaClanstvaFormCtrl as KategorijaClanstvaFormCtrl',
                inputs: {
                    'supModalOptions': {'selectBoxData': selectRetval}
                }
            }).then(function (modal) {
                modal.element.modal();
                modal.close.then(function (kategorija) {
                    $('.modal-backdrop').remove();
                    if (kategorija) {
                        vm.document.kategorija = kategorija;
                    }
                });
            });
        }

        function unesiDefaultnuKategoriju() {
            var newKat = new KategorijaClanstva;
            newKat.id = uuid4.generate();
            newKat.naziv = '-';
            vm.isActivated = false;
            KategorijaClanstvaRest.save(newKat).then(function () {
                vm.document.kategorija = newKat;
                vm.isActivated = true;
            });
        }

        function showKategorijeInfoPopup() {
            SweetAlert.swal({
                    title: $translate.instant('upozorenje'),
                    text: $translate.instant('RegistarClanova.kategorijaPopupInfo'),
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#75aacc",
                    cancelButtonText: $translate.instant('RegistarClanova.kategorijaPopupInfoNemaKategorije'),
                    confirmButtonText: $translate.instant('RegistarClanova.kategorijaPopupInfoImaKategorije'),
                    closeOnConfirm: true
                },
                function (udrugaImaKategorije) {
                    fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                    if (udrugaImaKategorije) {
                        ulaznaKategorijaModal();
                    }
                    else {
                        unesiDefaultnuKategoriju();
                    }

                });
        }


    }

})();
