(function() {

    angular.module("conto")
        .config(TranslateConfig);

    function TranslateConfig($translateProvider) {
        $translateProvider.preferredLanguage('hr');
        $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
    }

})();
