(function() {

    angular.module("conto")
           .config(Select2Config);

    function Select2Config(AppConfig, $provide) {
        /** TEMP FIX FOR UI_SELECT AND angular 1.5 */
        $provide.decorator('uiSelectChoicesDirective', function($delegate) {
            var directive = $delegate[0];

            var templateUrl = directive.templateUrl;

            directive.templateUrl = function(tElement) {
                tElement.addClass('ui-select-choices');
                return templateUrl(tElement);
            };

            return $delegate;
        });

        $provide.decorator('uiSelectMatchDirective', function($delegate) {
            var directive = $delegate[0];

            var templateUrl = directive.templateUrl;

            directive.templateUrl = function(tElement) {
                tElement.addClass('ui-select-match');
                return templateUrl(tElement);
            };

            return $delegate;
        });
    }

})();
