/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('NacinPlacanja', NacinPlacanja);

    function NacinPlacanja($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            naziv: 'required|text',
            metoda: 'required|text'
        };


        return ClassUtils.defineModel('NacinPlacanja', attributes);

    }

})();
