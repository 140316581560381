(function () {

    angular.module("conto")
        .directive('supTable', SupTable);

    function SupTable() {

        return {
            restrict: 'E',
            scope: {
                data: '=',
                modelRest: '=?',
                columns: '=',
                selectedItems: '=?',
                reloadList: '=?',
                restOptions: '=?',
                tblLoader: '=?',
                perPage: '@'
            },
            templateUrl: 'Components/SupTable/sup-table.html',
            controllerAs: 'SupTable',
            transclude: 'true',
            bindToController: true,
            controller: function ($scope, $log, RestOptions, $q, SweetAlert) {
                var vm = this;
                if (!vm.restOptions) {
                    vm.restOptions = new RestOptions;
                    vm.restOptions.setSort('updated_at', 'desc');
                }

                /* Set default perPage */
                if (vm.perPage) {
                    vm.restOptions.setLimit(vm.perPage);
                }
                vm.page = vm.restOptions.getPage();

                if (!vm.selectedItems) vm.selectedItems = [];
                vm.tblLoader = true;

                /* Ako je definirana modelRest klasa automatiziraj posao dohvaćanja podataka i prikaza u tablici*/
                if (vm.modelRest) {

                    if (!vm.reloadList) {
                        vm.reloadList = function (options) {
                            vm.tblLoader = true;
                            vm.modelRest.getList(options).then(function (response) {
                                vm.data = response;
                            });
                        };

                        /* Initialize reload list after init */
                        vm.reloadList(vm.restOptions.get());
                    }

                    $scope.$watch('SupTable.data', function (newValue, oldValue, scope) {
                        vm.selectedItems = []; // resetiraj selektiranje stavke npr. nakon brisanja
                        $scope.$broadcast('supTableRow:selectOne', false);
                        $scope.$broadcast('supTable:selectAll', false);
                        $scope.$broadcast('supTable:reloadList', true);
                        vm.tblLoader = false;
                    });

                    $scope.$on('supTable:changePage', function (event, mass) {
                        vm.changePageInsideDirective(mass);
                    });

                    vm.changePageInsideDirective = function (page) {
                        vm.page = page;
                        vm.reloadList(vm.restOptions.setPage(page).get());
                    };

                    vm.removeSelectedItems = function () {
                        var promises = [];


                        //TODO: refaktorirati sweetalert poziv
                        SweetAlert.swal({
                                title: "Jeste li sigurni?",
                                text: "Jeste li sigurni da želite obrisati odabrane stavke?",
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                cancelButtonText: "Odustani",
                                confirmButtonText: "Da, obriši!",
                                closeOnConfirm: false
                            },
                            function (isConfirm) {
                                fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                                if (isConfirm) {

                                    vm.tblLoader = true;
                                    angular.forEach(vm.selectedItems, function (item, key) {
                                        if (item.id) {
                                            promises.push(vm.modelRest.remove(item.id));
                                        }
                                    });

                                    $log.debug("removeSelectedItems promisses: ", promises);

                                    $q.all(promises).then(function (data) {
                                        var errorList = [];
                                        angular.forEach(data, function (item) {
                                            if (item.status === 'error') {
                                                errorList.push(item);
                                            }
                                        });

                                        if (errorList.length || (data.status && data.status.error)) {
                                            SweetAlert.swal("Upozorenje", "Pojedine stavke nisu mogle biti obrisane!", "error");
                                        }
                                        else {
                                            //TODO: refaktorirati sweetalert poziv
                                            SweetAlert.swal({
                                                    title: "Bravo!",
                                                    type: "success",
                                                    text: "Stavke uspješno obrisane!",
                                                    timer: 1500,
                                                    showConfirmButton: false
                                                },
                                                function () {
                                                    fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                                                    swal.close();
                                                    if (vm.callback && typeof vm.callback === "function") {
                                                        vm.callback();
                                                    }
                                                });
                                        }

                                        vm.reloadList(vm.restOptions.setPage(1).get());
                                    }, function (err) {
                                        $log.error(err);
                                        SweetAlert.swal("Upozorenje", "Pojedine stavke nisu mogle biti obrisane!", "error");
                                        vm.reloadList(vm.restOptions.setPage(1).get());
                                    });

                                }

                            });
                    }

                }

                vm.byString = function (o, s) {
                    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                    s = s.replace(/^\./, '');           // strip a leading dot
                    var a = s.split('.');
                    for (var i = 0, n = a.length; i < n; ++i) {
                        var k = a[i];
                        if (k in o) {
                            o = o[k];
                        } else {
                            return;
                        }
                    }
                    return o;
                };

                vm.numToList = function (num) {
                    var arr = [];
                    for (var i = 1; i <= num; i++) {
                        arr.push(i);
                    }
                    return arr;
                };

                vm.changePage = function (page) {
                    vm.tblLoader = true;
                    $scope.$emit('supTable:changePage', page);
                };


                vm.isItemSelected = function (id) {
                    return vm.selectedItems.indexOf(id) > -1;
                };

                vm.selectItem = function (obj) {
                    if (vm.selectedItems) {
                        var pos = vm.selectedItems.indexOf(obj);
                        if (pos > -1) {
                            vm.selectedItems.splice(pos, 1);
                        }
                        else {
                            vm.selectedItems.push(obj);
                        }
                    }
                };

                vm.selectAll = function () {
                    if (vm.selectedItems && vm.selectedItems.length !== vm.data.length) {
                        vm.selectedItems = [];
                        angular.forEach(vm.data, function (item, i) {
                            if (item && item.id) {
                                vm.selectedItems.push(item);
                            }
                        });
                    }
                    else {
                        vm.selectedItems = [];
                    }
                    if (vm.selectedItems) {
                        var selectedAll = vm.selectedItems.length > 0;
                        $scope.$broadcast('supTable:selectAll', selectedAll)
                    }
                };

            }
        };

    }

})();
