/**
 * Created by danijelmaric on 15/02/16.
 */
(function() {

    angular.module("conto")
           .factory('PocetnoStanjeRacun', PocetnoStanjeRacun);

    function PocetnoStanjeRacun($log, ValidationService, ClassUtils) {

        var attributes = {
            iznos: 'numeric|required',
            partner: 'required|object',
            externa_oznaka: 'text|required',
            interna_oznaka: 'text',
            datum_dokumenta: 'date|required',
            datum_dospijeca: 'date|required',
            napomena: 'text'
        };

        return ClassUtils.defineModel('PocetnoStanjeRacun', attributes);

    }

})();
