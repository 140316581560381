(function() {

    angular.module("conto")
        .factory('ApiUtils', ApiUtils);

    function ApiUtils() {

        var service;
        service = {
            'validateSuccess': validateSuccess,
            'validateError': validateError
        };

        return service;

        function validateSuccess(response) {
            if (!response) return false;
            if (!response.data) return false;
            if (!response.data.status) return false;
            return (response && response.data && response.data.status === 'success');
        }

        function validateError(response) {
            if (!response) return false;
            if (!response.data) return false;
            if (!response.data.status) return false;
            return (response && response.data && response.data.status === 'error');
        }

    }

})();
