(function() {

    angular.module("conto")
        .controller('PomocCtrl', PomocCtrl);

    function PomocCtrl($log, $auth, AppConfig, $http) {
        var vm = this;

        vm.apiServer = AppConfig.server;
        vm.token = $auth.getToken();

        vm.pomocList = [
            {'naziv': 'Kako upisati podatke o udruzi', 'link': 'https://www.youtube.com/watch?v=nF2YpeSytgI'},
            {'naziv': 'Kako upisati podatke o korisniku ', 'link': 'https://www.youtube.com/watch?v=4cfTEh8iJQ4'},
            {'naziv': 'Kako voditi popis članova udruge ', 'link': 'https://www.youtube.com/watch?v=mkuKFsvFRLo'},
            {'naziv': 'Kako izraditi statut udruge ', 'link': 'https://www.youtube.com/watch?v=0n2wf_lbP4Q'},
            {'naziv': 'Kako evidentirati ulazni račun, primjer kupnje opreme s refundacijom suradniku ', 'link': 'https://www.youtube.com/watch?v=cyUy3N0lE-o'},
            {'naziv': 'Kako evidentirati ulazni račun, primjer s virmanskim plaćanjem ', 'link': 'https://www.youtube.com/watch?v=l1cAX4b_kRI'},
            {'naziv': 'Kako izraditi izlazni račun kupcu ', 'link': 'https://www.youtube.com/watch?v=OBXkoiI0oT8'},
            {'naziv': 'Kako evidentirati bankovni Izvod ', 'link': 'https://www.youtube.com/watch?v=JVoag8f2odA'}
        ]

    }
})();
