(function () {

    angular.module("conto")
           .config(RoutesConfig);

    function RoutesConfig($stateProvider, $urlRouterProvider, $authProvider, $locationProvider) {

        /**
         * Potrebno za lijepe URL-ove (bez hash-a)
         */
        $locationProvider.html5Mode(true);

        var authResolve = ['$location', '$auth', function ($location, $auth) {
            if (!$auth.isAuthenticated()) {
                Raven.setUserContext();
                return $location.path('/auth/login');
            }
        }];

        var versionCheckResolve = function ($http, $log, AppConfig) {
            return $http({
                method: 'GET',
                url: AppConfig.server + '/api'
            })
                .then(function (data) {
                    $log.info("API ON COLOR: ", data.data.color);
                }, function (err) {
                    $log.error(err);
                })
        };

        function fetchInBatchResolve(FetchDataInBatch, $log) {
            $log.info("Global data retrive...");
            var fetchInBatch = new FetchDataInBatch;
            return fetchInBatch.extendItemsToFetch('UlaznaKategorijaRest').getData();
        }

        function sessionDataResolve(SessionDataRest, $log) {
            $log.info("Session data retrive...");
            return SessionDataRest.getList();
        }

        $urlRouterProvider.otherwise(function ($injector, $location) {
            $injector.invoke(['$state', function ($state) {
                $state.go('homeLoggedIn');
            }]);
        });

        $stateProvider
            .state('home', {
                url: '',
                templateUrl: 'home.html',
                resolve: {
                    authenticated: authResolve
                }
            })
            .state('homeLoggedIn', {
                url: '/home',
                templateUrl: 'home.html',
                controller: 'HomeCtrl as home',
                resolve: {
                    authenticated: authResolve,
                    sessionData: sessionDataResolve,
                    versionCheck: versionCheckResolve
                }
            })
            .state('Knjigovodstvo', {
                url: "/dokumenti",
                templateUrl: "Modules/Racun/RacunList/home.html",
                abstract: true,
                parent: 'home'
            })

            /*******************************************
             *
             *      ULAZNI RAČUN (URA)
             *
             *******************************************/
            .state('UlazniRacun', {
                url: "/ulazni-racun",
                templateUrl: "Modules/Racun/RacunList/home.html",
                abstract: true,
                parent: 'Knjigovodstvo'
            })
            .state('UlazniRacun.list', {
                url: "/list",
                templateUrl: "Modules/Racun/RacunList/racun-list.html",
                controller: 'RacunListCtrl',
                controllerAs: 'RacunListCtrl',
                resolve: {
                    'fetchInBatch': fetchInBatchResolve,
                    'documentSlug': function () {
                        return 'UlazniRacun';
                    },
                    'RacunRest': function (UlazniRacunRest) {
                        return UlazniRacunRest;
                    }
                }
            })
            .state('UlazniRacun.form', {
                url: "/:id",
                templateUrl: "Modules/Racun/RacunForm/racun-form.html",
                controller: 'RacunFormCtrl',
                controllerAs: 'RacunFormCtrl',
                resolve: {
                    'fetchInBatch': fetchInBatchResolve,
                    'documentSlug': function () {
                        return 'UlazniRacun';
                    },
                    'RacunDocument': function (UlazniRacun) {
                        return UlazniRacun;
                    },
                    'RacunStavka': function (UlazniRacunStavka) {
                        return UlazniRacunStavka;
                    },
                    'RacunRest': function (UlazniRacunRest) {
                        return UlazniRacunRest;
                    }
                }
            })

            /*******************************************
             *
             *      IZLAZNI RAČUN (IRA)
             *
             *******************************************/
            .state('IzlazniRacun', {
                url: "/izlazni-racun",
                templateUrl: "Modules/Racun/RacunList/home.html",
                abstract: true,
                parent: 'Knjigovodstvo'
            })
            .state('IzlazniRacun.list', {
                url: "/list",
                templateUrl: "Modules/Racun/RacunList/racun-list.html",
                controller: 'RacunListCtrl',
                controllerAs: 'RacunListCtrl',
                resolve: {
                    'fetchInBatch': fetchInBatchResolve,
                    'documentSlug': function () {
                        return 'IzlazniRacun';
                    },
                    'RacunRest': function (IzlazniRacunRest) {
                        return IzlazniRacunRest;
                    }
                }
            })
            .state('IzlazniRacun.form', {
                url: "/:id",
                templateUrl: "Modules/Racun/RacunForm/racun-form.html",
                controller: 'RacunFormCtrl',
                controllerAs: 'RacunFormCtrl',
                resolve: {
                    'fetchInBatch': fetchInBatchResolve,
                    'documentSlug': function () {
                        return 'IzlazniRacun';
                    },
                    'RacunDocument': function (IzlazniRacun) {
                        return IzlazniRacun;
                    },
                    'RacunStavka': function (IzlazniRacunStavka) {
                        return IzlazniRacunStavka;
                    },
                    'RacunRest': function (IzlazniRacunRest) {
                        return IzlazniRacunRest;
                    }
                }
            })

            /*******************************************
             *
             *                IZVOD
             *
             *******************************************/
            .state('Izvod', {
                url: "/izvod",
                templateUrl: "Modules/Racun/RacunList/home.html",
                abstract: true,
                parent: 'Knjigovodstvo'
            })
            .state('Izvod.list', {
                url: "/list",
                templateUrl: "Modules/Izvod/IzvodList/izvod-list.html",
                controller: 'IzvodListCtrl',
                controllerAs: 'IzvodListCtrl',
                resolve: {
                    'fetchInBatch': fetchInBatchResolve
                }
            })
            .state('Izvod.form', {
                url: "/:id",
                templateUrl: "Modules/Izvod/IzvodForm/izvod-form.html",
                controller: 'IzvodFormCtrl',
                controllerAs: 'IzvodFormCtrl',
                resolve: {
                    'fetchInBatch': function (FetchDataInBatch, $log) {
                        var fetchInBatch = new FetchDataInBatch;
                        return fetchInBatch.extendItemsToFetch(['UlaznaKategorijaRest', 'BankovniRacunRest']).getData();
                    }
                }
            })

            /*******************************************
             *
             *            BLAGAJNIČKI DOKUMENT
             *
             *******************************************/
            .state('BlagajnickiDokument', {
                url: "/blagajna",
                templateUrl: "Modules/Racun/RacunList/home.html",
                abstract: true,
                parent: 'Knjigovodstvo'
            })
            .state('BlagajnickiDokument.list', {
                url: "/list",
                templateUrl: "Modules/BlagajnickiDokument/BlagajnickiDokumentList/blagajnicki-dokument-list.html",
                controller: 'BlagajnickiDokumentListCtrl',
                controllerAs: 'BlagajnickiDokumentListCtrl',
                resolve: {
                    'fetchInBatch': fetchInBatchResolve
                }
            })
            .state('BlagajnickiDokument.form', {
                url: "/:id",
                templateUrl: "Modules/BlagajnickiDokument/BlagajnickiDokumentForm/blagajnicki-dokument-form.html",
                controller: 'BlagajnickiDokumentFormCtrl',
                controllerAs: 'BlagajnickiDokumentFormCtrl',
                resolve: {
                    'fetchInBatch': function (FetchDataInBatch, $log) {
                        var fetchInBatch = new FetchDataInBatch;
                        return fetchInBatch.extendItemsToFetch(['UlaznaKategorijaRest', 'BlagajnaRest']).getData();
                    }
                }
            })

            /*******************************************
             *
             *                PARTNERI
             *
             *******************************************/
            .state('Partner', {
                url: "/partner",
                templateUrl: "Modules/Partner/home.html",
                abstract: true,
                parent: 'home'
            })
            .state('Partner.list', {
                url: "/list",
                templateUrl: "Modules/Partner/PartnerList/partner-list.html",
                controller: 'PartnerListCtrl',
                controllerAs: 'PartnerListCtrl'
            })
            .state('Partner.form', {
                url: "/:id",
                templateUrl: "Modules/Partner/PartnerForm/partner-form.html",
                controller: 'PartnerFormCtrl',
                controllerAs: 'PartnerFormCtrl',
                resolve: {
                    'fetchInBatch': fetchInBatchResolve,
                    'partnerType': function () {
                        return null;
                    },
                    'supModalOptions': function () {
                        return null;
                        /* needed for popup stuff */
                    },
                    'close': function () {
                        return null;
                        /* needed for popup stuff */
                    }
                }
            })

            /*******************************************
             *
             *           REGISTAR ČLANOVA
             *
             *******************************************/
            .state('RegistarClanova', {
                url: "/registar-clanova",
                templateUrl: "Modules/RegistarClanova/home.html",
                abstract: true,
                parent: 'home'
            })
            .state('RegistarClanova.list', {
                url: "/list",
                templateUrl: "Modules/RegistarClanova/RegistarClanovaList/registar-clanova-list.html",
                controller: 'RegistarClanovaListCtrl',
                controllerAs: 'RegistarClanovaListCtrl'
            })
            .state('RegistarClanova.form', {
                url: "/:id",
                templateUrl: "Modules/Partner/PartnerForm/partner-registar-form.html",
                controller: 'PartnerFormCtrl',
                controllerAs: 'PartnerFormCtrl',
                resolve: {
                    'fetchInBatch': fetchInBatchResolve,
                    'partnerType': function () {
                        return 'clan'
                    },
                    'supModalOptions': function () {
                        return null;
                        /* needed for popup stuff */
                    },
                    'close': function () {
                        return null;
                        /* needed for popup stuff */
                    }
                }
            })

            .state('Napredno', {
                url: "/napredno",
                templateUrl: "Modules/Napredno/home.html",
                abstract: true,
                parent: 'home'
            })

            /*******************************************
             *
             *              TEMELJNICA
             *
             *******************************************/
            .state('Temeljnica', {
                url: "/temeljnica",
                templateUrl: "Modules/Temeljnica/home.html",
                abstract: true,
                parent: 'Napredno'
            })
            .state('Temeljnica.list', {
                url: "/list",
                templateUrl: "Modules/Temeljnica/TemeljnicaList/temeljnica-list.html",
                controller: 'TemeljnicaListCtrl',
                controllerAs: 'TemeljnicaListCtrl',
                resolve: {
                    'documentSlug': function () {
                        return 'Temeljnica';
                    }
                }
            })
            .state('Temeljnica.form', {
                url: "/edit/:id",
                templateUrl: "Modules/Temeljnica/TemeljnicaForm/temeljnica-form.html",
                controller: 'TemeljnicaFormCtrl',
                controllerAs: 'TemeljnicaFormCtrl',
                resolve: {
                    'documentSlug': function () {
                        return 'Temeljnica';
                    },
                    'fetchInBatch': fetchInBatchResolve
                }
            })
            /*******************************************
             *
             *              POČETNO STANJE
             *
             *******************************************/
            .state('PocetnoStanje', {
                url: "/pocetno-stanje",
                templateUrl: "Modules/PocetnoStanje/templates/home.html",
                abstract: true,
                parent: 'Napredno'
            })
            .state('PocetnoStanje.form', {
                url: "/:id",
                templateUrl: "Modules/PocetnoStanje/templates/pocetno-stanje-form.html",
                controller: 'PocetnoStanjeFormCtrl',
                controllerAs: 'PocetnoStanjeFormCtrl',
                resolve: {
                    'fetchInBatch': fetchInBatchResolve
                }
            })
            /*******************************************
             *
             *          POSTAVKE
             *
             *******************************************/
            .state('Postavke', {
                url: "/postavke",
                templateUrl: "Modules/Postavke/home.html",
                abstract: true,
                parent: 'home'
            })

            /*******************************************
             *
             *          KATEGORIJA ČLANSTVA
             *
             *******************************************/
            .state('KategorijaClanstva', {
                url: "/kategorija-clanstva",
                templateUrl: "Modules/RegistarClanova/home-kategorije.html",
                abstract: true,
                parent: 'Postavke'
            })
            .state('KategorijaClanstva.list', {
                url: "/list",
                templateUrl: "Modules/RegistarClanova/KategorijaClanstva/kategorija-clanstva-list.html",
                controller: 'KategorijaClanstvaListCtrl',
                controllerAs: 'KategorijaClanstvaListCtrl'
            })
            .state('KategorijaClanstva.form', {
                url: "/edit/:id",
                templateUrl: "Modules/RegistarClanova/KategorijaClanstva/kategorija-clanstva-form.html",
                controller: 'KategorijaClanstvaFormCtrl',
                controllerAs: 'KategorijaClanstvaFormCtrl',
                resolve: {
                    'close': function () {
                        return null;
                        /* needed for popup stuff */
                    },
                    'supModalOptions': function () {
                        return null;
                        /* needed for popup stuff */
                    }
                }
            })

            /*******************************************
             *
             *              TENANT DATA
             *
             *******************************************/
            .state('TenantData', {
                url: "/tenant-data",
                templateUrl: 'Modules/TenantData/tenant-data.html',
                controller: 'TenantDataCtrl',
                controllerAs: 'TenantDataCtrl',
                parent: 'Postavke',
                resolve: {
                    'sessionData': sessionDataResolve
                }
            })

            /*******************************************
             *
             *                USER DATA
             *
             *******************************************/
            .state('UserData', {
                url: "/user-data",
                templateUrl: 'Modules/UserData/user-data.html',
                controller: 'UserDataCtrl',
                controllerAs: 'UserDataCtrl',
                parent: 'Postavke',
                resolve: {
                    'sessionData': sessionDataResolve
                }
            })

            /*******************************************
             *
             *                IZVJEŠTAJI
             *
             *******************************************/
            .state('Izvjestaji', {
                url: "/izvjestaji",
                templateUrl: "Modules/Izvjestaji/izvjestaji.html",
                controller: 'IzvjestajiCtrl',
                controllerAs: 'IzvjestajiCtrl',
                parent: 'home'
            })

            /*******************************************
             *
             *                POMOĆ
             *
             *******************************************/
            .state('Pomoc', {
                url: "/pomoc",
                templateUrl: "Modules/Pomoc/pomoc.html",
                controller: 'PomocCtrl',
                controllerAs: 'PomocCtrl',
                parent: 'home'
            })

            /*******************************************
             *
             *                STATUT
             *
             *******************************************/
            .state('Statut', {
                url: "/statut",
                templateUrl: "Modules/Statut/statut.html",
                controller: 'StatutCtrl',
                controllerAs: 'StatutCtrl',
                parent: 'Postavke'
            })

            /*******************************************
             *
             *   LOGIN/LOGOUT/REGISTRACIJA/TOKEN-AUTH
             *
             *******************************************/
            .state('auth', {
                abstract: true,
                url: '/auth',
                templateUrl: 'Modules/Auth/auth.html'
            })
            .state('auth.login', {
                url: '/login',
                templateUrl: 'Modules/Auth/login.html',
                controller: 'LoginCtrl',
                controllerAs: 'LoginCtrl'
            })
            .state('auth.reset', {
                url: '/reset/:token',
                templateUrl: 'Modules/Auth/reset.html',
                controller: 'ResetPasswordCtrl',
                controllerAs: 'ResetPasswordCtrl'
            })
            .state('auth.token', {
                url: '/token/:token',
                controller: 'LoginCtrl',
                controllerAs: 'LoginCtrl'
            })
            .state('auth.logout', {
                url: '/logout',
                template: null,
                controller: 'LogoutCtrl'
            });
    }

})();
