(function () {

    angular.module("conto")
        .controller('TemeljnicaFormStavkeCtrl', TemeljnicaFormStavkeCtrl);

    /** @namespace $scope.TemeljnicaFormCtrl */
    function TemeljnicaFormStavkeCtrl($scope,
                                 $log,
                                 SessionDataRest,
                                 $q,
                                 FetchDataInBatch,
                                 RacunService,
                                 PdvRest,
                                 UlaznaKategorijaRest,
                                 JmjRest,
                                 $rootScope,
                                 ModalService,
                                 SweetAlert,
                                 $http,
                                 ValidationService,
                                 TemeljnicaStavka,
                                 AppConfig,
                                 AppConstants,
                                 $translate,
                                 TreeboxPopupOpener,
                                 OsaSiValidationMessages,
                                 UlaznaKategorijaService) {

        var vm = this;
        var batchData;

        const ODLJEVI = 'potrazuje';
        const PRILJEVI = 'duguje';

        vm.editItem = {};
        vm.editItemIndex = null;
        vm.jsonStavke = [];
        vm.tenantData = {};
        vm.rekapitulacija = {potrazuje: 0, duguje: 0};

        vm.calculateTotals = RacunService.calculateStavkaTotal;
        vm.isStavkaOSAorSI = OsaSiValidationMessages.isStavkaOSAorSI;

        vm.newRow = newRow;
        vm.deleteItem = deleteItem;
        vm.konfigurirajStavku = konfigurirajStavku;
        vm.promjeneNaStavci = promjeneNaStavci;
        vm.setStavkaFlux = setStavkaFlux;
        vm.addNewPartner = addNewPartner;

        activate();

        function activate() {
            batchData = $scope.TemeljnicaFormCtrl.fetchInBatch;
            vm.tenantData = batchData.SessionDataRest.tenant;
            vm.pdv = batchData.PdvRest;
            vm.jmj = batchData.JmjRest;

            if (!$scope.TemeljnicaFormCtrl.disabled && !$scope.TemeljnicaFormCtrl.updateId) {
                newRow();
            }
        }

        function addNewPartner(stavka) {
            batchData = $scope.TemeljnicaFormCtrl.fetchInBatch;

            ModalService.showModal({
                templateUrl: 'Modules/Partner/PartnerForm/partner-modal.html',
                controller: "PartnerFormCtrl as PartnerFormCtrl",
                inputs: {
                    'fetchInBatch': batchData,
                    'supModalOptions': {}
                }
            }).then(function (modal) {
                modal.element.modal();
                modal.close.then(function (partner) {
                    $('.modal-backdrop').remove();
                    if (partner) {
                        stavka.partner  = partner;
                    }
                });
            });
        }

        function konfigurirajStavku(retval) {
        }

        function promjeneNaStavci(stavka) {
            calculateTotals();
        }

        function deleteItem($index) {
            $scope.TemeljnicaFormCtrl.racun.obrisiStavku($index);
            calculateTotals();
        }

        function setStavkaFlux(stavka, fluxType) {

            stavka.disablePriljev = false;
            stavka.disableOdljev = false;
            var parentCategory;

            if (fluxType === 'duguje' && (stavka.duguje > 0 || stavka.duguje < 0)) {
                stavka.potrazuje = 0;
                stavka.disableOdljev = true;


            }
            else if (fluxType === 'potrazuje' && (stavka.potrazuje > 0 || stavka.potrazuje < 0)) {
                stavka.duguje = 0;
                stavka.disablePriljev = true;

            }

            promjeneNaStavci(stavka);
        }

        function calculateTotals() {
            vm.rekapitulacija = $scope.TemeljnicaFormCtrl.racun.calculateTotals();
        }

        /**
         * Funkcija koja kreira novu stavku, konfigurira ju s defaultnim parametrima,
         * dodaje ju u ulazni račun, a nakon toga i na sučelje
         */
        function newRow() {
            //var stavka = RacunService.createStavkaDirtyAndPreconfigured(batchData, $scope.TemeljnicaFormCtrl.izvod, stavkaModel);
            var newStavka = (new TemeljnicaStavka);
            //newStavka.showDetails = true;
            var stavka = $scope.TemeljnicaFormCtrl.racun.dodajStavku(newStavka);
            calculateTotals()

        }

    }

})();
