(function () {

    angular.module("conto")
           .controller('RacunListCtrl', RacunListCtrl);

    function RacunListCtrl(RacunRest, $scope, $log, $state, documentSlug, PrintService, fetchInBatch, SweetAlert) {
        var vm = this;

        vm.documentSlug = documentSlug;
        vm.modelRest = RacunRest;
        vm.response = [];
        vm.selectedItems = [];
        vm.print = print;
        vm.storno = storno;


        function storno() {
            if (!vm.selectedItems && vm.selectedItems[0] && vm.selectedItems[0].id) return;
            var _id = vm.selectedItems[0].id;

            SweetAlert.swal({
                    title: "Jeste li sigurni?",
                    text: "Jeste li sigurni da želite stornirati dokument?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    cancelButtonText: "Odustani",
                    confirmButtonText: "Da, obriši!", closeOnConfirm: false
                },
                function (isConfirm) {
                    fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                    if (isConfirm) {

                        $scope.$broadcast('supForm:deleteRequestInProgress', true);
                        vm.modelRest.remove(_id).then(function (data) {
                                //$log.warn("Spremanje člana", data);
                                $scope.$broadcast('supForm:deleteRequestInProgress', false);

                                SweetAlert.swal({
                                        title: "Bravo!",
                                        type: "success",
                                        text: "Uspješno ste obrisali dokument!",
                                        timer: 1500,
                                        showConfirmButton: false
                                    },
                                    function () {
                                        fixSweetAlertBug(); // Tužno ali potrebno inače se tab tipka break-a

                                        swal.close();
                                        $state.go(''.concat(vm.documentSlug, '.list'),{}, {reload:true});
                                    });
                            },
                            function (err) {
                                $log.error(err);
                                SweetAlert.swal("Upozorenje", "Stavka nije mogla biti obrisana!", "error");
                                $scope.$broadcast('supForm:deleteRequestInProgress', false);
                            });

                    }

                });
        }


        function print(racun) {
            PrintService.print({
                templateUrl: 'Modules/Racun/RacunPrint/izlazni-racun.html',
                controller: "IzlazniRacunPrintCtrl as print",
                inputs: {
                    printData: {
                        'racun': racun,
                        'fetchInBatch': fetchInBatch
                    }
                }
            });
        }


    }
})();
