(function () {

    angular.module("conto")
           .directive('partnerFormPayment', PartnerFormPayment);

    function PartnerFormPayment() {
        return {
            restrict: 'EA',
            templateUrl: 'Modules/Partner/PartnerForm/partner-form-payment.html',
            controllerAs: 'PartnerFormCtrl',
            transclude: 'true'
        }
    }
})();

