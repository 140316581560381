(function() {

    angular.module("conto")
        .directive('supTableOptions', SupTableOptions);

    function SupTableOptions($log, $compile) {

        return {
            restrict: 'EA',
            require: '^supTable',
            link: function(scope, element, attr, supTableCtrl) {
                $(element).hide();
                scope.$on('supTableRow:selectOne', function(event, mass) {
                    //$log.debug("mass", mass);
                    $(element).show();
                    $(element).attr('disabled', 'disabled');
                    if (supTableCtrl.selectedItems.length > 1 && attr.supTableOptions == 'many') {
                        $(element).attr('disabled', false);
                    }
                    else if (supTableCtrl.selectedItems.length === 1 && attr.supTableOptions == 'one') {
                        $(element).attr('disabled', false);
                    }
                    else if (supTableCtrl.selectedItems.length && attr.supTableOptions == 'many|one') {
                        $(element).attr('disabled', false);
                    }
                });
            }
        };

    }

})();