(function() {

    angular.module("conto")
        .config(NgDialogConfig);

    function NgDialogConfig(ngDialogProvider) {
        ngDialogProvider.setDefaults({
            showClose: false,
            closeByDocument: false,
            closeByEscape: true,
            disableAnimation: true
        });
    }

})();