(function () {

    angular.module("conto")
        .controller('ResetPasswordCtrl', ResetPasswordCtrl);

    function ResetPasswordCtrl($log, $auth, $state, $stateParams, ValidationService, $http, AppConfig, PasswordReset, SweetAlert, $window, ApiUtils) {
        var vm = this;


        $http.post(AppConfig.server + '/password/token-is-valid', {token: $stateParams.token}).then(function successCallback(response) {
                if (ApiUtils.validateSuccess(response)) {
                    if (response.data.email) {
                        vm.email = response.data.email;

                        return init();
                    }
                    else {
                        $log.error('Password Reset Token error', response);
                        $state.go('home', {reload: true});
                    }
                }
            },
            function (reject) {
                $log.error('Password Reset Token: URL rejected', reject);
                $state.go('home', {reload: true});
            });

        function init() {

            vm.loginData = new PasswordReset;

            vm.display = 'reset-password';

            vm.showLoading = function () {
                vm.loading = true;
            };

            vm.hideLoading = function () {
                vm.loading = false;
            };

            vm.recoverPassword = function () {

                try {
                    vm.loginData.validate();

                    var data = {
                        'token': $stateParams.token,
                        'password': vm.loginData.password1,
                        'password_confirmation': vm.loginData.password2
                    };

                    vm.showLoading();
                    $http.post(AppConfig.server + '/password/reset', data).then(function (response) {
                            vm.hideLoading();
                            //$log.debug("Reset", response);
                            if (response && response.data && response.data.status !== 'success') {
                                SweetAlert.swal("Greška", 'Lozinka nije mogla biti promjenjena.', "error");
                            }
                            else {
                                return vm.login();
                            }
                        },
                        function (reject) {
                            vm.hideLoading();

                            $log.error(reject);
                            SweetAlert.swal("Greška", 'Ne postoji registrirani korisnik s e-mailom kojeg ste unijeli.', "error");
                        });
                }
                catch (e) {
                    SweetAlert.swal("Greška", 'Morate ispravno unijeti odgovarajući e-mail i lozinku te ponoviti unos lozinke.', "error");
                }
            };

            vm.login = function () {
                $auth.login({
                    'email': vm.email,
                    'password': vm.loginData.password1
                }).then(function (response) {
                        $log.debug("Korisnik uspješno prijavljen!", response);
                        $state.go('home', {reload: true});
                    })
                    .catch(function (response) {
                        $log.error("Greška kod prijave nakon reseta lozinke!", response);
                        $state.go('auth.login', {reload: true});
                        // TODO: obavjestiti o grešci
                    });
            }
        }
    }

})();
