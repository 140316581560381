/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('Pdv', Pdv);

    function Pdv($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            vrijednost: 'required|numeric',
            naziv: 'required'
        };

        return ClassUtils.defineModel('Pdv', attributes);

    }

})();
