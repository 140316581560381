(function() {

    angular.module("conto")
        .factory('Registration', Registration);

    function Registration($log, ValidationService, ClassUtils) {

        var attributes = {
            email: 'required|email',
            telephone: 'required'
        };

       return ClassUtils.defineModel('Registration', attributes);

    }

})();
