(function () {

    angular.module("conto")
        .factory('ValidationService', ValidationService);

    function ValidationService($log, ValidationException, SupValidation, $translate) {

        return {
            validate: function (data, rules, fieldName) {
                var vData = {}, vRules = {};
                if (angular.isObject(data) && angular.isObject(rules)) {
                    vData = data;
                    vRules = rules;
                }
                else {
                    if (!fieldName) fieldName = 'ValidationServiceGenericField';
                    vData[fieldName] = data;
                    vRules[fieldName] = rules;
                }

                var vVal = (new SupValidation).validate(vData, vRules);
                if (vVal !== true) {
                    throw new ValidationException(vVal);
                }
            },

            tryCatch: function(method, arrToPushErrors) {
                try {
                    method();
                } catch (err) {
                    var errToReport = err && err.errorsList ? err.errorsList : err;
                    arrToPushErrors.push(errToReport);
                }
            }

        };

    }

})();
