(function () {

    angular.module("conto")
        .directive('supSpinner', SupSpinnerDirective);

    function SupSpinnerDirective($log, $compile, $timeout) {

        return {
            scope: false,
            restrict: 'E',
            template: '<div class="spinner"> <div class="bounce1"></div> <div class="bounce2"></div> <div class="bounce3"></div> </div>'
        }

    }

})();
