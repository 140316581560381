(function () {

    angular.module("conto")
        .factory('ClassUtils', ClassUtils);

    function ClassUtils($log, ValidationService, ValidationException, SupValidation, AppConstants) {

        var service;
        service = {
            'pureData': pureData,
            'defineModel': defineModel
        };

        /* class "constructor" and initialisation */
        return service;

        /*
         * Funkcija za kalkuliranje totala
         * @return vraća OBJEKT s rekapitulacijom izračuna
         */
        function pureData(data) {
            ValidationService.validate(data, 'required', 'data');

            var NOT_ALLOWED_FOR_GET = ['errors', 'rawData'];

            if (data.errors && data.errors.length) {
                throw new Error("Greška u validaciji");
            }

            var pure = {};
            angular.forEach(data, function (value, key) {

                if ($.inArray(key, NOT_ALLOWED_FOR_GET) === -1) {
                    if (value) {
                        pure[key] = value;
                    }
                    else {
                        pure[key] = null;
                    }
                }

            });

            return pure;
        }

        function defineModel(modelName, attributesList) {
            if (!modelName || !attributesList) throw new Error("modelName or attributes missing");

            return function () {

                /* Inicijalno postavi atribute */
                var attributes = attributesList;
                setAttributes(attributes);

                /* Zabilječi defaultne atribute radi kasnijeg eventualnog reseta */
                var attributesDefault = angular.extend({}, attributesList);

                var defineObjectList = {};
                var data = {};
                var extendedValidatorsList = [];
                var extendedFormattersList = [];

                var service;
                service = {
                    'setData': setData,
                    'getData': getData,
                    'getDataForRest': getDataForRest,
                    'validate': validate,
                    'getModelName': getModelName,
                    'setAttributes': setAttributes,
                    'getAttributes': getAttributes,
                    'extendValidators': extendValidators,
                    'extendFormatters': extendFormatters,
                    'removeFormatter': removeFormatter,
                    'executeValidator': executeValidator,
                    'removeValidator': removeValidator,
                    'formatFieldsDefault': formatFieldsDefault,
                    'rawData': data
                };

                angular.forEach(getAttributes(), function (item, key) {
                    data[key] = null;

                    defineObjectList[key] = {};
                    defineObjectList[key]['get'] = _get(key);

                    if (typeof item === "object") {
                        defineObjectList[key]['set'] = item.setter(null, data, service);
                    }
                    else {
                        defineObjectList[key]['set'] = _set(key, null, item);
                    }

                });

                Object.defineProperties(service, defineObjectList);

                /**
                 *  Return service object
                 **/
                return service;

                function getAttributes() {
                    return attributes;
                }

                function setAttributes(attributesList) {
                    attributes = attributesList;

                    attributes['errors'] = 'text';
                    attributes['updated_at'] = 'text';
                }

                function setData(getData) {
                    if (!getData) throw "Fatal error, getData attribute in ClassUtils.setData function not specified!";

                    angular.forEach(getAttributes(), function (item, key) {
                        service[key] = getData[key];
                    });

                    return this;
                }

                function _get(key) {
                    return function () {
                        return data[key];
                    }
                }

                function _set(key, val, rules) {
                    if (!val) val = null;
                    return function (val) {
                        if (key !== 'errors') {
                            var fieldName = getModelName() + "." + key;

                            var vData = {}, vRules = {};
                            vData[fieldName] = val;
                            vRules[fieldName] = rules;
                            var vVal = (new SupValidation).validate(vData, vRules);
                            if (vVal) {
                                service.errors = vVal !== true ? vVal : null;
                            }
                        }

                        data[key] = val;
                    }
                }

                function getModelName() {
                    return modelName;
                }

                function extendValidators(name, func) {
                    if (typeof func === "function") {
                        extendedValidatorsList.push({'name': name, 'function': func});
                    }
                }

                function removeValidator(validatorName) {
                    angular.forEach(extendedValidatorsList, function (validator, key) {
                        if (validator &&
                            validator.name === validatorName) {
                            delete extendedValidatorsList[key];
                        }
                    })
                }

                function executeValidator(validatorName) {
                    angular.forEach(extendedValidatorsList, function (validator) {
                        if (validator &&
                            validator.name === validatorName &&
                            typeof validator.function === "function") {
                            return validator.function();
                        }
                    })
                }

                function extendFormatters(name, func) {
                    if (typeof func === "function") {
                        extendedFormattersList.push({'name': name, 'function': func});
                    }
                }

                function removeFormatter(name) {
                    angular.forEach(extendedValidatorsList, function (validator, key) {
                        if (validator &&
                            validator.name === name) {
                            delete extendedFormattersList[key];
                        }
                    })
                }

                function formatFieldsDefault() {
                    setAttributes(attributesDefault);
                    return this;
                }

                function validate() {
                    var attributes = getAttributes();
                    delete attributes['errors'];
                    data.errors = null;

                    /* Prođi kroz zadane formatere ukoliko postoje te ih apliciraj */
                    angular.forEach(extendedFormattersList, function (formatter) {
                        //$log.info(formatter);
                        if (formatter && typeof formatter.function === "function") formatter.function();
                    });

                    var newAttrs = {}, newData = {};
                    angular.forEach(attributes, function (val, key) {
                        newAttrs[getModelName() + "." + key] = val;
                        newData[getModelName() + "." + key] = data[key];
                    });

                    var vVal = (new SupValidation).validate(newData, newAttrs);

                    var extVal = {};
                    var extValPassing = true;
                    angular.forEach(extendedValidatorsList, function (validator) {
                        if (validator && typeof validator.function === "function") {
                            //$log.debug("shitty", validator.function, validator.name);
                            var checkItem = validator.function();
                            if (checkItem !== true) {
                                extValPassing = false;
                                extVal[getModelName() + "." + validator.name] = checkItem;
                            }

                        }
                    });

                    if (vVal === true && extValPassing) {
                        return true;
                    }
                    else {
                        if (vVal === true) {
                            vVal = {};
                        }
                        angular.extend(vVal, extVal);

                        data.errors = vVal;
                        throw new ValidationException(vVal);
                    }
                }

                function getData() {
                    service.validate();
                    return pureData(data);
                }

                function getDataForRest() {
                    var data = getData();
                    angular.forEach(getAttributes(), function(validator, key) {
                        if (validator) {
                            angular.forEach(validator.split('|'), function(oneValidator) {
                               if (oneValidator === 'date' && data[key]) {
                                   $log.debug("BEFORE", data[key]);
                                   data[key] = moment.utc(data[key]).format(AppConstants.DDMMYYY_INTERNATIONAL);
                                   $log.debug("AFTER", data[key]);
                               }
                            });
                        }
                        //$log.warn("VALIDATOR & KEY", validator, key);
                    });

                    return data;
                }

            }

        }

    }

})();
