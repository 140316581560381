/**
 * Created by danijelmaric on 21/08/15.
 */
(function() {

    angular.module("conto")
        .factory('RegistarClanova', RegistarClanova);

    function RegistarClanova($log, ValidationService, ClassUtils) {

        var attributes = {
            id: 'required|uuid',
            naziv: 'required',
            oib: 'required|oib',
            ulica_i_broj: 'text',
            postanski_broj: 'numeric',
            mjesto: 'text',
            telefon: 'numeric',
            mobitel: 'numeric',
            email: 'email',
            datum_rodjenja: 'date|required',
            kategorija: 'required',
            aktivan_od: 'date|required',
            aktivan_do: 'date'
        };

        return ClassUtils.defineModel('RegistarClanova', attributes);

    }

})();
