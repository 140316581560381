(function () {

    angular.module("conto")
           .directive('partnerForm', PartnerFormDirective);

    function PartnerFormDirective() {
        return {
            restrict: 'EA',
            templateUrl: 'Modules/Partner/PartnerForm/partner-form-directive.html',
            controllerAs: 'PartnerFormCtrl',
            transclude: 'true'
            /*bindToController: true,
            controller: PartnerFormCtrl*/
        }
    }
})();
