(function () {

    angular.module("conto")
           .directive('supFormButtonSave', SupFormButtonSave);

    function SupFormButtonSave() {

        return {
            restrict: 'EA',
            require: '^supForm',
            template: ['<button type="button" class="btn btn-primary btn-lg sup-form-save-button-instance">',
                       '<span class="glyphicon glyphicon-ok desc-icon"></span>',
                       '<span class="input-spinner"></span> {{\'spremi\' | translate}}</button>'].join(''),
            replace: true,
            scope: {},
            link: function (scope, element, attr, supFormCtrl) {
                var inputSpinner = element.find(".input-spinner");
                var descIcon = element.find(".desc-icon");

                inputSpinner.hide();

                element.bind("click", function () {
                    return supFormCtrl.save();
                });

                scope.$on('supForm:saveRequestInProgress', function (event, mass) {
                    if (mass) {
                        element.attr("disabled", "disabled");
                        inputSpinner.show();
                        descIcon.hide();
                    }
                    else {
                        element.attr("disabled", false);
                        inputSpinner.hide();
                        descIcon.show();
                    }
                });


            }
        };

    }

})();
