(function() {

    angular.module("conto")
        .factory('SupModalService', SupModalService);

    function SupModalService($log, Drzava, $q, RestService, $document, $compile, $controller, $rootScope, $templateRequest, $timeout, $animate) {

        return function() {

            var service;
            service = {
                'showModal': showModal
            };

            return service;

            function getTemplate(template, templateUrl) {
                var deferred = $q.defer();
                if(template) {
                    deferred.resolve(template);
                }
                else if(templateUrl) {
                    $templateRequest(templateUrl, true)
                        .then(function (template) {
                            deferred.resolve(template);
                        }, function (error) {
                            deferred.reject(error);
                        });
                }
                else {
                    deferred.reject("No template or templateUrl has been specified.");
                }
                return deferred.promise;
            }

            function showModal(options) {
                var body = $document.find('body');

                var deferred = $q.defer();
                var controllerName = options.controller;
                if(!controllerName) {
                    deferred.reject("No controller has been specified.");
                    return deferred.promise;
                }

                getTemplate(options.template, options.templateUrl).then(function(template) {
                    // Kreiraj novi scope isključivo za modal
                    var modalScope = $rootScope.$new();

                    var templateContainer = '<!-- Modal --> <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"> <div class="modal-dialog" role="document"> <div class="modal-content"> ' + template +' </div> </div> </div>';

                    var linkFn = $compile(templateContainer);
                    var modalElement = linkFn(modalScope);


                    var closeDeferred = $q.defer();
                    var closedDeferred = $q.defer();
                    var inputs = {
                        $scope: modalScope,
                        close: function(result, delay) {
                            if(delay === undefined || delay === null) delay = 0;
                            $timeout(function() {
                                //  Resolve the 'close' promise.
                                closeDeferred.resolve(result);

                                //  Let angular remove the element and wait for animations to finish.
                                $animate.leave(modalElement)
                                    .then(function () {
                                        //  Resolve the 'closed' promise.
                                        closedDeferred.resolve(result);

                                        //  We can now clean up the scope
                                        modalScope.$destroy();
                                        angular.element(".modal-backdrop").remove(); /* Bootstrap modal bugfix */

                                        //  Unless we null out all of these objects we seem to suffer
                                        //  from memory leaks, if anyone can explain why then I'd
                                        //  be very interested to know.
                                        inputs.close = null;
                                        deferred = null;
                                        closeDeferred = null;
                                        modal = null;
                                        inputs = null;
                                        modalElement = null;
                                        modalScope = null;
                                    });
                            }, delay);
                        }
                    };

                    inputs.$element = modalElement;

                    //  Create the controller, explicitly specifying the scope to use.
                    var modalController = $controller(options.controller, inputs);

                    if(options.controllerAs){
                        modalScope[options.controllerAs] = modalController;
                    }

                    modalElement.modal({backdrop: 'static'}); /* Bootstrap modal function */
                    body.append(modalElement);

                    //  We now have a modal object...
                    var modal = {
                        controller: modalController,
                        scope: modalScope,
                        element: modalElement,
                        close: closeDeferred.promise,
                        closed: closedDeferred.promise
                    };

                    //  ...which is passed to the caller via the promise.
                    deferred.resolve(modal);
                    ///$('#myModal').modal()
                }).then(null, function(error) {
                    deferred.reject(error);
                });

                return deferred.promise;

            }

        }

    }

})();
