(function () {

    angular.module("conto")
        .controller('BlagajnickiDokumentListCtrl', BlagajnickiDokumentListCtrl);

    function BlagajnickiDokumentListCtrl(BlagajnickiDokumentRest, $scope, $log, $state, fetchInBatch, PrintService, RestOptions) {
        var vm = this;

        vm.modelRest = BlagajnickiDokumentRest;
        vm.response = [];
        vm.selectedItems = [];
        vm.restOptions = new RestOptions;
        vm.restOptions.setSort('interna_oznaka', 'desc');

        vm.print = print;

        function print(racun) {
            $log.info('Doc print', racun);
            PrintService.print({
                templateUrl: 'Modules/BlagajnickiDokument/BlagajnickiDokumentPrint/blagajnicki-dokument-print.html',
                controller: "BlagajnickiDokumentPrintCtrl as print",
                inputs: {
                    printData: {
                        'racun': racun,
                        'fetchInBatch': fetchInBatch
                    }
                }
            });
        }

    }
})();
