(function () {

    angular.module("conto")
           .controller('BlagajnickiDokumentFormCtrl', BlagajnickiDokumentFormCtrl);

    function BlagajnickiDokumentFormCtrl($scope,
                           $log,
                           SessionDataRest,
                           NacinPlacanjaRest,
                           BlagajnickiDokument,
                           BlagajnickiDokumentRest,
                           PartnerRest,
                           uuid4,
                           $translate,
                           $stateParams,
                           $http,
                           RacunService,
                           ModalService,
                           ApiUtils,
                           SweetAlert,
                           SupPopupMessagesUtils,
                           $state,
                           AppConstants,
                           fetchInBatch,
                           PrintService,
                           AppConfig) {
        var vm = this;

        vm.racun = new BlagajnickiDokument();


        $log.info("fetchInBatch", fetchInBatch);

        vm.fetchInBatch = fetchInBatch;
        vm.tenantData = fetchInBatch.tenant;
        vm.editId = $stateParams.id || 'new';
        vm.updateId = (vm.editId !== 'new') ? vm.editId : null;
        vm.disabled = false;
        vm.isActivated = false;
        vm.nacinPlacanjaDefaults = {};
        vm.groupByPdvList = {};
        vm.print = print;

        vm.saveForm = saveForm;

        activate();

        function setBlagajnaRacunIfOne() {
            if (fetchInBatch &&
                fetchInBatch.BlagajnaRest &&
                fetchInBatch.BlagajnaRest.length === 1) {
                vm.racun.blagajna = fetchInBatch.BlagajnaRest[0];
                vm.hasOnlyOneBankAccount = true;
            }
        }

        function print(data) {
            if (!data) data = vm.racun;

            PrintService.print({
                templateUrl: 'Modules/BlagajnickiDokument/BlagajnickiDokumentPrint/blagajnicki-dokument-print.html',
                controller: "BlagajnickiDokumentPrintCtrl as print",
                inputs: {
                    printData: {
                        'racun': data,
                        'fetchInBatch': fetchInBatch
                    }
                }
            });
        }

        function activate() {
            if (vm.editId !== 'new') {
                BlagajnickiDokumentRest.getOne(vm.editId).then(
                    formatDocumentForEdit,
                    function (err) {
                        $log.error(err);
                    });
            }
            else {
                vm.racun.id = uuid4.generate();
                vm.racun.datum = moment.utc().format(AppConstants.DDMMYYY_INTERNATIONAL);
                vm.isActivated = true;
                setBlagajnaRacunIfOne();
                setTimeout(function () {
                    angular.element(".btn.btn-default.form-control.ui-select-toggle:visible:first").click();
                }, 10);
            }
        }

        function formatDocumentForEdit(data) {
            vm.racun.setData(data);
            vm.disabled = true;
            vm.isActivated = true;
        }

        /* Završna provjera i spremanje ulaznog računa */
        function saveForm(options) {
            vm.racun.datum_obracuna = new Date();
            vm.racun.datum_prijema = new Date(); //TODO za sada preskačemo, trebat će nam kasnije

            $log.info("saveForm", vm.racun, vm.racun.rawData);


            try {
                vm.racun.validate(); // Throws exception
            }
            catch (err) {
                $log.warn("Validation error", err.errorsList);
                setTimeout(function () {
                    angular.element(".has-error:first").focus();
                }, 10);
                return false;
            }

            SupPopupMessagesUtils.formSaveConfrm({
                restClass: BlagajnickiDokumentRest,
                data: vm.racun,
                updateId: vm.updateId
            }, function (status, data) {
                if (status === SupPopupMessagesUtils.CALLBACK_SUCCESS) {
                    vm.racun.interna_oznaka = data.interna_oznaka;
                    vm.racun.updated_at = data.updated_at;
                    if (options && options.print) print();
                    $state.go('BlagajnickiDokument.list');
                }
            });

        }

    }
})();
