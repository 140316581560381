(function () {

    angular.module("conto")
           .constant('AppConstants', {
               /**
                * Globalni date format, kod momentjs formatera se koristi ovako,
                * dok se kod datepickera koristi identično ali u lowercaseu
                */

               'HR_DRZAVNA_SIFRA': 191,

               'DDMMYYY': 'DD.MM.YYYY',
               'DDMMYYY_INTERNATIONAL': 'YYYY-MM-DD',
               'DDMMYYYHHMMSS': 'DD.MM.YYYY HH:mm:ss',
               'OSNOVNO_SREDSTVO': 'OSA',
               'SITAN_INVENTAR': 'SI',
               'OSNOVNO_SREDSTVO_MIN_IZNOS': 3500.00,
               'PARTNER_FIZICKA_OSOBA': 'F',
               'PARTNER_STRANAC': 'S',
               'PARTNER_PRAVNA_OSOBA': 'P',
               'DOZVOLJENI_NACINI_PLACANJA_URA': ['gotovina_za_refundaciju', 'virman', 'gotovina_iz_blagajne'],
               'DOZVOLJENI_NACINI_PLACANJA_IRA': ['virman'],
               'KREDITNA_KARTICA': 'kreditna-kartica',
               'VIRMAN': 'virman',
               'GOTOVINA_IZ_BLAGAJNE': 'gotovina_iz_blagajne',
               'GOTOVINA_ZA_REFUNDACIJU': 'gotovina_za_refundaciju',
               'URA_SLUG': 'UlazniRacun',
               'IRA_SLUG': 'IzlazniRacun',

               'URA_SHORT_SLUG': 'URA',
               'IRA_SHORT_SLUG': 'IRA',
               'IZV_SHORT_SLUG': 'IZV',

               'BLAGAJNA_UPLATA': 'UPL',
               'BLAGAJNA_ISPLATA': 'ISP',

               'IRA_SLUG_USLUGA': 'ira-usluge',
               'PRILJEV': 'priljev',
               'ODLJEV': 'odljev',
               'NOT_DEFINED_PRILJEV_ODLJEV': 'not-defined-priljev-odljev',
               'DUGUJE': 'duguje',
               'POTRAZUJE': 'potrazuje',
               'NOT_DEFINED_DUGUJE_POTRAZUJE': 'not-defined-duguje-potrazuje',
               'PARTNER_TYPE': {
                   'CLAN': 'clan',
                   'SURADNIK': 'suradnik',
                   'VOLONTER': 'volonter'
               },
               'PAKETI_LIST': [
                   {'id': 'free', 'naziv': 'Neo Free', cijena_mjesecno: 0, cijena_godisnje: 0},
                   {'id': 'neo_s', 'naziv': 'Neo S', cijena_mjesecno: 299, cijena_godisnje: 2990},
                   {'id': 'neo_m', 'naziv': 'Neo M', cijena_mjesecno: 399, cijena_godisnje: 3990},
                   {'id': 'neo_l', 'naziv': 'Neo L', cijena_mjesecno: 899, cijena_godisnje: 8990}
               ],
               'RAZDOBLJE_LIST': [
                   {'id': 'monthly', naziv: 'Mjesečno'},
                   {'id': 'yearly', naziv: 'Godišnje'}
               ],
               'RESPONSE_MESSAGES': {
                   'RECORD_ALREADY_EXISTS': 'RECORD_ALREADY_EXISTS'
               }
           });

})();
