(function () {

    angular.module("conto")
        .controller('RegistarClanovaListCtrl', RegistarClanovaListCtrl);

    function RegistarClanovaListCtrl($scope, RegistarClanovaRest, ngDialog, $log, RestOptions, $timeout, $q, SupPrintService, AppConstants) {
        var vm = this;

        vm.modelRest = RegistarClanovaRest;
        vm.response = [];

        vm.restOptions = new RestOptions;
        vm.filterBy = filterBy;
        vm.printList = printList;

        /* Initialize reload list after init */
        vm.restOptions.setCustomOption('aktivan', true);

        activate(vm.restOptions.get(), true);

        /* Custom reload list*/
        function activate(options, special) {
            vm.tblLoader = true;

            /* Resetiraj filter aktivnosti kod pretrage */
            if (!special) {
                vm.restOptions.setCustomOption('aktivan', '');
            }
            //$log.debug(vm.restOptions.get());
            vm.modelRest.getList(vm.restOptions.get()).then(function (response) {
                vm.response = response;
            });
        }

        function filterBy(status) {
            if (status === 'active') {
                vm.restOptions.setCustomOption('aktivan', true);
            }
            else if (status === 'inactive') {
                vm.restOptions.setCustomOption('aktivan', false);
            }
            else {
                vm.restOptions.setCustomOption('aktivan', '');
            }
            vm.restOptions.setPage(1);
            activate(vm.restOptions.get(), true);
        }

        function printList(status) {
            vm.tblLoader = true;
            vm.printTime = moment().format(AppConstants.DDMMYYY + ' H:m');

            var opts = new RestOptions;

            opts.setLimit('all');
            if (status === 'active') {
                opts.setCustomOption('aktivan', true);
                vm.filterPrintInfo = 'Aktivni članovi';
            }
            else if (status === 'inactive') {
                opts.setCustomOption('aktivan', false);
                vm.filterPrintInfo = 'Neaktivni članovi';
            }
            else {
                opts.setCustomOption('aktivan', '');
                vm.filterPrintInfo = 'Aktivni i neaktivni članovi';
            }
            vm.modelRest.getList(opts.get()).then(function (response) {
                vm.tblLoader = false;
                vm.printResponse = response;
            });
        }

        $scope.$on('ngRepeatFinished:generate-print', function () {
            var supPrint = new SupPrintService;
            supPrint.print({
                'divId': 'clanovi-table-print'
            }, function () {
                // remove unnecesary data from DOM after print
                vm.printResponse = [];
            });
        });


    }
})();
