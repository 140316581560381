(function () {

    angular.module("conto")
        .factory('ValidationException', ValidationException);

    function ValidationException($log, ValidationUtils) {

        var _errorsList;
        var _modelName;


        function prittyPrint() {
            //$log.debug("_errorsList", _errorsList);
            return ValidationUtils.formatErrorMsg(_errorsList, _modelName);
        }

        var ValidationException = function ValidationException(errorsList, modelName) {
            //$log.info("errorsList", errorsList, modelName);
            _errorsList = errorsList;
            _modelName = modelName;

            var temp = Error.apply(this, arguments);
            temp.name = this.name = 'ValidationException';
            this.stack = temp.stack;

            this.message = JSON.stringify(_errorsList);
            this.errorsList = _errorsList;
            this.prittyPrint = prittyPrint;
        };

        //inherit prototype using ECMAScript 5 (IE 9+)
        ValidationException.prototype = Object.create(Error.prototype, {
            constructor: {
                value: ValidationException,
                writable: true,
                configurable: true
            }
        });

        return ValidationException;

    }

})();
