(function() {

    angular.module("conto")
        .controller('TemeljnicaListCtrl', TemeljnicaListCtrl);

    function TemeljnicaListCtrl(TemeljnicaRest, $scope, $log, $state, documentSlug) {
        var vm = this;
        vm.documentSlug = documentSlug;

        vm.modelRest = TemeljnicaRest;
        vm.response = [];
        vm.selectedItems = [];

    }
})();
