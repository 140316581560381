(function () {

    angular.module("conto")
           .controller('IzvodFormCtrl', IzvodFormCtrl);

    function IzvodFormCtrl($scope,
                           $log,
                           SessionDataRest,
                           NacinPlacanjaRest,
                           Izvod,
                           IzvodRest,
                           PartnerRest,
                           uuid4,
                           $translate,
                           $stateParams,
                           $http,
                           RacunService,
                           ModalService,
                           ApiUtils,
                           SweetAlert,
                           SupPopupMessagesUtils,
                           $state,
                           AppConstants,
                           fetchInBatch,
                           PrintService,
                           AppConfig) {
        var vm = this;

        vm.racun = new Izvod();


        $log.info("fetchInBatch", fetchInBatch);

        vm.fetchInBatch = fetchInBatch;
        vm.tenantData = fetchInBatch.tenant;
        vm.editId = $stateParams.id || 'new';
        vm.updateId = (vm.editId !== 'new') ? vm.editId : null;
        vm.disabled = false;
        vm.isActivated = false;
        vm.nacinPlacanjaDefaults = {};
        vm.groupByPdvList = {};
        vm.print = print;

        vm.validateBrojIzvoda = validateBrojIzvoda;
        vm.saveForm = saveForm;
        vm.showMissingIzvodList = showMissingIzvodList;

        activate();

        function setBankovniRacunIfOne() {
            if (fetchInBatch &&
                fetchInBatch.BankovniRacunRest &&
                fetchInBatch.BankovniRacunRest.length === 1) {
                $log.debug('fetchInBatch', fetchInBatch);
                vm.racun.bankovni_racun = fetchInBatch.BankovniRacunRest[0];
                vm.hasOnlyOneBankAccount = true;
            }
        }

        function activate() {
            if (vm.editId !== 'new') {
                IzvodRest.getOne(vm.editId).then(
                    formatDocumentForEdit,
                    function (err) {
                        $log.error(err);
                    });
            }
            else {
                vm.racun.id = uuid4.generate();
                vm.isActivated = true;
                setBankovniRacunIfOne();
                setTimeout(function () {
                    angular.element(".input-group-eventually input:first").focus();
                }, 10);
            }
        }

        function validateBrojIzvoda() {
            vm.missingIzvodWarning = false;
            $http({
                method: 'POST',
                url: AppConfig.server + '/api/missing/izvod',
                params: {
                    'bankovni_racun_id': vm.racun.bankovni_racun.id,
                    'broj': vm.racun.externa_oznaka,
                    'year': moment(vm.racun.datum).format('YYYY')
                }
            }).then(function (response) {
                if (response && response.data && response.data.count > 0) {
                    vm.missingIzvodWarning = true;
                    if (response.data.missing) {
                        vm.izvodiList = response.data.missing;
                    }
                }

            }, function (err) {
                if (ApiUtils.validateError(err)) {
                    if (err.data.message === AppConstants.RESPONSE_MESSAGES.RECORD_ALREADY_EXISTS) {
                        SweetAlert.swal({
                            title: $translate.instant('upozorenje'),
                            type: "error",
                            text: $translate.instant('Izvod.alreadyExists')
                        }, function () {
                            vm.racun.externa_oznaka = null;
                        });
                    }
                }
            });
        }

        function showMissingIzvodList() {
            var listMsg = vm.izvodiList;
            if (listMsg) {
                listMsg = listMsg.join(', ');
                listMsg = '<div class="izvod-missing-numbers-list">' + listMsg + '</div>';
                SweetAlert.swal({
                    html: true,
                    title: $translate.instant('upozorenje'),
                    type: "warning",
                    text: $translate.instant('Izvod.missing_upozorenje') + listMsg
                });
            }
        }

        function formatDocumentForEdit(data) {
            vm.racun.setData(data);
            vm.disabled = true;
            vm.isActivated = true;
        }

        /* Završna provjera i spremanje ulaznog računa */
        function saveForm() {
            vm.racun.datum_obracuna = new Date();
            vm.racun.datum_prijema = new Date(); //TODO za sada preskačemo, trebat će nam kasnije

            $log.info("saveForm", vm.racun, vm.racun.rawData);


            try {
                vm.racun.validate(); // Throws exception
            }
            catch (err) {
                $log.warn("Validation error", err.errorsList);
                setTimeout(function () {
                    angular.element(".has-error:first").focus();
                }, 10);
                return false;
            }

            SupPopupMessagesUtils.formSaveConfrm({
                restClass: IzvodRest,
                data: vm.racun,
                updateId: vm.updateId
            }, function (status, data) {
                if (status === SupPopupMessagesUtils.CALLBACK_SUCCESS) {
                    $state.go('Izvod.list');
                }
            });

        }

    }
})();
