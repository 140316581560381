(function() {

    angular.module("conto")
        .config(AuthConfig);

        function AuthConfig($authProvider, AppConfig) {
        	$authProvider.baseUrl =  AppConfig.server; // API Base URL for the paths below.
		}

})();
