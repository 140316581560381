(function() {

    angular.module("conto")
        .controller('StatutCtrl', StatutCtrl);

    function StatutCtrl($log, $auth, AppConfig, $http) {
        var vm = this;

        vm.apiServer = AppConfig.server;
        vm.token = $auth.getToken();

        vm.gaEvent = function() {
        	ga('send', 'event', 'Download', 'Completed', 'Statute');
        };


        vm.iframeUrl = AppConfig.statut + '/?token=' + vm.token;

        vm.statutExists = false;
        $http.post(AppConfig.server + '/provjeri-statut').then(function(response) {
            $log.debug(response);
            if (response && response.data && response.data.file_exists && response.data.file_exists == true) {
                vm.statutExists = true;
            }
            else {
                vm.statutExists = 'iframe';
                $(".statut-iframe").attr("src", vm.iframeUrl);
                $(".statut-iframe").height($(window).height());

                window.addEventListener("message", function(d) {
                    if (d && d.data == 'reload') {
                        $log.debug("Reloadam window, ifreame me zamolio!");
                        window.location.reload();
                    }
                }, false);
            }

        });

    }
})();
