(function() {

    angular.module("conto")
        .directive('supInputDate', SupInputDate);

    function SupInputDate($log, $compile, $parse, AppConstants) {

        return {
            restrict: 'A',
            require: ['ngModel', 'supInput'],
            /**
             * @returns {*}
             * @param scope {*}
             * @param element
             * @param attr.supInputDate
             * @param attr.$observe
             * @param attr
             * @param ctrls
             */
            link: function(scope, element, attr, ctrls) {
                //$log.debug("supFormInput", scope, element, attr, ctrls);

                var ngModelCtrl = ctrls[0];
                var input = element.find("input");

                /* Inicijaliziraj datepicker s eventualnim custom postavkama */
                setDatepicker(attr.supInputDate);

                /* Postavi custom bootstrap ikonicu kalendara */
                element.find(".input-group-eventually").addClass("input-group").prepend('<div class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></div>');

                /**
                 * Funckija koja prati promjenu sup-input-date atributa,
                 * koristi se za dobivanje informacija o date-rangeu
                 */
                attr.$observe('supInputDate', function(options){
                    var parsed = $parse(attr.supInputDate);
                    setDatepicker(options);
                });

                /**
                 * Funckija koja postavlja datepicker u određeno input polje,
                 * prima options objekt, dokumentacija parametara na sljedćem url-u:
                 * http://bootstrap-datepicker.readthedocs.org/en/latest/methods.html#remove
                 *
                 * @param options
                 */
                function setDatepicker(options) {
                    input.datepicker('remove');
                    input.removeClass("datepicker");
                    var defaultOptions = {format: AppConstants.DDMMYYY.toLowerCase(), forceParse: false, language: 'hr', autoclose: true};
                    var mergedOptions = {};
                    if (options) {
                        options = scope.$eval(options);
                        mergedOptions = angular.extend(defaultOptions, options);
                    }
                    else {
                        mergedOptions = defaultOptions;
                    }
                    input.addClass("datepicker");
                    input.datepicker(mergedOptions);

                    element.find('.input-group-addon').bind('click tap', function() {
                        input.focus();
                    });
                }

                ngModelCtrl.$parsers.push(function(modelValue) {
                    var date = moment.utc(modelValue, AppConstants.DDMMYYY);
                    if (date.isValid()) {
                        return date;
                    }
                    return undefined;
                });

                ngModelCtrl.$formatters.push(function(modelValue) {
                    var val = moment.utc(modelValue);
                    if (val.isValid()) {
                        return val.format(AppConstants.DDMMYYY);
                    }
                    else {
                        return undefined;
                    }
                });

                ngModelCtrl.$render = function() {
                    input.val(ngModelCtrl.$viewValue);
                };

                input.on("change", function() {
                    var val = $(this).val();
                    if (val && val != '') {
                        var ac = autocomplete_date(val);
                        if (ac) {
                            ngModelCtrl.$setViewValue(ac);
                            ngModelCtrl.$render();
                            scope.$apply();
                        }
                    }
                    else {
                        ngModelCtrl.$setViewValue(null);
                        ngModelCtrl.$render();
                        scope.$apply();
                    }
                });

                function autocomplete_date($date) {
                    var CURRENT_DATE = moment.utc().format(AppConstants.DDMMYYY).toString();

                    var retval = false;

                    var d = $date.split(".");
                    var arr_date = CURRENT_DATE.split(".");

                    if (d[2] === undefined && /^\d\d?$/.test(d[0])) {
                        if (d[0].length == 1)
                            d[0] = "0" + d[0];

                        if (/^\d\d?$/.test(d[1])) {
                            if (d[1].length == 1)
                                d[1] = "0" + d[1];
                            if (is_valid_date(d[0], d[1], arr_date[2]))
                                retval = d[0] + "." + d[1] + "." + arr_date[2] + "";
                        }
                        else if (is_valid_date(d[0], arr_date[1], arr_date[2]))
                            retval = d[0] + "." + arr_date[1] + "." + arr_date[2] + "";

                        return retval;
                    }

                    return retval;
                }

                function is_valid_date(dateToCheck, monthToCheck, yearToCheck) {
                    monthToCheck = monthToCheck - 1;
                    var dateObj = new Date(yearToCheck, monthToCheck, dateToCheck);
                    return dateObj.getFullYear() == yearToCheck && dateObj.getMonth() == monthToCheck && dateObj.getDate() == dateToCheck;
                }

            }
        };

    }

})();
