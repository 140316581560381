(function () {

    angular.module("conto")
        .directive('supTableSearch', SupTableSearchDirective);

    function SupTableSearchDirective($log, $compile) {

        return {
            restrict: 'EA',
            require: '^supTable',
            scope: {
                searchInput: '=?'
            },
            templateUrl: 'Components/SupTable/sup-table-search.html',
            controller: function ($scope) {
                $scope.$watch('searchInput', function (newItem) {
                    //$log.debug(newItem);
                    if ($scope && $scope.supTableCtrl && newItem !== undefined) {
                        $scope.supTableCtrl.reloadList($scope.supTableCtrl.restOptions.setQuery(newItem).get());
                    }
                });

                $scope.searchInputClear = function () {
                    if ($scope && $scope.supTableCtrl) {
                        $scope.searchInput = '';
                        $scope.supTableCtrl.reloadList($scope.supTableCtrl.restOptions.setQuery('').get());
                    }
                }
            },
            link: function (scope, element, attr, supTableCtrl) {
                scope.supTableCtrl = supTableCtrl;
            }
        };

    }

})();
